import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';
import useStyles from './styles';

function ImageGallery({ fields, title }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const maxSlides = fields.length;
  const classes = useStyles();

  const handleSlideChange = (slide) => {
    setActiveSlide(slide);
  };

  const handleNextSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide + 1);
  };

  const handleBackSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide - 1);
  };

  return (
    <Grid container item xs={12} justify="center" className={classes.imageGalleryComponent}>
      <Grid container item xs={12} justify="center">
        <Grid item xs={10}>
          <section>
            <Typography variant="h2" component="h2" className={classes.centerLine}>
              {title}
            </Typography>
          </section>
        </Grid>
      </Grid>
      <Grid item xs={2} md={1} className={classes.arrowLeft}>
        <ArrowIcon
          disabled={activeSlide === 0}
          onClickHandler={handleBackSlide}
          reverseArrow
          arrowClass={classes.Arrow}
        />
      </Grid>
      <Grid item xs={8} sm={7} md={6} lg={5} className={classes.CardsSlider}>
        <SwipeableViews
          axis="x"
          index={activeSlide}
          className={classes.Slider}
          onChangeIndex={handleSlideChange}
          slideClassName={classes.SlideContainer}
          enableMouseEvents
        >
          {fields.map(item => (
            <img
              className={classes.galleryItem}
              src={item.fields.file.url}
              alt={item.fields.title}
              key={item.sys.id}
            />
          ))}
        </SwipeableViews>
      </Grid>
      <Grid item xs={2} md={1} className={classes.arrowRight}>
        <ArrowIcon
          disabled={activeSlide === maxSlides - 1}
          onClickHandler={handleNextSlide}
          arrowClass={classes.Arrow}
        />
      </Grid>
    </Grid>
  );
}

ImageGallery.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};

export default ImageGallery;
