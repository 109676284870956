import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import ResponsiveImage from '../common/ResponsiveImage';
import useStyles from './styles';

const Founder = ({ fields }) => {
  const { title, person_item_ref: [content] } = fields;
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.founderMain}>
      <Grid item xs={11} sm={10}>
        <Grid container justify="center" alignItems="flex-end" className={classes.founderContainer}>
          <Grid
            item
            xs={8}
            sm={6}
            md={5}
            lg={4}
            className={classes.leftImage}
          >
            <ResponsiveImage
              className={classes.staticImage}
              imageUrl={content.fields.image.fields.file.url}
              imageTitle={content.fields.title}
              sm={500}
              md={440}
              lg={440}
              xl={440}
            />
          </Grid>
          <Grid
            item
            xs={10}
            md={5}
            lg={4}
            className={classes.textContainer}
          >
            <Typography variant="h2" component="h2" className={classes.founderTitle}>
              {title}
            </Typography>
            <Typography variant="h3" component="h3" className={classes.founderMainTitle}>
              {content.fields.name}
            </Typography>
            <Typography variant="h4" component="h4" className={classes.founderSecondaryTitle}>
              {content.fields.title}
            </Typography>
            <Typography paragraph className={classes.founderParagraph}>
              {content.fields.shortBio}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Founder.propTypes = {
  fields: PropTypes.shape({
    person_item_ref: PropTypes.array,
    title: PropTypes.string,
  }).isRequired,
};

export default Founder;
