import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, IconButton } from '@material-ui/core';
import useStyles from './styles';
import SearchIcon from '../../assets/search-icon.png';

const SearchBar = ({ onSearch, initValue }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(initValue);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch(value);
    }
  };
  return (
    <div className={classes.SearchBar}>
      <InputBase
        classes={{ root: classes.InputRoot, input: classes.InputField }}
        placeholder="Search"
        value={value}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <IconButton
        aria-label="search"
        classes={{ root: classes.IconButton }}
        onClick={() => onSearch(value)}
      >
        <img
          src={SearchIcon}
          alt="search Icon"
          className={classes.SearchIcon}
        />
      </IconButton>
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  initValue: PropTypes.string,
};
SearchBar.defaultProps = {
  initValue: '',
};

export default SearchBar;
