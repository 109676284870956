import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Box,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../common/RichTextOptions';
import { ReactComponent as QuoteIcon } from '../../assets/quote.svg';
import useStyles from './styles';

const BannerCareers = ({ fields }) => {
  const classes = useStyles();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography
          variant="h4"
          paragraph
          className={classes.bannerCareersDescription}
        >
          {children}
        </Typography>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <Grid item>
          <QuoteIcon className={classes.quoteIcon} />
          {children}
        </Grid>
      ),
      ...headingOptions,
    },
  };

  return (
    <Grid container direction="row-reverse" className={classes.bannerCareersComponent}>
      <Grid item xs={10}>
        <Grid container justify="center">
          <Grid item xs={11} md={4} className={classes.bannerCareersContent}>
            <Typography variant="h1" component="h2" className={classes.bannerCareersTitle}>
              {fields.title}
            </Typography>
          </Grid>
          <Grid item xs={11} md={8} className={classes.bannerCareersContent}>
            <Box className={classes.bannerCareersQuote}>
              {documentToReactComponents(fields.content, options)}
            </Box>
          </Grid>
          <Grid item xs={10} md={12}>
            <Typography variant="h3" component="h3" className={classes.bannerCareersSecondaryTitle}>
              {fields.secondary_title}
            </Typography>
          </Grid>

          <Grid item xs={11}>
            <Typography
              variant="h2"
              component="h2"
              className={classes.careersListTitle}
            >
            Apply to our Open Positions
            </Typography>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
};

BannerCareers.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    secondary_title: PropTypes.string,
    content: PropTypes.object,
  }).isRequired,
};

export default BannerCareers;
