import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(() => ({
  OrderedList: {
    '& > p': {
      display: 'list-item',
      marginLeft: rem(24),
      marginBottom: rem(10),
    },
    '& > ol': {
      listStyleType: 'upper-alpha',
    },
  },

}));
