import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { InputBase, IconButton } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import useStyles from './styles';

const Dropdown = ({
  values, name, placeholder, onChange, linkPath, value, initialValue,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  const setResult = (item, index) => {
    setOpen(false);
    if (onChange) {
      onChange(item);
    }
  };
  const itemsList = () =>
    values.map((item, index) => (
      <NavLink
        to={linkPath(item)}
        onClick={() => setResult(item, index)}
        key={item}
      >
        <li className={classes.DropdownItem}>{item}</li>
      </NavLink>
    ));

  return (
    <div>
      <div className={`${classes.DropdownField} ${open ? 'open' : ''}`}>
        <InputBase
          name={name}
          classes={{ root: classes.InputRoot, input: classes.InputField }}
          placeholder={placeholder}
          value={values[value] ? values[value] : initialValue}
          onClick={open ? handleClose : handleOpen}
          disabled
        />
        <IconButton
          className={`${classes.IconButton} ${open ? 'open' : ''}`}
          onClick={open ? handleClose : handleOpen}
        >
          <KeyboardArrowDown />
        </IconButton>
      </div>
      {open && (
        <ul id="autocomplete-list" className={classes.DropdownList}>
          {itemsList()}
        </ul>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  linkPath: PropTypes.func.isRequired,
  value: PropTypes.number,
  initialValue: PropTypes.string,
};
Dropdown.defaultProps = {
  initialValue: '',
  placeholder: '',
  onChange: () => {},
  value: 0,
};

export default Dropdown;
