import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';
import React from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import {
  Router, Route, Switch,
} from 'react-router-dom';
import { Grid, CssBaseline, MuiThemeProvider } from '@material-ui/core';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { PROJECT_THEME, SITE_TITLE } from './constants';
import { googleAnalyticsTrackingId } from './utils/config';
import Homepage from './pages/p_001-HomePage/Homepage';
import Company from './pages/p_002-Company/Company';
import InvestmentStrategies from './pages/p_003-InvestmentStrategies/InvestmentStrategies';
import Culture from './pages/p_004-Culture/Culture';
import BlogPage from './pages/p_005-BlogPage/BlogPage';
import Technology from './pages/p_007-Technology/Technology';
import Error404 from './pages/Error404';
import Navigation from './components/c_001-Navigation/Navigation';
import Careers from './pages/p_006-Careers/Careers';
import Legal from './pages/p_008-Legal/Legal';
import Contact from './pages/p_009-Contact/Contact';
import BlogDetailPage from './pages/p_019-BlogDetailPage/BlogDetailPage';
import Sapphire from './pages/p_010-Sapphire/Sapphire';
import Campus from './pages/p_011-Campus/Campus';
import OpenPositions from './pages/p_012-OpenPositions/OpenPositions';

const history = createBrowserHistory();
ReactGA.initialize(googleAnalyticsTrackingId);
ReactGA.pageview(history.location.pathname + history.location.search);
history.listen(location => ReactGA.pageview(location.pathname + location.search));

ReactDOM.render(
  <Router history={history}>
    <MuiThemeProvider theme={PROJECT_THEME}>
      <CssBaseline />
      <Helmet title={SITE_TITLE} />
      <Navigation />
      <Grid container style={{ overflowX: 'hidden' }}>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/company" component={Company} />
          <Route
            path="/investment-approach"
            component={InvestmentStrategies}
          />
          <Route path="/culture" component={Culture} />
          <Route path="/technology" component={Technology} />
          <Route path="/blog/:category/:post" component={BlogDetailPage} />
          <Route path="/blog/:category" component={BlogPage} />
          <Route path="/careers/sapphire" component={Sapphire} />
          <Route path="/careers/campus" component={Campus} />
          <Route path="/careers/open-positions" component={OpenPositions} />
          <Route path="/careers" component={Careers} />
          <Route path="/terms-of-use" key="/terms-of-use" component={Legal} />
          <Route path="/privacy" key="/privacy" component={Legal} />
          <Route path="/contact" component={Contact} />
          <Route component={Error404} />
        </Switch>
      </Grid>
    </MuiThemeProvider>
  </Router>,
  document.getElementById('root'),
);
