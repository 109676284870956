import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography, Grid } from '@material-ui/core';

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import useStyles from './styles';
import { getEntries } from '../../utils/contentful';
import Footer from '../../components/c_003-Footer/Footer';

const Legal = ({ location }) => {
  const [content, setContent] = useState({});
  const classes = useStyles();
  const queryParams = {
    content_type: 'contentPage',
    'fields.slug': location.pathname,
    include: 2,
  };
  useEffect(() => {
    if (content && !content.sys) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);

          if (state) {
            setContent(state.items ? state.items[0] : {});
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
  }, [content, queryParams]);

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph className={classes.ContentText}>
          {children}
        </Typography>
      ),
      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <a href={node.data.target.fields.file.url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
  };

  return (
    <div className={classes.Legal}>
      {!content || !content.fields ? (
        <CircularProgress className={classes.loadingProgress} />
      ) : (
        <Box>
          {content.fields.block_ref.map((item) => {
            let component;
            if (item.sys.contentType.sys.id === 'blockLegal') {
              component = (
                <Grid container justify="center">
                  <Grid item xs={11} md={10} lg={8} xl={7}>
                    <Typography variant="h2" className={classes.LegalTitle}>
                      {item.fields.title}
                    </Typography>
                    {documentToReactComponents(item.fields.content, options)}
                  </Grid>
                </Grid>
              );
            }
            return (
              <section key={item.sys.id} className={classes.Section}>
                {component}
              </section>
            );
          })}
          <Footer />
        </Box>
      )}
    </div>
  );
};

Legal.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Legal;
