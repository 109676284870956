import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import BulletList from './BulletList/BulletList';
import OrderedList from './OrderedList/OrderedList';
import TitleRichText from './TitleRichText/TitleRichText';


export const headingOptions = {

  [BLOCKS.HEADING_1]: (node, children) => (
    <TitleRichText variant="h1" component="h1">
      {children}
    </TitleRichText>
  ),
  [BLOCKS.HEADING_2]: (node, children) => (
    <TitleRichText variant="h2" component="h2">
      {children}
    </TitleRichText>
  ),
  [BLOCKS.HEADING_3]: (node, children) => (
    <TitleRichText variant="h3" component="h3">
      {children}
    </TitleRichText>
  ),
  [BLOCKS.HEADING_4]: (node, children) => (
    <TitleRichText variant="h4" component="h4">
      {children}
    </TitleRichText>
  ),
  [BLOCKS.UL_LIST]: (node, children) => (
    <BulletList>
      { children }
    </BulletList>
  ),
  [BLOCKS.OL_LIST]: (node, children) => (
    <OrderedList>
      { children }
    </OrderedList>
  ),
  [BLOCKS.LIST_ITEM]: (node, children) => (
    <React.Fragment>
      { children }
    </React.Fragment>
  ),
};

export default headingOptions;
