import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import qs from 'query-string';
import useStyles from './styles';
import SearchBar from '../c_022-search-bar/SearchBar';
import Dropdown from '../c_021-dropdown/Dropdown';
import careersNavLink from '../../utils/url';

const CareersFilter = ({ departmentsList, officesList, history }) => {
  const { location } = history;
  const classes = useStyles();
  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { address, department, q: search } = queryString;

  const departmentsNavLink = value => careersNavLink({
    page: 1,
    address,
    department: value.replace('/', '-'),
    q: search,
  });
  const addressNavLink = value => careersNavLink({
    page: 1,
    address: value,
    department,
    q: search,
  });
  const searchNavLink = (value) => {
    const link = careersNavLink({
      page: 1,
      address,
      department,
      q: value.trim(),
    });
    history.push(link);
  };

  return (
    <Grid container className={classes.CareersFilter}>
      <Grid item xs={12}>
        <section>
          <Typography
            variant="h3"
            component="h2"
            className={classes.CareerFilterTitle}
          >
            Filter Positions
          </Typography>
          <div className={classes.FilterDropdown}>
            <Dropdown
              linkPath={departmentsNavLink}
              values={departmentsList}
              initialValue={department || ''}
              value={
                department
                  ? departmentsList.indexOf(department.replace('-', '/'))
                  : 0
              }
              name="departments"
            />
          </div>
          <div className={classes.FilterDropdown}>
            <Dropdown
              linkPath={addressNavLink}
              values={officesList}
              initialValue={address || ''}
              value={
                address ? officesList.indexOf(address.replace('-', '/')) : 0
              }
              name="offices"
            />
          </div>
          <Typography
            variant="h3"
            component="h2"
            className={classes.CareerFilterTitle}
          >
            Search for Jobs
          </Typography>
          <SearchBar onSearch={searchNavLink} initValue={search} />
        </section>
      </Grid>
    </Grid>
  );
};

CareersFilter.propTypes = {
  departmentsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  officesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
    push: PropTypes.func,
  }),
};

CareersFilter.defaultProps = {
  history: {},
};
export default CareersFilter;
