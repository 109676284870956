import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  Location: {
    textAlign: 'center',
    padding: 20,
  },
  LocationImage: {
    width: '90%',
  },
  LocationLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    '& span': {
      fontSize: rem(12),
      [theme.breakpoints.only('md')]: {
        fontSize: rem(14),
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: rem(16),
      },
      textTransform: ' uppercase',
      color: theme.palette.secondary.main,
    },
    '&:hover span': {
      color: theme.palette.primary.main,
    },
  },
  Arrow: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
}));
