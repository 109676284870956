import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const JSONJobBoard = (props) => {
  const { jsonldData } = props;
  const jobPostingArray = [];

  if (jsonldData.length) {
    (jsonldData).map((job) => {
      const tmpElement = document.createElement('div');
      tmpElement.innerHTML = job.content;
      const jobContent = (String(tmpElement.textContent)).replace(new RegExp('<p> </p>', 'g'), '<br>');
      let addressLocalityValue = '';
      let addressRegionValue = '';
      let addressCountryValue = '';

      if (job.offices[0]) {
        if ((job.offices[0].location)) {
          const nameArr = (job.offices[0].location).split(',');
          const [Locality, Region, Country] = nameArr;
          addressLocalityValue = Locality;
          addressRegionValue = Region || '';
          addressCountryValue = Country === ' United States' ? 'US' : Country;
        }
      }

      const firstPartJSONLDStructure = {
        '@context': 'https://schema.org/',
        '@type': 'JobPosting',
        title: job.title,
        description: jobContent,
        datePosted: job.updated_at,
        hiringOrganization: {
          '@type': 'Organization',
          name: 'Schonfeld',
          sameAs: 'https://www.schonfeld.com/',
        },
      };

      const secondPartJSONLDStructure = {
        jobLocation: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            streetAddress: job.location.name,
            addressLocality: addressLocalityValue,
            addressRegion: addressRegionValue,
            addressCountry: addressCountryValue,
          },
        },
      };

      const jsonldStructuredData = { ...firstPartJSONLDStructure, ...secondPartJSONLDStructure };
      return jobPostingArray.push(jsonldStructuredData);
    });
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(jobPostingArray)}
      </script>
    </Helmet>
  );
};

JSONJobBoard.propTypes = {
  jsonldData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

JSONJobBoard.defaultProps = {
  jsonldData: {},
};

export default JSONJobBoard;
