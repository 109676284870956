import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './styles';
import scrollIcon from '../../../assets/scroll-icon.svg';

const ScrollIcon = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.ScrollIcon}
    >
      <img
        src={scrollIcon}
        alt="Scroll icon"
      />
    </Grid>
  );
};
export default ScrollIcon;
