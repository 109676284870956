import React, { useEffect, useState } from 'react';
import { Slide } from 'react-full-page';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getEntries } from '../../utils/contentful';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import RightImage from '../../components/c_006-right-image-slider/RightImage';
import LeftImage from '../../components/c_008-left-image-accordion/LeftImage';
import Banner from '../../components/c_004-block-cta-banner/Banner';
import Values from '../../components/c_005-block-values-slider/Values';
import Offices from '../../components/c_007-offices-slider/Offices';
import CareersNews from '../../components/c_010-careers-news-culture/CareersNews';
import Footer from '../../components/c_003-Footer/Footer';

import Background from '../../assets/animations/Background.json';
import People from '../../assets/animations/People.json';
import Walk from '../../assets/animations/Walk.json';

import FEBackground from '../../assets/animations/FE-Background.json';
import FEPeople from '../../assets/animations/FE-People.json';

import QSBackground from '../../assets/animations/Quantitative-Strategies-background.json';
import QSPeople from '../../assets/animations/Quantitative-Strategies-People.json';

import TTBack from '../../assets/animations/Tactical-Trading-Back.json';
import TTFront from '../../assets/animations/Tactical-Trading-Front.json';
import TTPeople from '../../assets/animations/Tactical-Trading-People.json';

import DMFIAnimation from '../../assets/animations/DMFI-final.json';

import useStyles from './styles';

const lotties = [
  { name: 'background', animation: Background, loop: false },
  { name: 'people', animation: People, loop: true },
  { name: 'walk', animation: Walk, loop: false },
];
const strategyLotties = [
  [
    { name: 'QSBackground', animation: QSBackground, loop: true },
    { name: 'QSPeople', animation: QSPeople, loop: true },
  ],
  [
    { name: 'FEBackground', animation: FEBackground, loop: false },
    { name: 'FEPeople', animation: FEPeople, loop: true },
  ],
  [
    { name: 'TTBack', animation: TTBack, loop: false },
    { name: 'TTPeople', animation: TTPeople, loop: true },
    { name: 'TTFront', animation: TTFront, loop: false },
  ],
  [{ name: 'DMFIAnimation', animation: DMFIAnimation, loop: true }],
];

const Homepage = () => {
  const [content, setContent] = useState({});
  const queryParams = {
    content_type: 'contentPage',
    'fields.slug': '/homepage',
    include: 2,
  };

  const classes = useStyles();

  useEffect(() => {
    if (content && !content.sys) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);

          if (state) {
            setContent(state.items ? state.items[0] : {});
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
  }, [content, queryParams]);

  return (
    <div className={classes.Homepage}>
      {!content || !content.fields ? (
        <CircularProgress className={classes.loadingProgress} />
      ) : (
        <FullPageScroll>
          {content.fields.block_ref.map((item) => {
            let component;
            if (item.fields.value_item_ref) {
              component = <Values fields={item.fields} />;
            } else if (item.fields.layout && item.fields.layout === 'Banner') {
              component = <Banner fields={item.fields} lotties={lotties} />;
            } else if (item.fields.layout && item.fields.layout === 'Careers') {
              component = <CareersNews careersFields={item.fields} />;
            } else if (item.fields.cta_item_ref) {
              component = (
                <RightImage
                  fields={item.fields}
                  strategyLotties={strategyLotties}
                />
              );
            } else if (item.fields.accordion_item_ref) {
              component = <LeftImage fields={item.fields} />;
            } else if (item.fields.office_item_ref) {
              component = <Offices fields={item.fields} />;
            } else {
              component = JSON.stringify(item);
            }
            return (
              <Slide key={item.sys.id}>
                <section
                  className={classes.SlideContent}
                >
                  {component}
                </section>
              </Slide>
            );
          })}
          <Slide>
            <Footer />
          </Slide>
        </FullPageScroll>
      )}
    </div>
  );
};

export default Homepage;
