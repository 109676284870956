import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import NumberBox from '../common/NumberBox/NumberBox';
import useBackgroundColor from '../../utils/useBackgroundColor';
import useStyles from './styles';

function Statistics({ fields }) {
  const classes = useStyles();
  const ref = useBackgroundColor('#ffffff');
  return (
    <Grid container justify="center" alignContent="center" className={classes.Statistics} ref={ref}>
      <Grid item xs={12}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="h2" component="h2" className={classes.centerLine}>
            {fields.title}
          </Typography>
        </Grid>
      </Grid>
      {fields.accordion_item_ref.map(el => (
        <NumberBox
          number={el.fields.title}
          text={el.fields.content}
          key={el.fields.title}
        />
      ))}
    </Grid>
  );
}

Statistics.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    accordion_item_ref: PropTypes.array,
  }).isRequired,
};

export default Statistics;
