import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  TextQuote: {
    height: '90%',
    alignItems: 'center',
  },
  TextQuoteBox: {
    display: 'flex',
    paddingLeft: 32,
    paddingRight: 61,
    paddingTop: 30,
    paddingBottom: 34.5,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingLeft: 41,
      paddingRight: 23,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 100,
      paddingRight: 90,
    },
  },
  TextQuoteIcon: {
    width: 40.5,
    height: 32,
    marginBottom: 27,
    [theme.breakpoints.up('md')]: {
      width: 80,
      height: 64,
      marginBottom: 0,
    },
  },
  TextQuoteDescription: {
    color: theme.palette.primary.main,
    lineHeight: 1.48,
    marginBottom: 0,
    fontSize: rem(16),
    [theme.breakpoints.up('sm')]: {
      fontSize: rem(18),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(24),
      marginLeft: 41,
    },
  },
}));
