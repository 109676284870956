import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../RichTextOptions';
import useStyles from './styles';

function NumberBox({ number, text }) {
  const classes = useStyles();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography color="inherit" className={classes.text}>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };
  return (
    <Grid item xs={6} sm={3} lg={2} xl={1} className={classes.numberBox}>
      <Typography color="inherit" className={classes.number}>
        {number}
      </Typography>
      {documentToReactComponents(text, options)}
    </Grid>
  );
}

NumberBox.propTypes = {
  number: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  text: PropTypes.object.isRequired,
};

export default NumberBox;
