import React from 'react';
import {
  Typography, Grid,
} from '@material-ui/core';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import moment from 'moment';
import { Link } from 'react-router-dom';

import useStyles from './styles';
import { getEntries } from '../../utils/contentful';
import Ellipsis from '../common/Ellipsis';
import LinkButton from '../common/LinkButton';

const News = () => {
  const [postList, setPost] = React.useState([]);
  const currentDate = moment().format();

  if (postList.length === 0) {
    const getPosts = async () => {
      try {
        const state = await getEntries({
          content_type: 'blogPost',
          'fields.category': 'News',
          'fields.publishDate[lte]': currentDate,
          order: '-fields.publishDate',
          limit: 3,
          include: 2,
        });
        if (state) {
          setPost(state.items ? state.items : []);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', e);
      }
    };
    getPosts();
  }

  const classes = useStyles();

  const BlogItemContent = blogItem => (
    <React.Fragment>
      <Grid container className={classes.BlogContent} alignItems="center">
        <Grid item xs={3} md={2}>
          <Typography variant="h4" component="span" className={classes.Day}>
            {moment(blogItem.publishDate).format('DD')}
          </Typography>
          <Typography variant="body2" component="span" className={classes.Month}>
            {moment(blogItem.publishDate).format('MMM, YY')}
          </Typography>
        </Grid>
        <Grid item xs={9} md={10} className={`${classes.Summary}`}>
          <Typography component="span" paragraph className="BlogDescription">
            <Typography variant="h3" component="h3" className={classes.cardTitle}>
              <Ellipsis
                text={blogItem.title}
                maxLine="2"
                mobileLines="4"
              />
            </Typography>

          </Typography>
        </Grid>
      </Grid>
      <RightArrow className={`${classes.Arrow} arrow`} />
    </React.Fragment>
  );

  const BlogItem = (blogItem, active) => (
    <article className={classes.BlogItem} key={blogItem.slug}>
      {
            blogItem.external_url
              ? (
                <a href={blogItem.external_url} className={classes.BlogItem} target="_blank" rel="noopener noreferrer">
                  {BlogItemContent(blogItem, active)}
                </a>
              )
              : (
                <Link to={`/blog/News/${blogItem.slug}`} className={classes.BlogItem}>
                  {BlogItemContent(blogItem, active)}
                </Link>
              )}
    </article>
  );
  return (
    <Grid container alignItems="center" className={classes.News}>
      <section>
        <Typography variant="h2" component="h2" className={classes.NewsTitle}>
          News & Press
        </Typography>
        <div className={classes.NewsList}>
          {postList.map((item, index) => BlogItem(item.fields, index === 0))}
        </div>
        <LinkButton
          variant="contained"
          color="primary"
          className={classes.MainButton}
          to="/blog/News"
        >
          Read More
        </LinkButton>
      </section>
    </Grid>
  );
};

export default News;
