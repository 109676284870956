import { makeStyles } from '@material-ui/styles';
import isIE from '../../utils/isIE';

export default makeStyles(theme => ({
  valuesComponent: {
    height: '100%',
    alignItems: 'center',
    marginBottom: 100,
  },
  Values: {
    backgroundColor: theme.palette.background.card,
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 20,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    [theme.breakpoints.up('md')]: {
      paddingTop: '6%',
      paddingBottom: '6%',
      paddingLeft: 40,
      paddingRight: 50,
      '&.showAll': {
        paddingLeft: '7%',
        paddingTop: '4%',
        paddingBottom: '4%',
      },
      flexDirection: 'row',
      justifyContent: 'flex-start',
      position: 'relative',
    },
  },
  ValuesCta: {
    order: 1,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      order: 2,
      margin: 0,
    },
  },
  arrowLeft: {
    order: 2,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  arrowRight: {
    order: 4,
    display: 'flex',
    justifyContent: 'center',
  },
  ValuesSlider: {
    order: 3,
  },
  ValuesGrid: {
    marginTop: 40,
    order: 2,
  },
  ValuesCtaBtn: {
    textAlign: 'center',
    marginTop: 40,
    order: 4,
  },
  ValuesDetail: {
    maxWidth: 320,
    marginTop: 35,
    marginBottom: 35,
    [theme.breakpoints.up('md')]: {
      maxWidth: 240,
      marginTop: 25,
      marginBottom: 25,
    },
  },
  Slider: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '66.67%',
    },
  },
  Slide: {
    padding: !isIE() && 15,
    margin: isIE() && 15,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      maxWidth: 115,
      maxHeight: 110,
      [theme.breakpoints.up('md')]: {
        maxWidth: 75,
        maxHeight: 70,
      },
    },
  },
  SlideContainer: {
    overflow: 'hidden !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  SlideTitle: {
    maxWidth: 175,
    textAlign: 'center',
    color: theme.palette.text.main,
    marginTop: 10,
    [theme.breakpoints.up('md')]: {
      maxWidth: 140,
      '&.grid': {
        maxWidth: 165,
      },
    },
  },
  Arrow: {
    width: 26,
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 13,
      height: 26,
    },
  },

  MainButton: {},
}));
