export default function careersNavLink({
  page,
  address,
  department,
  q,
}) {
  let link = '/careers/open-positions';
  if (page) {
    link += `?page=${page}`;
  }
  if (address) {
    link += `${link === '' ? '?' : '&'}address=${encodeURIComponent(address)}`;
  }
  if (department) {
    link += `${link === '' ? '?' : '&'}department=${encodeURIComponent(department)}`;
  }
  if (q) {
    link += `${link === '' ? '?' : '&'}q=${encodeURIComponent(q)}`;
  }
  return link;
}
