import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Hidden,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReactComponent as QuoteIcon } from '../../assets/quote.svg';
import ScrollIcon from '../common/ScrollIcon/ScrollIcon';
import LinkButton from '../common/LinkButton';
import { headingOptions } from '../common/RichTextOptions';
import useStyles from './styles';
import useBackgroundColor from '../../utils/useBackgroundColor';

const CenterText = ({
  fields, firstSection, centered, bgColor, hideLine, hideTitle, biggerText,
}) => {
  const ref = useBackgroundColor(bgColor);
  const classes = useStyles();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph className={classes.quoteBlockParagraph}>
          {children}
        </Typography>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <blockquote
          className={classes.paragraphBlock}
        >
          <QuoteIcon />
          <div>
            {children}
          </div>
        </blockquote>
      ),
      ...headingOptions,
    },
  };

  return (
    <Grid container justify="center" className={classes.quoteBlockComponent} ref={ref}>
      <Grid item xs={10} sm={8} md={7} lg={5} className={classes.quoteBlockContent}>
        <Grid container justify="center" alignItems={centered ? 'center' : 'flex-start'}>
          <div className={classes.quoteBlockContainer} style={{ textAlign: centered ? 'center' : 'left' }}>
            { fields.image
              ? (
                <article className={classes.quoteBlockImageBlock}>
                  <img
                    className={classes.quoteBlockImage}
                    src={fields.image.fields.file.url}
                    alt={fields.title}
                  />
                </article>
              ) : null }
            <Typography variant="h2" component="h2" className={`${classes.quoteBlockTitle} ${centered ? classes.centerLine : ''} ${hideLine ? classes.hideLine : ''}`}>
              {hideTitle ? '' : fields.title}
            </Typography>
            <section className={biggerText ? classes.biggerText : ''}>
              {documentToReactComponents(fields.content, options)}
            </section>
            {fields.button_text ? (
              <LinkButton
                variant="contained"
                color="primary"
                to={fields.button_slug}
              >
                {fields.button_text}
              </LinkButton>
            ) : null}
          </div>
          {firstSection && (
          <Hidden smDown>
            <Grid
              item
              xs={12}
            >
              <ScrollIcon />
            </Grid>
          </Hidden>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

CenterText.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.object,
    content: PropTypes.object,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
  }).isRequired,
  firstSection: PropTypes.bool,
  centered: PropTypes.bool,
  bgColor: PropTypes.string,
  hideLine: PropTypes.bool,
  hideTitle: PropTypes.bool,
  biggerText: PropTypes.bool,
};

CenterText.defaultProps = {
  firstSection: false,
  centered: false,
  hideLine: false,
  hideTitle: false,
  biggerText: false,
  bgColor: '#ffffff',
};

export default CenterText;
