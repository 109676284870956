import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  Project: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: 40,
    },
  },
  DescriptionContainer: {
    [theme.breakpoints.only('xl')]: {
      height: 300,
    },
    [theme.breakpoints.only('lg')]: {
      height: 275,
    },
    [theme.breakpoints.only('md')]: {
      height: 350,
    },
  },
  ProjectDescription: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: rem(17),
    [theme.breakpoints.down('sm')]: {
      fontSize: rem(16),
    },
  },
  ProjectImage: {
    width: 65,
    borderRadius: '50%',
    marginRight: 20,
  },
  Name: {
    fontSize: rem(17),
    [theme.breakpoints.down('sm')]: {
      fontSize: rem(16),
    },
  },
}));
