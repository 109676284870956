import { createMuiTheme } from '@material-ui/core/styles';

export const SITE_TITLE = 'Schonfeld';

const PRIMARY_FONT = 'PT Sans, sans-serif';
const SECONDARY_FONT = 'Playfair Display, serif';

const BLACK = '#000000';
const ROYAL = '#0a2783';
const ROYAL_LIGHT = '#000081';
const GREY_BLUE = '#63afaf';
const LIGHT_GREY_BLUE = '#8acac8';
const DUCK_EGG_BLUE = '#e7f1fc';
const SPECIAL_BLUE = '#8db1ff';
const BROWNISH_GREY = '#5c5c5c';
const WARM_GREY = '#3B3B3B';
const GRAY = '#dddddd';
const LIGHT_GREY = '#b0b0b0';
const LIGHT_GREY_2 = '#d6d6d6';
const TRANSPARENT_GREY = 'rgba(10, 39, 131, 0.25)';
const LIGHT = '#f6f6f6';
const WHITE = '#ffffff';

export const PRIMARY_COLOR = ROYAL;
export const SECONDARY_COLOR = GREY_BLUE;
export const TEXT_DARK = BROWNISH_GREY;
export const TEXT_DEFAULT = WARM_GREY;
export const TEXT_LIGHT = LIGHT_GREY;
export const TEXT_CAPTION = TRANSPARENT_GREY;
export const BORDER_LIGHT_GRAY = LIGHT_GREY_2;
export const BORDER_GRAY = GRAY;
export const TRANSPARENT_BLACK = 'rgba(0, 0, 0, 0.1)';
export const TRANSPARENT_BLACK_2 = 'rgba(0, 0, 0, 0.2)';
export const TRANSPARENT_BLACK_3 = 'rgba(0, 0, 0, 0.06)';

export const rem = pixelSize => `${pixelSize / 16}rem`;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: ROYAL_LIGHT,
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR,
      disabled: TRANSPARENT_BLACK,
      contrastText: WHITE,
      white: WHITE,
      black: BLACK,
    },
    secondary: {
      light: LIGHT_GREY_BLUE,
      main: SECONDARY_COLOR,
      dark: SECONDARY_COLOR,
      contrastText: WHITE,
    },
    text: {
      main: TEXT_DEFAULT,
      secondary: TEXT_DARK,
      light: TEXT_LIGHT,
    },
    background: {
      main: WHITE,
      default: WHITE,
      card: LIGHT,
      lightBlue: DUCK_EGG_BLUE,
      specialBlue: SPECIAL_BLUE,
      borderColor: BORDER_GRAY,
    },
    lines: {
      main: TRANSPARENT_BLACK,
      dark: TRANSPARENT_BLACK_2,
      light: TRANSPARENT_BLACK_3,
      border: BORDER_LIGHT_GRAY,
    },
  },
  typography: {
    fontSize: 13,
    htmlFontSize: 13,
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'transparent',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 21.4,
        letterSpacing: 2.88,
        boxShadow: 'none',
        fontFamily: PRIMARY_FONT,
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      textPrimary: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: SECONDARY_COLOR,
      },
      textSecondary: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: PRIMARY_COLOR,
      },
      outlinedPrimary: {
        backgroundColor: 'transparent',
        border: `1px solid ${SECONDARY_COLOR}`,
        color: SECONDARY_COLOR,
        boxShadow: 'none',
        '&:hover': {
          borderColor: `1px solid ${PRIMARY_COLOR}`,
          color: PRIMARY_COLOR,
        },
      },
      outlinedSecondary: {
        backgroundColor: 'transparent',
        borderColor: `1px solid ${PRIMARY_COLOR}`,
        color: PRIMARY_COLOR,
        boxShadow: 'none',
        '&:hover': {
          border: `1px solid ${SECONDARY_COLOR}`,
          color: SECONDARY_COLOR,
        },
      },
      containedPrimary: {
        backgroundColor: SECONDARY_COLOR,
        borderColor: SECONDARY_COLOR,
        color: WHITE,
        boxShadow: 'none',
      },
      containedSecondary: {
        backgroundColor: PRIMARY_COLOR,
        borderColor: PRIMARY_COLOR,
        color: WHITE,
        boxShadow: 'none',
      },
    },
    MuiTypography: {
      root: {
        fontFamily: PRIMARY_FONT,
        fontSize: rem(13),
      },
      h1: {
        fontFamily: SECONDARY_FONT,
        fontWeight: 'normal',
        fontSize: rem(70),
        color: PRIMARY_COLOR,
        marginTop: rem(30),
        paddingTop: rem(30),
        marginBottom: rem(30),
        position: 'relative',

        '&:before': {
          content: '""',
          backgroundColor: SECONDARY_COLOR,
          width: 35,
          height: 3,
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
      h2: {
        fontFamily: SECONDARY_FONT,
        fontWeight: 'normal',
        fontSize: rem(36),
        color: PRIMARY_COLOR,
        marginTop: rem(18),
        paddingTop: rem(28),
        position: 'relative',

        '&:before': {
          content: '""',
          backgroundColor: SECONDARY_COLOR,
          width: 35,
          height: 3,
          position: 'absolute',
          top: 0,
          left: 0,
        },
      },
      h3: {
        fontFamily: SECONDARY_FONT,
        fontWeight: 'normal',
        fontSize: rem(18),
        color: PRIMARY_COLOR,
      },
      h4: {
        fontFamily: PRIMARY_FONT,
        fontWeight: 'normal',
        fontSize: rem(24),
        color: BLACK,
      },
      h5: {
        fontFamily: PRIMARY_FONT,
        fontWeight: 'normal',
        fontSize: rem(18),
        color: PRIMARY_COLOR,
      },
      body1: {
        fontFamily: PRIMARY_FONT,
        fontSize: rem(14),
        color: TEXT_DEFAULT,
      },
      body2: {
        fontFamily: PRIMARY_FONT,
        fontSize: rem(12),
        color: TEXT_DEFAULT,
      },
      caption: {
        fontFamily: PRIMARY_FONT,
        fontSize: rem(10),
        color: TEXT_CAPTION,
      },
      subtitle1: {
        fontFamily: SECONDARY_FONT,
        fontWeight: 'normal',
        fontSize: rem(21.5),
        color: BLACK,
      },
    },
  },
});

theme.overrides.MuiTypography = {
  root: {
    ...theme.overrides.MuiTypography.root,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(14.2), // 24 -> 14.2 (59.16%)
    },
  },
  h1: {
    ...theme.overrides.MuiTypography.h1,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(52), // 84 -> 52 (61.9%)
      lineHeight: 0.9,
    },
  },
  h2: {
    ...theme.overrides.MuiTypography.h2,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(24), // 40 -> 24 (60%)
    },
  },
  h3: {
    ...theme.overrides.MuiTypography.h3,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(17), // 28 -> 17 (60.71%)
    },
  },
  h4: {
    ...theme.overrides.MuiTypography.h4,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(15), // 24 -> 15 (62.5%)
    },
  },
  h5: {
    ...theme.overrides.MuiTypography.h5,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(17), // 28 -> 17 (60.71%)
    },
  },
  body1: {
    ...theme.overrides.MuiTypography.body1,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(14.2), // 24 -> 14.2 (59.16%)
    },
  },
  body2: {
    ...theme.overrides.MuiTypography.body2,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(14.2), // 24 -> 14.2 (59.16%)
    },
  },
  caption: {
    ...theme.overrides.MuiTypography.caption,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(12), // 20 -> 12 (60%)
    },
  },
  subtitle1: {
    ...theme.overrides.MuiTypography.subtitle1,
    [theme.breakpoints.down('md')]: {
      fontSize: rem(17), // 21.5 -> 17 (79%)
    },
  },
};

export const PROJECT_THEME = theme;
