import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  Event: {
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  EventDate: {
    fontSize: rem(12),
    color: '#000000',
    marginLeft: 20,
    marginBottom: 5,
  },
  EventTitle: {
    color: theme.palette.primary.main,
    fontSize: rem(18),
    marginLeft: 20,
    marginBottom: 20,
  },
  EventDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  ModalTitle: {
    color: theme.palette.primary.main,
    fontSize: rem(18),
    margin: '8px 20px',
  },
  ModalDate: {
    fontSize: rem(12),
    color: '#000000',
    margin: '8px 20px',
  },
  CloseContainer: {
    position: 'relative',
  },
  CloseIcon: {
    color: theme.palette.text.light,
    position: 'absolute',
    left: '98%',
  },
  IconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  Icon: {
    fontSize: 16,
    marginRight: 5,
  },
  Description: {
    margin: '8px 20px',
  },
  EventLink: {
    textDecoration: 'none',
    color: '#ffffff',
    margin: '8px 20px',
  },
}));
