const initialState = {
  values: {
    firstName: '', lastName: '', email: '', topic: 'general', subject: '', details: '',
  },
  errors: {

  },
  notification: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_VALUE': {
      const { name, data } = action.payload;
      return { ...state, values: { ...state.values, [name]: data } };
    }
    case 'SUBMIT_VALIDATION_ERRORS': {
      const { errors } = action.payload;
      const errs = errors.reduce((acc, { name, message }) => ({ ...acc, [name]: message }), {});
      return { ...state, errors: errs };
    }
    case 'SUBMIT_SUCCESS': {
      return { ...initialState, notification: { message: 'Your message was successfully sent.', type: 'success' } };
    }
    case 'SUBMIT_SERVER_ERRORS': {
      return { ...initialState, notification: { message: 'Internal server error', type: 'error' } };
    }
    case 'CLOSE_NOTIFICATION': {
      return { ...state, notification: null };
    }
    case 'SET_CAPTCHA': {
      return { ...state, values: { ...state.values, token: action.payload } };
    }
    default:
      return state;
  }
}

export { reducer, initialState };
