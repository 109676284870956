import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Typography,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';
import useStyles from './styles';
import Card from '../common/Card/Card';
import LinkButton from '../common/LinkButton';
import { getEntries } from '../../utils/contentful';

const NewsCarousel = ({ fields }) => {
  const { title, category } = fields;
  const [posts, setPosts] = useState([]);
  const [emptyCategory, setEmptyCategory] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const maxSlides = posts.length;
  const currentDate = moment().format();
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));

  const queryParams = {
    content_type: 'blogPost',
    'fields.category': category,
    'fields.publishDate[lte]': currentDate,
    order: '-fields.publishDate',
    include: 2,
    limit: 10,
  };

  const classes = useStyles();

  useEffect(() => {
    if (posts.length === 0 && !emptyCategory) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);

          if (state) {
            setPosts(state.items ? state.items : {});

            if (state.total === 0) {
              setEmptyCategory(true);
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
  }, [posts, emptyCategory, queryParams]);

  const handleSlideChange = (slide) => {
    setActiveSlide(slide);
  };

  const handleNextSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide + 1);
  };

  const handleBackSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide - 1);
  };

  return (
    posts.length === 0
      ? null
      : (
        <Grid container justify="flex-end" className={classes.newsComponent}>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center">
              <Grid container item xs={12} justify="flex-end">
                <Grid item xs={10}>
                  <section>
                    <Typography variant="h2" component="h2" className={classes.newsCarouselTitle}>
                      {title}
                    </Typography>
                  </section>
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="center">
                <Grid item xs={2} md={1} className={classes.arrowLeft}>
                  <ArrowIcon
                    disabled={activeSlide === 0}
                    onClickHandler={handleBackSlide}
                    reverseArrow
                    arrowClass={classes.Arrow}
                  />
                </Grid>
                <Grid item xs={8} className={classes.CardsSlider}>
                  <SwipeableViews
                    axis="x"
                    index={activeSlide}
                    className={classes.Slider}
                    onChangeIndex={handleSlideChange}
                    slideClassName={classes.SlideContainer}
                    enableMouseEvents
                  >
                    {
                      posts.length > 0
                        ? posts.map((item, i) => (
                          <Card key={`${item.fields.title}-${Math.random() * i}`} item={item} category={category} />
                        ))
                        : (
                          <div />
                        )
                    }
                  </SwipeableViews>
                </Grid>
                <Grid item xs={2} md={1} className={classes.arrowRight}>
                  <ArrowIcon
                    disabled={
                      activeSlide === maxSlides - 1
                      || (isLg && activeSlide === maxSlides - 3)
                      || (isMd && activeSlide === maxSlides - 2)
                    }
                    onClickHandler={handleNextSlide}
                    arrowClass={classes.Arrow}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} justify="center">
                <Grid item xs={8} className={classes.readMore}>
                  <LinkButton variant="contained" color="primary" to={`/blog/${category}`}>
                    Read More
                  </LinkButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
  );
};

NewsCarousel.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
};

export default NewsCarousel;
