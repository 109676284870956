import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  Homepage: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& > div': {
      display: 'table-row',
    },
  },
  loadingProgress: {
    display: 'block',
    position: 'fixed',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    zIndex: 1301,
  },
  SlideContent: {
    width: '100vw',
    paddingTop: 100,
    [theme.breakpoints.up('md')]: {
      paddingTop: 155,
    },
    '@media only screen and (min-width: 960px) and (min-height: 680px) and (orientation: landscape)': {
      height: '100vh',
    },
  },
  historySection: {
    '@media only screen and (max-height: 960px) and (orientation: landscape)': {
      height: 'initial',
    },
  },
}));
