import React, { useEffect, useState } from 'react';
import { Slide } from 'react-full-page';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getEntries } from '../../utils/contentful';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import MainIllustration from '../../components/c_043_main-illustration/MainIllustration';
import Statistics from '../../components/c_038_statistics/Statistics';
import Strategy from '../../components/c_012-investment-strategy-block/Strategy';
import CenterText from '../../components/c_011-center-text/CenterText';
import TextQuote from '../../components/c_036_text-quote/TextQuote';
import SingleMedia from '../../components/c_037_single-media/SingleMedia';
import Footer from '../../components/c_003-Footer/Footer';
import useStyles from './styles';

export default function Sapphire() {
  const [content, setContent] = useState({});
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const state = await getEntries({
          content_type: 'contentPage',
          'fields.slug': '/careers/sapphire',
          include: 2,
        });
        if (mounted && state) {
          setContent(state.items ? state.items[0] : {});
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', e);
      }
    };
    getData();
    return () => { mounted = false; };
  }, []);

  return (
    <div className={classes.Sapphire}>
      {!content || !content.fields ? (
        <CircularProgress className={classes.loadingProgress} />
      ) : (
        <FullPageScroll>
          {content.fields.block_ref.map((item, index) => {
            let component;
            if (item.sys.id === 'm9mmAzVeIJHgGppu03eSC') {
              component = (
                <MainIllustration
                  imgSrc={item.fields.image.fields.file.url}
                  altText={item.fields.image.fields.file.fileName}
                  isLogo
                />
              );
            } else if (item.sys.id === '6Q16gAyHzRaJLkmQM3vtaY') {
              component = <CenterText fields={item.fields} bgColor="#e3f4f4" centered hideLine biggerText />;
            } else if (item.fields.accordion_item_ref) {
              component = <Statistics fields={item.fields} />;
            } else if (item.fields.layout === 'Full Width (Right Image)') {
              component = (
                <Strategy
                  fields={item.fields}
                  index={index + 1}
                />
              );
            } else if (item.fields.layout === 'Full Width (Left Image)') {
              component = (
                <Strategy
                  fields={item.fields}
                  index={index + 1}
                  inverted
                />
              );
            } else if (item.sys.id === 'm57l7V7fLXlFPAFOBaV5Z') {
              component = <CenterText fields={item.fields} />;
            } else if (item.fields.layout === 'Quote') {
              component = <TextQuote fields={item.fields} />;
            } else if (item.fields.layout === 'Media') {
              component = <SingleMedia fields={item.fields} />;
            } else {
              return null;
            }
            return (
              <Slide key={item.sys.id}>
                <section
                  className={classes.SlideContent}
                >
                  {component}
                </section>
              </Slide>
            );
          })}
          <Slide>
            <Footer />
          </Slide>
        </FullPageScroll>
      )}
    </div>
  );
}
