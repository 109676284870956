import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Hidden } from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../common/RichTextOptions';
import useStyles from './styles';
import ResponsiveImage from '../common/ResponsiveImage';
import LinkButton from '../common/LinkButton';
import ScrollIcon from '../common/ScrollIcon/ScrollIcon';
import UncontrolledLottie from '../common/Lottie/UncontrolledLottie';
import isIE from '../../utils/isIE';

const Banner = ({ fields, lotties }) => {
  const classes = useStyles();
  const title = fields.title.split('<br>');

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph className={classes.Detail}>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  const bannerImage = () => {
    if (!isIE() && lotties.length > 0) {
      return (
        <React.Fragment>
          <UncontrolledLottie
            className={classes.BannerLottie}
            lotties={lotties}
          />
        </React.Fragment>
      );
    }
    return fields.image
      ? (
        <ResponsiveImage
          className={classes.BannerImage}
          imageUrl={fields.image.fields.file.url}
          imageTitle={fields.title}
        />
      ) : null;
  };

  const { height, width } = fields.image.fields.file.details.image;
  const isLandscape = lotties[0]
    ? lotties[0].animation.h < lotties[0].animation.w
    : height < width;

  return (
    <Grid container className={classes.Banner}>
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            xs={10}
            md={isLandscape ? 6 : 5}
            lg={isLandscape ? 5 : 4}
            className={`${classes.ImageWrapper} ${isLandscape ? 'landscape' : 'portrait'}`}
          >
            {bannerImage()}
          </Grid>
          <Grid
            item
            className={classes.BannerContent}
          >
            <section>
              {fields.secondary_title && (
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.Title}
                >
                  {fields.secondary_title}
                </Typography>
              )}
              <Typography
                variant="h1"
                component="h2"
                className={classes.Subtitle}
              >
                {title.map(line => (
                  <span key={line}>{line}</span>
                ))}
              </Typography>

              {fields.content && (
                <section>
                  {documentToReactComponents(fields.content, options)}
                </section>
              )}

              {fields.button_text && (
                <LinkButton
                  variant="contained"
                  color="primary"
                  className={classes.MainButton}
                  to={fields.button_slug}
                >
                  {fields.button_text}
                </LinkButton>
              )}
            </section>
          </Grid>
        </Grid>
      </Grid>

      <Hidden smDown>
        <Grid item xs={12}>
          <ScrollIcon />
        </Grid>
      </Hidden>
    </Grid>
  );
};

Banner.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    secondary_title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    content: PropTypes.object,
    image: PropTypes.object,
  }).isRequired,
  lotties: PropTypes.arrayOf(PropTypes.shape),
};
Banner.defaultProps = {
  lotties: [],
};
export default Banner;
