import React, { useEffect } from 'react';
import {
  Grid,
  Hidden,
  Toolbar,
  AppBar,
  IconButton,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import useStyles from './styles';
import menuIcon from '../../assets/menu-icon.svg';
import logo from '../../assets/schonfeld-logo.svg';
import { getEntries } from '../../utils/contentful';
import { SITE_TITLE } from '../../constants';
import DropdownMenu from './DropdownMenu';

export default () => {
  const classes = useStyles();
  const [shadow, setShadow] = React.useState(0);
  const [menuItems, setMenu] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const getBodyScrollTop = () => {
    const el = document.scrollingElement || document.documentElement;
    return el.scrollTop;
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (getBodyScrollTop() > 30) {
        setShadow(1);
      } else {
        setShadow(0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (menuItems.length === 0) {
    const getMenu = async () => {
      try {
        const state = await getEntries({
          content_type: 'blockNavigation',
          'fields.name': 'Main Menu',
          include: 2,
        });
        if (state) {
          setMenu(state.items ? state.items[0].fields.navigation_item_ref : []);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', e);
      }
    };
    getMenu();
  }

  const AdapterLink = React.forwardRef((props, ref) => <NavLink exact innerRef={ref} {...props} />);

  const menuList = menuItems.map(({ fields }) => {
    let componentProps;
    if (fields.navigation_item_ref) {
      return (
        <DropdownMenu
          isLg={isLg}
          fields={fields}
          shadow={shadow}
          AdapterLink={AdapterLink}
          key={fields.title}
        />
      );
    }
    if (fields.is_external) {
      componentProps = {
        component: 'a',
        href: fields.slug,
        target: '_blank',
        rel: 'noopener noreferrer',
      };
    } else {
      componentProps = {
        component: AdapterLink,
        to: `/${fields.slug}`,
      };
    }
    return (
      <ListItem
        key={fields.title}
        className={`${classes.drawerLink} ${classes.mainLink} ${shadow === 1 ? 'scrolling' : ''}`}
        {...componentProps}
      >
        <Typography variant="body2" component="span">
          {fields.title}
        </Typography>
      </ListItem>
    );
  });

  return (
    <React.Fragment>
      <AppBar
        position="static"
        color={!shadow ? 'default' : 'inherit'}
        elevation={shadow}
        className={classes.appBar}
      >
        <Toolbar className={`${classes.toolbar} ${shadow === 1 ? 'scrolling' : ''}`}>
          <Grid container>
            <Grid item xs={8} md={3}>
              <NavLink to="/" exact className={classes.headerLogo}>
                <img src={logo} alt={SITE_TITLE} />
              </NavLink>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={9}>
                <nav className={classes.desktopNav}>
                  <List className={classes.headerList}>
                    {menuList}
                  </List>
                </nav>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <nav className={classes.mobileNav}>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={handleDrawerOpen}
                  className={classes.menuButton}
                >
                  <img src={menuIcon} alt="Menu" />
                </IconButton>
              </nav>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      {!isLg && (
      <SwipeableDrawer
        anchor="right"
        classes={{
          paper: classes.drawerPaperClose,
        }}
        open={open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem component={AdapterLink} to="/" exact className={`${classes.drawerLink} ${shadow === 1 ? 'scrolling' : ''}`}>
            <Typography variant="body2" component="span">
              Home
            </Typography>
          </ListItem>
          {menuList}
        </List>
      </SwipeableDrawer>
      )}
    </React.Fragment>
  );
};
