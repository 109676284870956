import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import ContactForm from '../../components/c_026-contact-form/ContactForm';
import Footer from '../../components/c_003-Footer/Footer';
import useStyles from './styles';

const Contact = () => {
  const classes = useStyles();
  return (
    <div className={classes.Contact}>
      <Box>
        <section
          key="contact"
          className={classes.Section}
        >
          <Grid
            container
            direction="row-reverse"
            className={classes.bannerCareersComponent}
          >
            <Grid item xs={11}>
              <Grid container>
                <Grid item xs={11} md={10} lg={8} xl={7}>
                  <Typography variant="h1" className={classes.ContactTitle}>
                      Contact Us
                  </Typography>
                  <div className={classes.Form}>
                    <ContactForm />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
        <Footer />
      </Box>
    </div>
  );
};

export default Contact;
