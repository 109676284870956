import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  offices: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  officesBackground: {
    position: 'absolute',
    height: '100%',
    left: '0',
    top: 0,
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('sm')]: {
      left: '25%',
    },
    [theme.breakpoints.up('md')]: {
      left: '50%',
    },
  },
  background: {
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: '70%',
    },
    '@media only screen and (min-width: 960px) and (orientation: landscape)': {
      height: '100%',
    },
  },
  officesContent: {
    zIndex: 20,
  },
  officesInformation: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  officesInformationDescription: {
    marginTop: 35,
    marginRight: '8vw',
    marginBottom: 34,
    marginLeft: 0,
    maxWidth: 519,
    [theme.breakpoints.up('md')]: {
      marginTop: 27,
      marginBottom: 26,
      marginRight: 0,
      maxWidth: 345,
    },
  },
  officesCarousel: {
    order: 1,
    backgroundColor: theme.palette.background.card,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    paddingTop: 30,
    paddingBottom: 30,
    marginBottom: 67,
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginBottom: 0,
    },
  },
  sliderIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  carouselImages: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  image: {
    maxHeight: 303.7,
    display: 'block',
    maxWidth: 240.3,
    overflow: 'hidden',
    width: '100%',
  },
  carouselLocationName: {
    paddingTop: 18,
    paddingBottom: 25,
  },
  dotsContainer: {
    background: 'transparent',
    justifyContent: 'center',
  },
  dots: {
    width: '25%',
    display: 'flex',
    justifyContent: 'space-around',
    '& > div': {
      width: 5,
      height: 5,
    },
    [theme.breakpoints.up('md')]: {
      '& > div': {
        width: 4,
        height: 4,
      },
    },
  },
  iconSize: {
    width: 14,
    height: 26,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 50,
    },
  },
}));
