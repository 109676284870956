import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  Stage: {
    position: 'relative',
    height: '100%',
    textAlign: 'center',
    margin: '0 40px',
    [theme.breakpoints.only('md')]: {
      margin: '0 30px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 30,
    },
  },
  IndexContainer: {
    backgroundColor: theme.palette.primary.main,
    width: 60,
    height: 60,
    [theme.breakpoints.only('sm')]: {
      width: 75,
      height: 75,
    },
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginBottom: 20,
  },
  Index: {
    color: '#ffffff',
    [theme.breakpoints.only('sm')]: {
      fontSize: rem(22),
    },
  },
  TitleContainer: {
    [theme.breakpoints.up('lg')]: {
      height: 80,
    },
    [theme.breakpoints.only('md')]: {
      height: 60,
    },
  },
  Title: {
    [theme.breakpoints.down('md')]: {
      fontSize: rem(18),
    },
  },
  Description: {
    [theme.breakpoints.up('lg')]: {
      height: 180,
    },
    [theme.breakpoints.only('md')]: {
      height: 240,
    },
    '& p': {
      [theme.breakpoints.only('xs')]: {
        marginTop: 16,
      },
    },
  },
  Button: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
    },
  },
}));
