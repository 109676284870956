import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  leftImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  leftImageContent: {
    backgroundColor: theme.palette.background.card,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
  },
  leftImageInformation: {
    paddingTop: 25,
    [theme.breakpoints.up('md')]: {
      paddingTop: 100,
      paddingBottom: 100,
    },
  },
  logo: {
    width: 68,
    height: 69,
    marginBottom: rem(8),
    [theme.breakpoints.up('sm')]: {
      width: 136,
      height: 135,
    },
    [theme.breakpoints.up('md')]: {
      width: 68,
      height: 69,
    },
  },
  leftImageTitle: {},
  leftImageDescription: {
    margin: 0,
    marginTop: 28,
    marginBottom: 34,
    [theme.breakpoints.up('md')]: {
      marginTop: 15,
      marginBottom: 24,
    },
  },
  leftImageBtn: {},
  leftImageAccordion: {
    marginTop: 64,
    marginBottom: 48,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  accordion: {
    background: 'transparent',
    boxShadow: 'none',
    '&$expanded': {
      margin: 0,
    },
  },
  accordionTitle: {
    borderBottomWidth: 1,
    padding: 0,
    borderBottom: `2px solid ${theme.palette.lines.main}`,
    minHeight: 58,
    '&$expanded': {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderBottom: 'none',
      minHeight: 58,
    },
  },
  accordionTitleContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 23,
    marginRight: 0,
    marginBottom: 27,
    marginLeft: 0,
    '&$expanded': {
      marginTop: 24,
      marginRight: 0,
      marginBottom: 30,
      marginLeft: 0,
      '& > h3': {
        color: theme.palette.secondary.main,
      },
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 12,
      marginRight: 0,
      marginBottom: 12,
      marginLeft: 0,
      '&$expanded': {
        marginTop: 12,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: 0,
        '& > h3': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  expanded: {},
  removeBtn: {
    fill: theme.palette.primary.main,
  },
  addBtn: {
    fill: theme.palette.secondary.main,
  },
  accordionInformation: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      margin: 0,
      paddingBottom: 10,
    },
    '& > p:last-child': {
      borderBottom: `2px solid ${theme.palette.lines.main}`,
      paddingBottom: 35,
    },
    [theme.breakpoints.up('md')]: {
      '& > p': {
        color: theme.palette.text.secondary,
        maxWidth: '98%',
      },
      '& > p:last-child': {
        paddingBottom: 28,
      },
    },
  },
}));
