import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Slide } from 'react-full-page';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import useStyles from './styles';
import { getEntries } from '../../utils/contentful';
import ImageBanner from '../../components/c_033-image-banner/ImageBanner';
import NewsCarousel from '../../components/c_015-news-carousel/NewsCarousel';
import LeftImage from '../../components/c_008-left-image-accordion/LeftImage';
import TechnologyLocations from '../../components/c_017-technology-locations/TechnologyLocations';
import Footer from '../../components/c_003-Footer/Footer';

const Technology = () => {
  const [content, setContent] = useState({});
  const classes = useStyles();
  const queryParams = {
    content_type: 'contentPage',
    'fields.slug': '/technology',
    include: 2,
  };
  useEffect(() => {
    if (content && !content.sys) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);
          if (state) {
            setContent(state.items ? state.items[0] : {});
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
  }, [content, queryParams]);
  return (
    <div className={classes.Technology}>
      {
        !content || !content.fields
          ? (
            <CircularProgress className={classes.loadingProgress} />
          )
          : (
            <FullPageScroll>
              {
                content.fields.block_ref.map((item) => {
                  let component;
                  let isNewsCarouselComponent = false;
                  if (item.fields.layout === 'Banner') {
                    component = <ImageBanner fields={item.fields} />;
                  } else if (item.fields.accordion_item_ref) {
                    component = <LeftImage fields={item.fields} />;
                  } else if (item.sys.contentType.sys.id === 'blockBlog') {
                    isNewsCarouselComponent = true;
                    component = <NewsCarousel fields={item.fields} />;
                  } else if (item.fields.office_item_ref) {
                    component = <TechnologyLocations fields={item.fields} />;
                  }
                  return (
                    <Slide key={item.sys.id}>
                      <section className={`${classes.SlideContent} ${isNewsCarouselComponent ? classes.newCarouselSection : null}`}>
                        { component }
                      </section>
                    </Slide>
                  );
                })
              }
              <Slide>
                <Footer />
              </Slide>
            </FullPageScroll>
          )
      }
    </div>
  );
};

export default Technology;
