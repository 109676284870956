import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Box, Hidden,
} from '@material-ui/core';
import qs from 'query-string';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';

import { NavLink } from 'react-router-dom';
import useStyles from './styles';

import JobCard from '../c_019a-job-card/JobCard';

import careersNavLink from '../../utils/url';

const CareersList = ({
  fields,
  loading,
  itemsLimit,
  renderedItems,
  page,
  totalItems,
  history,
}) => {
  const { location } = history;
  const classes = useStyles();
  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const lastPage = Math.ceil(totalItems / itemsLimit);
  const refContainer = React.useRef(null);

  const handleScrollToElement = () => {
    window.scroll(0, refContainer.current.offsetTop - 100);
  };

  const pageNavLink = value => careersNavLink({
    page: value,
    address: queryString.address,
    department: queryString.department,
    q: queryString.q,
  });

  const renderJobsTree = (jobFields, index) => {
    const positions = jobFields.length > 0
      ? jobFields.map(jobsTree => (
        <section key={jobsTree.name} className={`${classes.jobGroup} ${index === 3 ? 'firstDepartment' : 'department'}`}>
          <Typography variant="h3" component="h2" className={classes.jobTitle}>
            {jobsTree.name}
          </Typography>
          {jobsTree.jobs && jobsTree.jobs.map(job => <JobCard jobInformation={job} key={job.id} />)}
          {jobsTree.children && renderJobsTree(jobsTree.children, index + 1)}
        </section>
      ))
      : (
        <Typography variant="h4" component="h3" className={classes.noResults}>
          No results found.
        </Typography>
      );
    return positions;
  };

  const createPagination = () => {
    const itemArray = [];
    for (let i = 0; i < lastPage; i += 1) {
      itemArray.push(
        <Typography variant="body1" component="span" key={`link-${i}`}>
          <NavLink
            to={pageNavLink(i + 1)}
            onClick={handleScrollToElement}
            exact
            className={`${classes.paginationElement} ${
              page === i ? 'active' : ''
            }`}
          >
            &nbsp;
            {i + 1}
            &nbsp;
          </NavLink>
        </Typography>,
      );
    }
    return itemArray;
  };

  return (

    <Grid container>
      <Grid item xs={11} ref={refContainer}>
        <Typography
          variant="h2"
          component="h2"
          className={classes.careersListTitle}
        >
          Apply to our Open Positions
        </Typography>
        {loading ? (
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <section>
            {renderJobsTree(fields, 1)}
            {!(page === 0 && renderedItems < itemsLimit) && (
              <Box className={classes.pagination}>
                <Hidden xsUp={lastPage === 1}>
                  <NavLink
                    disabled
                    to={pageNavLink(page === 0 ? 1 : page)}
                    className={`${classes.paginationPrevious} ${
                      page === 0 ? 'disabled' : ''
                    }`}
                    onClick={handleScrollToElement}
                  >
                    <ChevronLeft />
                  </NavLink>
                </Hidden>
                <Box className={classes.navPages}>{createPagination()}</Box>
                <Hidden xsUp={lastPage === 1}>
                  <NavLink
                    to={pageNavLink(
                      page === lastPage - 1 ? page + 1 : page + 2,
                    )}
                    className={`${classes.paginationNext} ${
                      page === lastPage - 1 ? 'disabled' : ''
                    }`}
                    onClick={handleScrollToElement}
                  >
                    <ChevronRight />
                  </NavLink>
                </Hidden>
              </Box>
            )}
          </section>
        )}
      </Grid>
    </Grid>
  );
};

CareersList.propTypes = {
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  itemsLimit: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  renderedItems: PropTypes.number.isRequired,
  history: PropTypes.shape({
    location: PropTypes.object,
  }),
};

CareersList.defaultProps = {
  fields: {},
  history: {},
};

export default CareersList;
