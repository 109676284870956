import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  footer: {
    paddingBottom: 35,
    paddingTop: 53,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 99,
      paddingTop: 86,
      paddingLeft: 90,
      paddingRight: 90,
    },
  },
  logoItem: {
    paddingBottom: 35,
    order: 1,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 58,
    },
    [theme.breakpoints.up('md')]: {
      order: 2,
      paddingBottom: 35,
    },
  },
  legal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 44,
    paddingBottom: 44,
    order: 3,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
      paddingBottom: 64,
    },
    [theme.breakpoints.up('md')]: {
      order: 2,
      justifyContent: 'flex-end',
      paddingTop: 0,
      paddingBottom: 35,
    },
  },
  footerItem: {
    paddingBottom: 50,
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.divider}`,
    order: 2,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 76,
    },
    [theme.breakpoints.up('md')]: {
      order: 3,
      paddingBottom: 67,
    },
  },
  socialList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    order: 4,
    [theme.breakpoints.up('md')]: {
      borderBottom: `2px solid ${theme.palette.divider}`,
      justifyContent: 'flex-end',
      paddingBottom: 67,
    },
  },
  logo: {
    display: 'flex',
    cursor: 'pointer',
    width: 40,
    height: 51,
    [theme.breakpoints.up('sm')]: {
      width: 63,
      height: 81,
    },
    [theme.breakpoints.up('md')]: {
      width: 47,
      height: 61,
    },
  },
  copyright: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: 'uppercase',
    lineHeight: 'normal',
    letterSpacing: '2.4px',
    [theme.breakpoints.up('sm')]: {
      letterSpacing: '4.8px',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
      letterSpacing: '2.4px',
    },
    '& > span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  },
  footerList: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  navItem: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    textTransform: 'uppercase',
    paddingLeft: 0,
    paddingRight: 0,
    width: 'auto',
    '& > span': {
      color: theme.palette.primary.main,
      letterSpacing: '5.1px',
      lineHeight: 2,
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: 25,
      '& > span': {
        letterSpacing: '5.76px',
      },
    },
    [theme.breakpoints.up('md')]: {
      '& > span': {
        letterSpacing: '0.8px',
      },
    },
    '&:hover': {
      '& > span': {
        color: theme.palette.secondary.main,
      },
    },
  },
  socialItems: {
    width: 25,
    height: 25,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      width: 64,
      height: 64,
    },
    [theme.breakpoints.up('md')]: {
      width: 25,
      height: 25,
    },
  },
}));
