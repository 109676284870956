import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  newsComponent: {
    height: '100%',
    alignItems: 'center',
  },
  newsCarouselTitle: {
    maxWidth: '85%',
  },
  Arrow: {
    width: 26,
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 13,
      height: 26,
    },
  },
  arrowLeft: {
    order: 2,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  CardsSlider: {
    order: 2,
    paddingTop: 40,
  },
  arrowRight: {
    order: 4,
    display: 'flex',
    justifyContent: 'center',
  },
  Slider: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: 'calc(66.5%)',
    },
    [theme.breakpoints.only('md')]: {
      paddingRight: 'calc(50% - 10px)',
    },
  },
  readMore: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
  },
}));
