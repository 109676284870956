import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Error404 = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.container}>
      <Typography variant="h1" component="h2">
        404
      </Typography>
      <Typography variant="h3" component="h3">
        Page Not Found
      </Typography>
    </Grid>
  );
};

export default Error404;
