import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Dialog,
} from '@material-ui/core';
import moment from 'moment';
import EventModal from './EventModal';
import useStyles from './styles';

function Event({
  name, description, location, date, url,
}) {
  const formattedDate = moment(date).format('MM-DD-YYYY');
  const formattedTime = moment(date).format('hh:mm UTCZ');
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Grid item className={classes.Event} onClick={handleClickOpen}>
        <Typography className={classes.EventDate}>
          {formattedDate}
        </Typography>
        <Typography variant="h4" component="h4" className={classes.EventTitle}>
          {name}
        </Typography>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
      >
        <EventModal
          formattedDate={formattedDate}
          name={name}
          description={description}
          location={location}
          time={formattedTime}
          url={url}
          handleClose={handleClose}
        />
      </Dialog>
    </>
  );
}

Event.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.object,
  location: PropTypes.string,
};

Event.defaultProps = {
  description: undefined,
  location: '',
};

export default Event;
