import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Hidden,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../common/RichTextOptions';
import ScrollIcon from '../common/ScrollIcon/ScrollIcon';
import LinkButton from '../common/LinkButton';
import UncontrolledLottie from '../common/Lottie/UncontrolledLottie';
import isIE from '../../utils/isIE';
import useBackgroundColor from '../../utils/useBackgroundColor';
import useStyles from './styles';

const Strategy = ({
  fields, index, inverted, firstSection, strategyLotties,
}) => {
  const classes = useStyles();
  const ref = useBackgroundColor('#ffffff');

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  const renderImage = (ctaItem, i) => {
    if (!isIE() && strategyLotties[i - 1]) {
      const animations = strategyLotties[i - 1];
      return (
        <Grid item xs={10} md={inverted ? 4 : 5} lg={5} className={`${classes.Image} ${inverted ? 'inverted' : ''}`}>
          <UncontrolledLottie
            className={`${classes.InvestmentImage} ${ctaItem.title.includes('Tactical') ? classes.MakeSmaller : ''}`}
            lotties={animations}
            delay={0.3}
          />
        </Grid>
      );
    }
    return ctaItem.image
      ? (
        <Grid
          item
          xs={10}
          lg={5}
          md={inverted ? 4 : 5}
          className={`${classes.Image} ${inverted ? 'inverted' : ''}`}
        >
          <img
            src={ctaItem.image.fields.file.url}
            alt={ctaItem.title}
            className={`${classes.InvestmentImage} ${ctaItem.title.includes('Tactical') || ctaItem.title.includes('Discretionary') ? classes.MakeSmaller : ''}`}
          />
        </Grid>
      ) : null;
  };

  return (
    <Grid container justify="center" className={classes.StrategyContainer} ref={ref}>
      <Grid item xs={12} sm={10} lg={8}>
        {fields.secondary_title && (
          <Grid container justify="center">
            <Grid item xs={10} md={11} lg={12}>
              <Typography variant="h2" component="h2" className={classes.SecondaryTitle}>
                {fields.secondary_title}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid container className={`${classes.Strategy} ${inverted ? 'inverted' : ''}`}>
          <Grid item xs={10} md={inverted ? 6 : 5} lg={6} className={`${classes.Content} ${inverted ? 'inverted' : ''}`}>
            <Typography variant="h2" component="h2" className={classes.Title}>
              {fields.title}
            </Typography>
            <div className={classes.Description}>
              {documentToReactComponents(fields.content, options)}
            </div>
            {fields.button_text ? (
              <LinkButton
                variant="contained"
                color="primary"
                to={fields.button_slug}
              >
                {fields.button_text}
              </LinkButton>
            ) : null}
          </Grid>
          {renderImage(fields, index)}
        </Grid>
      </Grid>
      {firstSection && (
      <Hidden smDown>
        <Grid
          item
          xs={12}
        >
          <ScrollIcon />
        </Grid>
      </Hidden>
      )}

    </Grid>
  );
};

Strategy.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    secondary_title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    content: PropTypes.object,
    image: PropTypes.object,
  }).isRequired,
  strategyLotties: PropTypes.arrayOf(PropTypes.array),
  index: PropTypes.number.isRequired,
  inverted: PropTypes.bool,
  firstSection: PropTypes.bool,
};
Strategy.defaultProps = { inverted: false, firstSection: false, strategyLotties: [] };

export default Strategy;
