import asiaPacificCountries from '../../../utils/asiaPacificCountries';

export default class Matcher {
  constructor(department, address, search) {
    this.department = department === 'All Departments' ? '' : department;
    this.address = address === 'All Offices' ? '' : address;
    this.search = search;
    this.count = 0;
    this.underMatchedDepartment = false;
    this.departmentStack = 0;
  }

  static includes(text1, text2) {
    return text1.toLowerCase().indexOf(text2.toLowerCase().trim()) !== -1;
  }

  pushDepartment(departmentName) {
    if (this.department === '') return;

    if (!this.underMatchedDepartment) {
      this.underMatchedDepartment = departmentName.replace('/', '-').trim() === this.department.trim();
    }

    if (this.underMatchedDepartment) this.departmentStack += 1;
  }

  popDepartment() {
    if (this.department === '') return;

    if (this.underMatchedDepartment) this.departmentStack -= 1;
    if (this.departmentStack === 0) this.underMatchedDepartment = false;
  }

  match(job) {
    if (this.address === 'Asia-Pacific' && asiaPacificCountries.find(x => job.location.name.includes(x)) === undefined) return false;
    if (this.address !== '' && this.address !== 'Asia-Pacific' && !job.location.name.includes(this.address)) return false;

    if (this.search !== '' && !Matcher.includes(job.title, this.search)) return false;
    if (this.department !== '' && !this.underMatchedDepartment) return false;

    this.count += 1;

    return true;
  }
}
