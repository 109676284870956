import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import useStyles from './styles';
import careersNavLink from '../../../utils/url';

function Location({ title, image }) {
  const classes = useStyles();

  const pageNavLink = value => careersNavLink({
    page: '1',
    address: value,
    department: 'All Departments',
  });

  const subTitle = `SEE OUR OPEN POSITIONS IN ${title}`;
  return (
    <Grid container className={classes.Location}>
      <Grid item xs={12}>
        <img src={image.fields.file.url} alt={title} className={classes.LocationImage} />
        <Typography variant="subtitle1" component="h3">
          {title}
        </Typography>
        <Link className={classes.LocationLink} to={pageNavLink(title)}>
          <Typography variant="h5" component="span">
            {subTitle.toUpperCase()}
          </Typography>
          <RightArrow className={classes.Arrow} />
        </Link>
      </Grid>
    </Grid>
  );
}

Location.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  image: PropTypes.object.isRequired,
};

export default Location;
