import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useStyles from './styles';
import { getEntries } from '../../utils/contentful';
import SearchBar from '../../components/c_022-search-bar/SearchBar';
import FeaturedArticles from '../../components/c_020-featured-articles/FeaturedArticles';
import Footer from '../../components/c_003-Footer/Footer';
import DetailPage from '../../components/C_025-DetailPage/DetailPage';

const BlogDetailPage = (props) => {
  const [content, setContent] = useState(null);
  const [parameters, setParameters] = useState({
    categoryTags: {
      financial: '',
      news: '',
      events: '',
      'featured-slug': '',
    },
    activePost: '',
    search: '',
  });
  const classes = useStyles();
  const { match, location, history } = props;
  const { category, post } = match.params;

  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { q: search } = queryString;

  const queryParamsEntries = {
    content_type: 'blogPost',
    'fields.category[match]': category,
    'fields.slug[in]': post,
  };

  const blogTitles = {
    News: 'News & Press',
    Culture: 'Culture',
    Technology: 'From the developers',
  };

  const queryParamsHashtags = {
    content_type: 'tag',
  };

  const refContainer = React.useRef(null);

  useEffect(() => {
    let mounted = true;
    if (!content || post !== parameters.activePost) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParamsEntries);
          const stateHt = await getEntries(queryParamsHashtags);

          if (stateHt) {
            const tagElements = {};
            stateHt.items.forEach((item) => { tagElements[item.fields.slug] = item.sys.id; });

            if (mounted && state) {
              setContent(state.items.length > 0 ? state.items : []);
              setParameters({
                search,
                categoryTags: tagElements,
                activePost: post,
              });
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
    return () => { mounted = false; };
  }, [
    content,
    parameters,
    setParameters,
    queryParamsEntries,
    queryParamsHashtags,
    post,
    search,
  ]);

  const searchNavLink = (value) => {
    let link = '?page=1&';
    if (value.trim()) {
      link += `q=${value.trim()}`;
    }
    history.push(`/blog/${category}${link}`);
  };

  const handleScrollToElement = () => {
    window.scroll(0, refContainer.current.offsetTop - 100);
  };

  return (
    <div className={classes.BlogDetailPage}>
      {
          !content
            ? (
              <CircularProgress className={classes.loadingProgress} />
            )
            : (
              <Box>
                <Grid container direction="row-reverse" className={classes.Section}>
                  <Grid item xs={11}>
                    <Grid container>
                      <Grid item xs={3} sm={2} md={1}>
                        <Link to={`/blog/${category}`} className={classes.backbtn}>
                          <ArrowBackIcon />
                          Back
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={11} md={4} direction="row-reverse">
                    <Box className={classes.Sidebar}>
                      <Typography variant="h3" component="h3" className={classes.SideSectionTitle}>
                      Choose a Category
                      </Typography>
                      <Typography variant="h5" component="span" className={classes.SideHashtag}>
                        <NavLink to={`/blog/${category}?tagId=${parameters.categoryTags.financial}`}>
                        #Financial
                        </NavLink>
                      </Typography>
                      <Typography variant="h5" component="span" className={classes.SideHashtag}>
                        <NavLink to={`/blog/${category}?tagId=${parameters.categoryTags.news}`}>
                        #News
                        </NavLink>
                      </Typography>
                      <Typography variant="h5" component="span" className={classes.SideHashtag}>
                        <NavLink to={`/blog/${category}?tagId=${parameters.categoryTags.events}`}>
                        #Events
                        </NavLink>
                      </Typography>
                      <Typography variant="h3" component="h3" className={classes.SideSectionTitle}>
                      Search for Articles
                      </Typography>
                      <SearchBar onSearch={searchNavLink} initValue={search} />
                      <Typography variant="h3" component="h3" className={classes.SideSectionTitle}>
                      Featured Articles
                      </Typography>
                      <FeaturedArticles
                        tagIds={parameters.categoryTags}
                        onClickEvent={handleScrollToElement}
                      />
                    </Box>
                  </Grid>
                  <Grid container item alignContent="flex-start" justify="flex-start" xs={11} md={7}>
                    <Grid item xs={11}>
                      <Typography variant="h2" component="h2" className={classes.BlogTitle} ref={refContainer}>
                        {blogTitles[category] || 'From the developers'}
                      </Typography>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (content[0])
                          ? content.map(item => (
                            <DetailPage
                              key={item.sys.id}
                              item={item}
                              category={category}
                              className={classes.BlogCard}
                            />
                          ))
                          : (
                            <Typography variant="h4" component="h3">
                              No results found.
                            </Typography>
                          )
                    }
                    </Grid>
                  </Grid>
                </Grid>
                <Footer />
              </Box>
            )
      }
    </div>
  );
};

BlogDetailPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default BlogDetailPage;
