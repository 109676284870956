import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  Dropdown: {},
  DropdownField: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.text.light}`,
    borderRadius: 15,
    paddingLeft: 19,
    height: 32.8,
    '& input::placeholder': {
      color: theme.palette.primary.black,
      opacity: 1,
      fontSize: rem(10),
      fontWeight: 'normal',
      letterSpacing: 2.4,
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        letterSpacing: 2.88,
        fontSize: rem(12),
        fontWeight: 'bold',
      },
    },
    '&.open': {
      borderBottom: 0,
      borderRightWidth: 1,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  InputRoot: {
    width: '100%',
  },
  InputField: {
    height: '100%',
    color: theme.palette.primary.black,
    opacity: 1,
    padding: 0,
    fontSize: rem(10),
    fontWeight: 'normal',
    letterSpacing: 2.4,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      letterSpacing: 2.88,
      fontSize: rem(12),
      fontWeight: 'bold',
    },
  },
  IconButton: {
    padding: 0,
    height: '100%',
    width: 32.8,
    color: theme.palette.primary.main,
    '& svg': {
      transition: 'transform 0.5s',
      fontSize: 25,
    },
    '&.open': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  DropdownList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    border: `1px solid ${theme.palette.text.light}`,
    borderTop: 0,
    borderBottomLeftRadius: 23,
    borderBottomRightRadius: 23,
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: 270,
    transition: 'max-height 0.25s ease-in',
    '& a': {
      textDecoration: 'none',
    },
  },
  DropdownItem: {
    borderTop: `1px solid ${theme.palette.text.light}`,
    color: theme.palette.primary.black,
    fontSize: rem(10),
    fontWeight: 'normal',
    letterSpacing: 2.4,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      letterSpacing: 2.88,
      fontSize: rem(12),
      fontWeight: 'bold',
    },
    padding: '10px 19px',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.lines.light,
    },
  },
}));
