import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  CareerStages: {
    height: '90%',
    alignItems: 'center',
  },
  centerLine: {
    textAlign: 'center',
    marginBottom: 40,
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  StagesContainer: {
    height: '100%',
    position: 'relative',
  },
  line: {
    position: 'absolute',
    height: 1,
    backgroundColor: theme.palette.background.specialBlue,
    width: '40%',
    top: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
