import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { getEntries } from '../../utils/contentful';
import useStyles from './styles';

const FeaturedArticles = ({ tagIds, onClickEvent }) => {
  const [posts, setPosts] = useState([]);
  const featuredSlug = tagIds['featured-slug'];
  const classes = useStyles();
  const currentDate = moment().format();

  const queryParams = {
    content_type: 'blogPost',
    'fields.publishDate[lte]': currentDate,
    links_to_entry: featuredSlug,
    order: '-fields.publishDate',
    include: 2,
    limit: 4,
  };

  useEffect(() => {
    let mounted = true;
    if (posts.length === 0 && featuredSlug !== '') {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);

          if (mounted && state) {
            setPosts(state.items ? state.items : {});
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
    return () => { mounted = false; };
  }, [posts, featuredSlug, queryParams]);

  return (
    <div className={classes.FeaturedArticles}>
      {
        posts.map(item => (
          <div key={item.sys.id} className={classes.article}>
            <Typography variant="h5" component="h5" className={classes.title}>
              {item.fields.title}
            </Typography>
            <Typography variant="body2" component="div">
              <NavLink to={`/blog/${item.fields.category}/${item.fields.slug}`} className={classes.readMoreLink} onClick={onClickEvent}>
                Read More
                <ChevronRight />
              </NavLink>
            </Typography>
          </div>
        ))
      }
    </div>
  );
};

FeaturedArticles.propTypes = {
  tagIds: PropTypes.shape({
    'featured-slug': PropTypes.string,
  }).isRequired,
  onClickEvent: PropTypes.func.isRequired,
};

export default FeaturedArticles;
