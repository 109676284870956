import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';

const Timeline = ({
  items,
  activeSlide,
  setActiveSlide,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(415));
  const marginLeft = isMobile ? `calc(${((4 / 6) * activeSlide * -100)}vw - ${32 * activeSlide}px)` : (activeSlide * -310);
  const cardContainerWidth = items.length * 310;

  const handleEventClick = (e) => {
    const button = e.target;
    setActiveSlide(parseInt(button.value, 10));
  };

  return (
    <Grid container className={classes.timeline} style={{ marginLeft }}>
      <Grid item xs={12}>
        <div
          className={classes.cardContainer}
          style={{ width: cardContainerWidth }}
        >
          {
            items.map(({ fields }, i) => (
              <div key={`${fields.title}-${Math.random() * i}`} className={`${classes.eventCard} ${activeSlide === i ? 'active' : ''}`}>
                <div className={classes.cardIcon}>
                  {
                    fields.image
                      ? (<img src={fields.image.fields.file.url} alt={fields.title} />)
                      : ''
                  }
                </div>
                <Typography variant="h5" component="h5" className={classes.cardTitle}>
                  {fields.title}
                </Typography>
                <Typography paragraph>
                  {fields.content.content.map(item => item.content.map(cardText => (
                    cardText.value
                  )))}
                </Typography>
              </div>
            ))
          }
        </div>
      </Grid>
      <Grid
        item
        className={classes.bottomContainer}
        style={{ width: cardContainerWidth }}
      >
        <div className={classes.lineTitleContainer}>
          <Typography variant="h3" component="h3" className={classes.lineTitle}>
            Timeline
          </Typography>
        </div>
        <div
          className={classes.lineContainer}
          style={{ width: cardContainerWidth }}
        >
          {
            items.map(({ fields }, i) => (
              <Box key={`${fields.title}-${Math.random() * i}`} className={`${classes.eventButtonBox} ${activeSlide === i ? 'active' : ''}`}>
                <button
                  value={i}
                  label={fields.title}
                  type="button"
                  className={`${classes.event} ${activeSlide === i ? classes.activeEvent : ''}`}
                  onClick={handleEventClick}
                />
                <Typography variant="body2" component="div" className={classes.eventLabel}>
                  {fields.title}
                </Typography>
              </Box>
            ))
          }
        </div>
      </Grid>
    </Grid>
  );
};

Timeline.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default Timeline;
