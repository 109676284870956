import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  staticTextImageComponent: {
    height: '100%',
    alignItems: 'center',
  },
  staticTextImageContent: {
    paddingTop: 25,
    paddingBottom: 25,
    justifyContent: 'space-around',
    [theme.breakpoints.up('md')]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  leftImage: {
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  rightImage: {
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  rightinformationOrder: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  secondaryTitle: {
    marginTop: 20,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  staticTextImageTitle: {
    marginBottom: 20,
  },
  staticTextImageParagraph: {
    maxWidth: 400,
  },
  rightImageOrder: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginLeft: '2%',
      marginRight: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '6%',
    },
  },
  leftImageOrder: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: '5%',
      marginRight: '2%',
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: '6%',
    },
  },
  staticImage: {
    width: '100%',
    height: '100%',
    maxHeight: 400,
  },
  staticImageLottie: {
    width: '100%',
    margin: 'auto',
  },
}));
