export const greenhouseBoardToken = process.env.REACT_APP_GREENHOUSE_BOARD_TOKEN;

export const contactFormURL = process.env.REACT_APP_CONTACT_FORM_URL;
export const contactFormSiteKey = process.env.REACT_APP_CONTACT_FORM_SITE_KEY;

export const contentfulSpaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const contentfulApiKey = process.env.REACT_APP_CONTENTFUL_API_KEY;
export const contentfulEnvironment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;

export const googleAnalyticsTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
