import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { ReactComponent as SliderArrow } from '../../../assets/slider-icon.svg';
import useStyles from './styles';

const ArrowIcon = (props) => {
  const {
    reverseArrow,
    onClickHandler,
    disabled,
    buttonClass,
    arrowClass,
  } = props;
  const classes = useStyles();
  return (
    <IconButton
      aria-label={reverseArrow ? 'Previous' : 'Next'}
      className={`${classes.carouselIcon} ${buttonClass} ${reverseArrow ? classes.prevButton : null}`}
      onClick={onClickHandler}
      disabled={disabled}
      disableRipple
    >
      <SliderArrow className={arrowClass} />
    </IconButton>
  );
};

ArrowIcon.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  reverseArrow: PropTypes.bool,
  buttonClass: PropTypes.string,
  arrowClass: PropTypes.string.isRequired,
};

ArrowIcon.defaultProps = {
  reverseArrow: false,
  buttonClass: '',
};

export default ArrowIcon;
