import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';


const BulletList = (props) => {
  const classes = useStyles();
  const { children, className } = props;

  return (
    <div className={`${classes.BulletList} ${className}`}>
      { children }
    </div>
  );
};

BulletList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

BulletList.defaultProps = {
  className: '',
};

export default BulletList;
