import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import LinkButton from '../common/LinkButton';
import { headingOptions } from '../common/RichTextOptions';
import useStyles from './styles';

const LeftImage = ({ fields }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const initialState = !isMobile ? fields.accordion_item_ref[0].fields.title : false;
  const [accordionExpanded, setAccordionExpanded] = useState(
    initialState,
  );
  const classes = useStyles();

  const handleAccordionChange = panel => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  return (
    <React.Fragment>
      <Grid container direction="row-reverse" className={classes.leftImage}>
        <Grid item xs={11} sm={10} md={10} className={classes.leftImageContent}>
          <Grid container alignItems="center" justify="space-around">
            <Grid item xs={10} md={4} className={classes.leftImageInformation}>
              {
                fields.image
                  ? (
                    <img
                      src={fields.image.fields.file.url}
                      alt={fields.title}
                      className={classes.logo}
                    />
                  )
                  : ''
              }
              <Typography variant="h2" component="h2" className={classes.leftImageTitle}>
                {fields.title}
              </Typography>
              <section className={classes.leftImageDescription}>
                {documentToReactComponents(fields.content, options)}
              </section>
              {fields.button_text && (
              <LinkButton
                variant="outlined"
                color="primary"
                to={fields.button_slug}
                className={classes.leftImageBtn}
              >
                {fields.button_text}
              </LinkButton>
              ) }
            </Grid>
            <Grid item xs={10} md={4} className={classes.leftImageAccordion}>
              {
                fields.accordion_item_ref.map((fieldsMap) => {
                  const info = documentToReactComponents(fieldsMap.fields.content, options);
                  return (
                    <ExpansionPanel
                      key={fieldsMap.fields.title}
                      expanded={accordionExpanded === fieldsMap.fields.title}
                      onChange={handleAccordionChange(fieldsMap.fields.title)}
                      classes={{
                        root: classes.accordion,
                        expanded: classes.expanded,
                      }}
                    >
                      <ExpansionPanelSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        classes={{
                          content: classes.accordionTitleContent,
                          root: classes.accordionTitle,
                          expanded: classes.expanded,
                        }}
                      >
                        <Typography variant="h3" component="h3">{fieldsMap.fields.title}</Typography>
                        {accordionExpanded === fieldsMap.fields.title
                          ? (
                            <Remove
                              className={classes.removeBtn}
                              onClick={handleAccordionChange(fieldsMap.fields.title)}
                            />
                          )
                          : (
                            <Add
                              className={classes.addBtn}
                              onClick={handleAccordionChange(fieldsMap.fields.title)}
                            />
                          )}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails
                        classes={{
                          root: classes.accordionInformation,
                        }}
                      >
                        {info}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  );
                })
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

LeftImage.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    image: PropTypes.object,
    content: PropTypes.object,
    accordion_item_ref: PropTypes.array,
  }).isRequired,
};

export default LeftImage;
