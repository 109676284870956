import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  Department: {
    width: 'calc((100% - 100px)/7)',
    height: 175,
    [theme.breakpoints.down('md')]: {
      width: 'calc((100% - 100px)/4)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc((100% - 100px)/3)',
    },
    [theme.breakpoints.only('xs')]: {
      width: 'calc((100% - 50px)/2)',
      height: 125,
    },
    backgroundColor: '#afc8ff',
    '& span': {
      margin: 15,
    },
    '&:nth-child(2n)': {
      backgroundColor: theme.palette.background.lightBlue,
    },
    '&:nth-child(3n)': {
      backgroundColor: '#bee6e6',
    },
    '&:nth-child(4n)': {
      backgroundColor: '#ccc',
    },
    '&:nth-child(5n)': {
      backgroundColor: '#c6d8ff',
    },
    '&:nth-child(6n)': {
      backgroundColor: '#92d5d6',
    },
    '&:nth-child(8n)': {
      backgroundColor: '#8db1ff',
    },
    '&:nth-child(11n)': {
      backgroundColor: '#92d5d6',
    },
    '&:nth-child(12n)': {
      backgroundColor: '#ccc',
    },
  },
  LocationLink: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    '&:hover span': {
      textDecoration: 'underline',
    },
    '& span': {
      [theme.breakpoints.only('xs')]: {
        fontSize: rem(14),
      },
    },
  },
}));
