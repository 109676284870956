import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Slide } from 'react-full-page';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import useStyles from './styles';
import { getEntries } from '../../utils/contentful';
import ImageBanner from '../../components/c_033-image-banner/ImageBanner';
import Values from '../../components/c_005-block-values-slider/Values';
import ImageGallery from '../../components/c_032_imageGallery/ImageGallery';
import ManagementTeam from '../../components/c_013-management-team/ManagementTeam';
import Diversity from '../../components/c_028_diversity/Diversity';
import Footer from '../../components/c_003-Footer/Footer';

const Culture = () => {
  const [content, setContent] = useState({});
  const classes = useStyles();
  const queryParams = {
    content_type: 'contentPage',
    'fields.slug': '/culture',
    include: 2,
  };
  useEffect(() => {
    if (content && !content.sys) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);
          if (state) {
            setContent(state.items ? state.items[0] : {});
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
  }, [content, queryParams]);

  return (
    <div className={classes.Culture}>
      {
        !content || !content.fields
          ? (
            <CircularProgress className={classes.loadingProgress} />
          )
          : (
            <FullPageScroll>
              {
                content.fields.block_ref.map((item) => {
                  let component;
                  if (item.fields.layout && item.fields.layout === 'Banner') {
                    component = <ImageBanner fields={item.fields} />;
                  } else if (item.fields.value_item_ref) {
                    component = <Values fields={item.fields} showAll />;
                  } else if (item.sys.contentType.sys.id === 'blockImages') {
                    component = (
                      <ImageGallery
                        fields={item.fields.images}
                        title={item.fields.title}
                      />
                    );
                  } else if (item.fields.layout === 'Quote') {
                    component = (
                      <Diversity
                        fields={item.fields}
                      />
                    );
                  } else if (item.fields.person_item_ref) {
                    component = <ManagementTeam fields={item.fields} spotlight />;
                  } else {
                    return null;
                  }
                  return (
                    <Slide key={item.sys.id}>
                      <section key={item.sys.id} className={`${classes.Section} ${item.fields.layout === 'Quote' ? classes.DEI : ''}`}>
                        { component }
                      </section>
                    </Slide>

                  );
                })
              }
              <Slide>
                <Footer />
              </Slide>
            </FullPageScroll>
          )
      }
    </div>
  );
};

export default Culture;
