import { makeStyles } from '@material-ui/styles';
import isIE from '../../utils/isIE';

export default makeStyles(theme => ({
  rightImage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  carouselInformation: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  carouselIllustrations: {
    display: isIE() ? 'flex' : 'block',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 5,
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginBottom: 0,
    },
  },
  arrowBlock: {
    zIndex: 1299,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '25%',
      width: 'auto',
    },
  },
  nextSlideArrow: {
    [theme.breakpoints.down('sm')]: {
      right: '3vw',
    },
  },
  rightImageText: {
    paddingTop: 27,
    paddingBottom: 34,
    [theme.breakpoints.up('md')]: {
      paddingTop: 21,
      paddingBottom: 20,
      maxWidth: 335,
    },
  },
  rightImageBtn: {},
  imageCarousel: {
    maxWidth: 511,
    maxHeight: 551,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 427,
      maxHeight: 461,
    },
  },
  lottiePortrait: {
    width: '82%',
    margin: 'auto',
  },
  iconSize: {
    width: 30,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 50,
    },
  },
}));
