import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  BlogDetailPage: {},
  loadingProgress: {
    display: 'block',
    position: 'fixed',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    zIndex: 1301,
  },
  Section: {
    width: '100vw',
    paddingTop: 100,
    [theme.breakpoints.up('md')]: {
      paddingTop: 160,
    },
  },
  Sidebar: {
    backgroundColor: theme.palette.background.card,
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 32,
    paddingRight: 32,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 40,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
      paddingTop: 45,
      paddingBottom: 45,
      paddingLeft: 50,
      paddingRight: 50,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      position: 'relative',
    },
  },
  SideSectionTitle: {
    paddingBottom: 15,
    '&:not(:first-child)': {
      paddingTop: 25,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(28),
      paddingBottom: 25,
      '&:not(:first-child)': {
        paddingTop: 40,
      },
    },
  },
  SideHashtag: {
    display: 'block',
    textTransform: 'uppercase',
    color: theme.palette.primary.black,
    cursor: 'pointer',
    letterSpacing: 4.32,
    lineHeight: 1.64,
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: rem(11),
      letterSpacing: 2.6,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  BlogTitle: {
    marginTop: 25,
    marginBottom: 25,
    [theme.breakpoints.up('md')]: {
      marginBottom: 35,
    },
  },
  BlogCard: {
    height: 'auto!important',
    marginBottom: 40,
  },
  pagination: {
    display: 'flex',
  },
  paginationElement: {
    color: theme.palette.primary.black,
    fontSize: rem(16),
    textDecoration: 'none',

    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  paginationPrevious: {
    flexGrow: 0,
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '&.disabled': {
      opacity: 0.6,
      cursor: 'unset',
    },
  },
  navPages: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    lineHeight: 1,
  },
  paginationNext: {
    flexGrow: 0,
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '&.disabled': {
      opacity: 0.6,
      cursor: 'unset',
    },
  },
  backbtn: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    transition: 'color 0.5s ease',
    marginBottom: 25,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
}));
