import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import useBackgroundColor from '../../utils/useBackgroundColor';

function SingleMedia({ fields }) {
  const classes = useStyles();
  const ref = useBackgroundColor('#ffffff');
  const isVideo = fields.image.fields.file.contentType === 'video/mp4';
  const videoRef = useRef(null);

  useEffect(() => {
    let observer;
    if (isVideo) {
      const handlePause = (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            videoRef.current.pause();
          }
        });
      };

      observer = new IntersectionObserver(handlePause, {
        rootMargin: '0px',
        threshold: [0.25, 0.75],
      });
      observer.observe(videoRef.current);
    }
    return () => observer && observer.disconnect();
  }, [isVideo]);

  return (
    <Grid container justify="center" className={classes.SingleMedia} ref={ref}>
      <Grid item xs={10} sm={8} md={9} lg={8}>
        {isVideo && (
        <Grid item>
          <Typography variant="h2" component="h2" className={classes.centerLine}>{fields.title}</Typography>
        </Grid>
        )}
        <Grid container justify="center" alignContent="center">
          <Grid item xs={12} sm={10} md={8} className={classes.mediaContainer}>
            {isVideo && (
            <video
              className={classes.mediaItem}
              controls
              ref={videoRef}
            >
              <source src={`${fields.image.fields.file.url}#t=0.001`} type="video/mp4" />
              <track kind="captions" />
            </video>
            )}
            {!isVideo && (
            <>
              <img
                className={classes.mediaItem}
                src={fields.image.fields.file.url}
                alt={fields.image.fields.title}
                key={fields.image.sys.id}
              />
              <Typography variant="h3" component="span" className={classes.imageCaption}>{fields.title}</Typography>
            </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SingleMedia.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
};

export default SingleMedia;
