import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  '@global': {
    body: {
      [theme.breakpoints.down('sm')]: {
        '& #root > div > div > div > div > .CareersPage': {
          backgroundColor: '#7dd8d8',
        },
      },
      [theme.breakpoints.up('lg')]: {
        '& header > div > div > div:nth-of-type(2) > nav > ul > div > .active': {
          '& span': {
            color: '#ffffff',
          },
          '&:after': {
            backgroundColor: '#ffffff',
          },
        },
        '& header > div > div > div:nth-of-type(2) > nav > ul > div > a.scrolling > span': {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  MainIllustration: {
    height: '90%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      height: '100%',
    },
    alignItems: 'center',
  },
  Illustration: {
    width: '100%',
  },
  centerLine: {
    paddingBottom: 40,
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
