import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import LinkButton from '../common/LinkButton';
import useStyles from './styles';

const Internships = ({
  fields,
}) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" alignContent="center" className={classes.InternshipComponent}>
      <Grid item xs={8} sm={4} md={3}>
        <Typography variant="h2" component="h2" className={`${classes.InternshipTitle} ${classes.centerLine}`}>
          {fields.title}
        </Typography>
      </Grid>
      <Grid container justify="center" className={classes.ButtonContainer}>
        {fields.cta_item_ref.map(button => (
          <LinkButton
            variant="contained"
            color="primary"
            key={button.fields.button_text}
            to={button.fields.button_slug}
            className={classes.InternshipButton}
          >
            {button.fields.button_text}
          </LinkButton>
        ))}
      </Grid>
    </Grid>
  );
};

Internships.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    cta_item_ref: PropTypes.array,
  }).isRequired,
};

export default Internships;
