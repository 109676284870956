import * as contentful from 'contentful';
import { contentfulSpaceId, contentfulApiKey, contentfulEnvironment } from './config';

const client = contentful.createClient({
  space: contentfulSpaceId,
  accessToken: contentfulApiKey,
  environment: contentfulEnvironment,
});

export async function getEntries(params) {
  const data = await client.getEntries(params);
  return data;
}

export async function getEntry(id) {
  const data = await client.getEntry(id);
  return data;
}

export async function getContentTypes(params) {
  const data = await client.getContentTypes(params);
  return data;
}

export async function getAssets(params) {
  const data = await client.getAssets(params);
  return data;
}
