import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Slide } from 'react-full-page';
import { getEntries } from '../../utils/contentful';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import CenterText from '../../components/c_011-center-text/CenterText';
import Strategy from '../../components/c_012-investment-strategy-block/Strategy';
import Footer from '../../components/c_003-Footer/Footer';

import FEBackground from '../../assets/animations/FE-Background.json';
import FEPeople from '../../assets/animations/FE-People.json';

import QSBackground from '../../assets/animations/Quantitative-Strategies-background.json';
import QSPeople from '../../assets/animations/Quantitative-Strategies-People.json';

import TTBack from '../../assets/animations/Tactical-Trading-Back.json';
import TTFront from '../../assets/animations/Tactical-Trading-Front.json';
import TTPeople from '../../assets/animations/Tactical-Trading-People.json';

import DMFIAnimation from '../../assets/animations/DMFI-final.json';

import useStyles from './styles';

const strategyLotties = [
  [
    { name: 'QSBackground', animation: QSBackground, loop: true },
    { name: 'QSPeople', animation: QSPeople, loop: true },
  ],
  [
    { name: 'FEBackground', animation: FEBackground, loop: false },
    { name: 'FEPeople', animation: FEPeople, loop: true },
  ],
  [
    { name: 'TTBack', animation: TTBack, loop: false },
    { name: 'TTPeople', animation: TTPeople, loop: true },
    { name: 'TTFront', animation: TTFront, loop: false },
  ],
  [{ name: 'DMFIAnimation', animation: DMFIAnimation, loop: true }],
];

const InvestmentStrategies = () => {
  const [content, setContent] = useState({});
  const classes = useStyles();

  const queryParams = {
    content_type: 'contentPage',
    'fields.slug': '/investment-approach',
    include: 2,
  };

  useEffect(() => {
    if (content && !content.sys) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);
          if (state) {
            setContent(state.items ? state.items[0] : {});
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
  }, [content, queryParams]);

  return (
    <div className={classes.InvestmentStrategies}>
      {
        !content || !content.fields
          ? (
            <CircularProgress className={classes.loadingProgress} />
          )
          : (
            <FullPageScroll>
              {
                content.fields.block_ref.map((item, index) => {
                  let component;
                  if (item.fields.layout === 'Full Width (Right Image)') {
                    component = (
                      <Strategy
                        fields={item.fields}
                        index={index + 1}
                        inverted={index % 2 !== 0}
                        firstSection={index === 0}
                        strategyLotties={strategyLotties}
                      />
                    );
                  } else {
                    component = (
                      <CenterText
                        fields={item.fields}
                        firstSection={index === 0}
                        centered
                      />
                    );
                  }
                  return (
                    <Slide key={item.sys.id}>
                      <section className={classes.SlideContent}>
                        {component}
                      </section>
                    </Slide>
                  );
                })
              }
              <Slide>
                <Footer />
              </Slide>
            </FullPageScroll>
          )
      }
    </div>
  );
};

export default InvestmentStrategies;
