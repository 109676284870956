import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  TechnologyLocations: {
  },
  Content: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  ImageWrapper: {
    marginBottom: '5%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      marginBottom: 0,
    },
  },
  OfficesImage: {
    width: '100%',
    height: '100%',
    maxWidth: 450,
    [theme.breakpoints.up('md')]: {
      marginLeft: '15%',
    },
  },
  LocationsTitle: {
    marginBottom: '10%',
    [theme.breakpoints.up('md')]: {
      marginBottom: '5%',
    },
  },
  LocationEntry: {
    marginBottom: '5%',
  },
  LocationTitle: {
    fontSize: rem(20),
    [theme.breakpoints.up('lg')]: {
      fontSize: rem(28),
    },
    color: theme.palette.primary.main,
  },
  LocationLink: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      justifyContent: 'flex-start',
    },
    '& span': {
      fontSize: rem(14),
      [theme.breakpoints.up('lg')]: {
        fontSize: rem(16),
      },
      textTransform: ' uppercase',
      color: theme.palette.secondary.main,
    },
    '&:hover span': {
      color: theme.palette.primary.main,
    },
  },
  Arrow: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
}));
