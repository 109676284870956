import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(() => ({
  TitleRichText: {
    padding: 0,
    marginTop: rem(16),
    marginBottom: rem(16),
    '&:before': {
      content: 'none',
    },
  },

}));
