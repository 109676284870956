import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const Ellipsis = ({ text, maxLine, mobileLines }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);

  return (
    <ResponsiveEllipsis
      unsafeHTML={text}
      maxLine={isMobile ? mobileLines : maxLine}
    />
  );
};

Ellipsis.propTypes = {
  text: PropTypes.string.isRequired,
  maxLine: PropTypes.string.isRequired,
  mobileLines: PropTypes.string,
};

Ellipsis.defaultProps = {
  mobileLines: '2',
};


export default Ellipsis;
