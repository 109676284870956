import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  Departments: {
    height: '90%',
    alignItems: 'center',
  },
  DepartmentTitle: {
    marginBottom: 40,
  },
  DepartmentContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  centerLine: {
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
