import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';
import useOnScreen from '../../utils/useOnScreen';
import ResponsiveImage from '../common/ResponsiveImage';
import useStyles from './styles';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ManagementTeam = ({ fields, spotlight }) => {
  const classes = useStyles();
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [ref, isVisible] = useOnScreen();
  const SliderLength = (fields.person_item_ref).length;

  const handleSlideChange = (step) => {
    setActiveSlide(step);
  };

  const handleNextSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide + 1);
  };

  const handlePreviousBack = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide - 1);
  };

  return (
    <Grid container className={classes.managementTeam} ref={ref}>
      <Grid container direction="row-reverse">
        <Grid item xs={10}>
          <Typography variant="h2" component="h2" className={classes.managementTeamTitle}>
            {fields.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid container justify="center">
          <Grid item xs={5} className={classes.arrowBlock}>
            <ArrowIcon
              disabled={activeSlide === 0}
              onClickHandler={handlePreviousBack}
              reverseArrow
              arrowClass={classes.iconSize}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8}>
        <AutoPlaySwipeableViews
          autoplay={isVisible}
          axis="x"
          index={activeSlide}
          onChangeIndex={handleSlideChange}
          enableMouseEvents
          interval={20000}
          animateTransitions={activeSlide !== 0}
        >
          {fields.person_item_ref.map(personItem => (
            <Grid container key={personItem.fields.name} alignItems="center" justify="space-between">
              <Grid item xs={12} md={7} className={classes.carouselInformation}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h3" className={classes.carouselInformationMainTitle}>
                      {personItem.fields.name}
                    </Typography>
                    <Typography variant="h4" component="h4" className={classes.carouselInformationSecondaryTitle}>
                      {personItem.fields.title}
                    </Typography>
                    <Typography paragraph className={`${classes.carouselInformationBio} ${spotlight ? classes.spotlightText : ''}`}>
                      {personItem.fields.shortBio}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <MobileStepper
                      steps={SliderLength}
                      position="static"
                      variant="dots"
                      activeStep={activeSlide}
                      classes={{
                        root: classes.dotsContainer,
                        dots: classes.dots,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} className={classes.carouselIllustrations}>
                {
                personItem.fields.image
                  && (
                    <ResponsiveImage
                      className={`${classes.imageCarousel} ${spotlight ? classes.spotlightCarousel : classes.managementTeamCarousel}`}
                      imageUrl={personItem.fields.image.fields.file.url}
                      imageTitle={personItem.fields.title}
                      sm={515}
                      md={395}
                      lg={395}
                      xl={395}
                    />
                  )
              }
              </Grid>
            </Grid>
          ))}
        </AutoPlaySwipeableViews>
      </Grid>
      <Grid item xs={2}>
        <Grid container justify="center">
          <Grid item xs={5} className={`${classes.arrowBlock} ${classes.nextSlideArrow}`}>
            <ArrowIcon
              disabled={activeSlide === SliderLength - 1}
              onClickHandler={handleNextSlide}
              arrowClass={classes.iconSize}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ManagementTeam.propTypes = {
  fields: PropTypes.shape({
    person_item_ref: PropTypes.array,
    title: PropTypes.string,
  }).isRequired,
  spotlight: PropTypes.bool.isRequired,
};

export default ManagementTeam;
