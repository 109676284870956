import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../RichTextOptions';
import useStyles from './styles';

function Employee({
  name, title, image, department, years, hobby, accomplishment,
}) {
  const classes = useStyles();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  return (
    <Grid container justify="space-between" className={classes.Employee}>
      <Grid item xs={12} md={4} className={classes.ImageContainer}>
        <img src={image} alt={name} className={classes.EmployeeImage} />
        <Typography variant="h3" component="h2" className={classes.EmployeeName}>
          {name}
        </Typography>
        <Typography variant="h4" component="h4" className={classes.SecondaryTitle}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} className={classes.TextContainer}>
        <Typography variant="h3" component="h2" className={classes.MainTitle}>
          Career Stats
        </Typography>
        <Grid container className={classes.BoxContainer}>
          <Grid item xs={4} className={classes.Box}>
            <Typography variant="h4" component="h4" className={classes.SecondaryTitle}>
                Department
            </Typography>
            <Typography variant="h5" component="h4" className={classes.SecondaryInfo}>
              {department}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.Box}>
            <Typography variant="h4" component="h4" className={classes.SecondaryTitle}>
                Years at Schonfeld
            </Typography>
            <Typography variant="h5" component="h4" className={classes.SecondaryInfo}>
              {years}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.Box}>
            <Typography variant="h4" component="h4" className={classes.SecondaryTitle}>
                Favorite Hobby
            </Typography>
            <Typography variant="h5" component="h4" className={classes.SecondaryInfo}>
              {hobby}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h3" component="h2" className={classes.MainTitle}>
          Proudest Accomplishment
        </Typography>
        <section>
          {documentToReactComponents(accomplishment, options)}
        </section>
      </Grid>
    </Grid>
  );
}

Employee.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  department: PropTypes.string.isRequired,
  years: PropTypes.string.isRequired,
  hobby: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  accomplishment: PropTypes.object.isRequired,
};

export default Employee;
