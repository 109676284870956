import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Grid,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';
import LinkButton from '../common/LinkButton';
import useStyles from './styles';
import Timeline from './Timeline';

const History = ({ fields, inHomepage }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const cardsLength = fields.history_item_ref ? fields.history_item_ref.length : 0;
  const classes = useStyles();

  const handleClickBack = () => {
    let step = 1;
    if (isXl && activeSlide >= 4) {
      step = 4;
    } else if (isLg && activeSlide >= 3) {
      step = 3;
    } else if (isMd && activeSlide >= 2) {
      step = 2;
    }
    setActiveSlide(activeSlide - step);
  };

  const handleClickForward = () => {
    let step = 1;
    if (isXl && activeSlide < cardsLength - 4) {
      step = 4;
    } else if (isLg && activeSlide < cardsLength - 3) {
      step = 3;
    } else if (isMd && activeSlide < cardsLength - 2) {
      step = 2;
    }
    setActiveSlide(activeSlide + step);
  };

  return (
    <Grid container direction="row-reverse" className={classes.historyComponent}>
      <Grid item xs={10} md={10}>
        <Grid container className={classes.titleRow}>
          <Typography variant="h2" component="h2">
            { fields.title }
          </Typography>
          <Box alignSelf="flex-end" className={classes.buttonBox}>
            <ArrowIcon
              disabled={activeSlide === 0}
              onClickHandler={handleClickBack}
              reverseArrow
              buttonClass={classes.backIconButton}
              arrowClass={classes.backIcon}
            />
            <ArrowIcon
              disabled={activeSlide === cardsLength - 1}
              onClickHandler={handleClickForward}
              buttonClass={classes.forwardIconButton}
              arrowClass={classes.forwardIcon}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.timelineContainer}>
          <Timeline
            items={fields.history_item_ref}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
          />
        </Grid>
        <Grid item>
          <Hidden xsUp={!inHomepage}>
            <LinkButton variant="contained" color="primary" to={fields.button_slug}>
              {fields.button_text}
            </LinkButton>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};

History.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    history_item_ref: PropTypes.array,
  }).isRequired,
  inHomepage: PropTypes.bool,
};

History.defaultProps = {
  inHomepage: false,
};

export default History;
