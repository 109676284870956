import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  EventPage: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxWidth: '100%',
    maxHeight: 380,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 760,
    },
    backgroundColor: '#92d5d6',
    '& div:nth-of-type(2n)': {
      backgroundColor: '#afc8ff',
    },
    '& div:nth-of-type(3n)': {
      backgroundColor: '#8db1ff',
    },
    '& div:nth-of-type(4n)': {
      backgroundColor: '#cccccc',
    },
    '& div:nth-of-type(5n)': {
      backgroundColor: '#bfd3ff',
    },
    '& div:nth-of-type(6n)': {
      backgroundColor: '#c8e9ea',
    },
    '& div:hover': {
      textDecoration: 'underline',
    },
  },
}));
