import { makeStyles } from '@material-ui/styles';
import isIE from '../../utils/isIE';

export default makeStyles(theme => ({
  Culture: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& > div': {
      display: 'table-row',
    },
  },
  loadingProgress: {
    display: 'block',
    position: 'fixed',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    zIndex: 1301,
  },
  Section: {
    width: '100vw',
    paddingTop: 100,
    [theme.breakpoints.up('md')]: {
      paddingTop: 155,
    },
    '@media only screen and (min-width: 960px) and (orientation: landscape)': {
      display: 'flex',
      alignItems: 'center',
    },
    '@media only screen and (min-width: 960px) and (min-height: 680px) and (orientation: landscape)': {
      height: !isIE() ? '100vh' : 'auto',
    },
  },
  DEI: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 100,
    },
  },
}));
