import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  Banner: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  ImageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      paddingRight: '3%',
      '&.portrait': {
        paddingRight: '6%',
      },
    },
  },
  BannerImage: {
    flex: 1,
    width: '100%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  BannerLottie: {
    width: '100%',
    margin: 'auto',
  },
  BannerContent: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: '10%',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginLeft: '3%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 40,
    },
  },
  Title: {
    marginTop: 0,
    marginBottom: 20,
  },
  Subtitle: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    '& span': {
      display: 'block',
    },
  },
  Detail: {
    maxWidth: '95%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  MainButton: {},
  ScrollIcon: {
    marginBottom: '4%',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: 14,
    },
  },
}));
