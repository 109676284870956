import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  careersListTitle: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginTop: 0,
      marginBottom: 37,
      fontSize: rem(36),
    },
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 692,
  },
  progress: {
    flexGrow: 0,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  jobTitle: {
    fontSize: rem(14),
    marginTop: 22,
    [theme.breakpoints.up('sm')]: {
      fontSize: rem(22),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(34),
    },

  },
  jobGroup: {
    '& > .department:nth-child(2)': {
      '& > h2': {
        [theme.breakpoints.up('sm')]: {
          fontSize: rem(20),
        },
        [theme.breakpoints.up('md')]: {
          fontSize: rem(28),
        },
      },
      '& > .firstDepartment:nth-child(2)': {
        '& h2': {
          [theme.breakpoints.up('sm')]: {
            fontSize: rem(18),
          },
          [theme.breakpoints.up('md')]: {
            fontSize: rem(22),
          },
        },
      },
    },
  },
  noResults: {
    marginBottom: 40,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 692,
  },
  paginationElement: {
    color: theme.palette.primary.black,
    fontSize: rem(16),
    textDecoration: 'none',
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
  paginationPrevious: {
    flexGrow: 0,
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '&.disabled': {
      opacity: 0.6,
      cursor: 'unset',
    },
  },
  navPages: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    lineHeight: 1,
  },
  paginationNext: {
    flexGrow: 0,
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '&.disabled': {
      opacity: 0.6,
      cursor: 'unset',
    },
  },
}));
