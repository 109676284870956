import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../common/RichTextOptions';
import ResponsiveImage from '../common/ResponsiveImage';
import useStyles from './styles';

export default function Diversity({ fields }) {
  const classes = useStyles();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  return (
    <Grid
      className={classes.DEI}
      container
      justify="center"
      alignItems="center"
    >
      <Grid item sm={8} xs={10}>
        <Grid
          item
          xl={5}
          md={4}
          xs={12}
        >
          <ResponsiveImage
            className={classes.staticImage}
            imageUrl={fields.image.fields.file.url}
            imageTitle={fields.title}
            sm={500}
            md={440}
            lg={440}
            xl={440}
          />

        </Grid>
        <Grid container>
          <Typography variant="h2" component="h2">
            {fields.title}
          </Typography>
        </Grid>
        <Grid container>

          <Grid
            className={classes.textContent}
            item
          >
            <section>
              {documentToReactComponents(fields.content, options)}
            </section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Diversity.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.object,
    content: PropTypes.object,
  }).isRequired,
};
