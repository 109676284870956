import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  linkButton: {
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
}));
