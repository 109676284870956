import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

export default function DropdownMenu({
  isLg, fields, shadow, AdapterLink,
}) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleDropdownOpen = () => {
    setExpanded(true);
  };

  const handleDropdownClose = () => {
    setExpanded(false);
  };

  return (
    <div
      onMouseEnter={handleDropdownOpen}
      onMouseLeave={handleDropdownClose}
    >
      <ListItem
        key={fields.title}
        className={`${classes.drawerLink} ${classes.mainLink} ${shadow === 1 ? 'scrolling' : ''}`}
        component={AdapterLink}
        to={`/${fields.slug}`}
        alignItems="center"
      >
        <Typography variant="body2" component="span">
          {fields.title}
        </Typography>
      </ListItem>
      {/* dropdown effect should only appear in big screens,
      in mobile all links are on the same level */}
      <div className={classes.dropdownContainer}>
        {((expanded && isLg) || !isLg) && fields.navigation_item_ref.map((i) => {
          if (isLg || (!isLg && fields.slug !== i.fields.slug)) {
            return (
              <ListItem
                key={i.fields.title}
                className={`${classes.drawerLink} ${classes.dropdownLink} ${shadow === 1 ? 'scrolling' : ''}`}
                component={AdapterLink}
                to={fields.slug !== i.fields.slug ? `/${fields.slug}/${i.fields.slug}` : `/${i.fields.slug}`}
              >
                <Typography variant="body2" component="span">
                  {i.fields.title}
                </Typography>
              </ListItem>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

DropdownMenu.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    navigation_item_ref: PropTypes.array,
  }).isRequired,
  isLg: PropTypes.bool.isRequired,
  shadow: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  AdapterLink: PropTypes.object.isRequired,
};
