import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  Contact: {},
  ContactTitle: {
    marginBottom: 20,
  },
  ContentText: {
    fontSize: rem(16),
    marginBottom: 30,
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  loadingProgress: {
    display: 'block',
    position: 'fixed',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    zIndex: 1301,
  },
  Section: {
    width: '100vw',
    paddingTop: 100,
    [theme.breakpoints.up('md')]: {
      paddingTop: 160,
    },
  },
  Heading5: {
    fontSize: rem(20),
  },
  ContactCard: {
    border: `1px solid ${theme.palette.lines.border}`,
    borderRadius: 18,
    padding: 36,
    paddingBottom: 6,
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
      paddingRight: 120,
    },
  },
  Form: {
    marginTop: 35,
    '& label.Mui-focused': {
      color: theme.palette.text.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.main,
      },
    },
    '& div': {
      '& fieldset': {
        borderRadius: 25,
      },
    },
  },
}));
