import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Hidden, useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import useOnScreen from '../../utils/useOnScreen';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';
import useStyles from './styles';
import LinkButton from '../common/LinkButton';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Values = ({ fields, showAll }) => {
  const theme = useTheme();
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [ref, isVisible] = useOnScreen();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const maxSlides = fields.value_item_ref.length;
  const classes = useStyles();

  const handleSlideChange = (slide) => {
    setActiveSlide(() => {
      if (!isMobile && slide === maxSlides - 2) {
        return 0;
      }
      return slide;
    });
  };

  const handleNextSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide + 1);
  };

  const handleBackSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide - 1);
  };

  const renderSwipeableView = () => (
    <React.Fragment>
      <Grid item xs={1} className={classes.arrowLeft}>
        <ArrowIcon
          disabled={activeSlide === 0}
          onClickHandler={handleBackSlide}
          reverseArrow
          arrowClass={classes.Arrow}
        />
      </Grid>
      <Grid item xs={10} md={6} xl={7} className={classes.ValuesSlider} ref={ref}>
        <AutoPlaySwipeableViews
          autoplay={isVisible}
          axis="x"
          index={activeSlide}
          className={classes.Slider}
          onChangeIndex={handleSlideChange}
          slideClassName={classes.SlideContainer}
          enableMouseEvents
          interval={5000}
          animateTransitions={activeSlide !== 0}
        >
          {fields.value_item_ref.map(item => (
            <div key={item.sys.id} className={classes.Slide}>
              {
                item.fields.image
                  ? (<img src={item.fields.image.fields.file.url} alt={fields.title} />)
                  : ''
              }
              <Typography variant="h5" component="h5" className={classes.SlideTitle}>
                {item.fields.title}
              </Typography>
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </Grid>
      <Grid item xs={1} className={classes.arrowRight}>
        <Hidden smDown>
          <ArrowIcon
            disabled={activeSlide >= maxSlides - 3}
            onClickHandler={handleNextSlide}
            arrowClass={classes.Arrow}
          />
        </Hidden>
        <Hidden mdUp>
          <ArrowIcon
            disabled={activeSlide >= maxSlides - 1}
            onClickHandler={handleNextSlide}
            arrowClass={classes.Arrow}
          />
        </Hidden>
      </Grid>
    </React.Fragment>
  );

  const renderGridView = () => (
    <React.Fragment>
      <Hidden smDown>
        <Grid item xs={12} className={classes.ValuesGrid}>
          <Grid container justify="center">
            {fields.value_item_ref.map(item => (
              <Grid item lg={2} xl={3} key={item.sys.id} className={classes.Slide}>
                {
                  item.fields.image
                    ? (
                      <img
                        src={item.fields.image.fields.file.url}
                        alt={fields.title}
                      />
                    )
                    : ''
                }
                <Typography variant="h5" component="h5" className={`${classes.SlideTitle} grid`}>
                  {item.fields.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        {renderSwipeableView()}
      </Hidden>
    </React.Fragment>
  );

  return (
    <Grid container justify="flex-end" className={classes.valuesComponent}>
      <Grid item xs={11} lg={10} className={`${classes.Values} ${showAll ? 'showAll' : ''}`}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} xl={3} className={classes.ValuesCta}>
            <section>
              <Typography variant="h2" component="h2">
                {fields.title}
              </Typography>
              {!showAll && (
              <Typography paragraph className={classes.ValuesDetail}>
                {fields.content.content.map(item => item.content.map(contentText => (
                  contentText.value
                )))}
              </Typography>
              )}
              {!showAll && (
              <LinkButton variant="outlined" color="primary" className={classes.MainButton} to={fields.button_slug}>
                {fields.button_text}
              </LinkButton>
              )}
            </section>
          </Grid>
          {!showAll
            ? renderSwipeableView()
            : renderGridView()}
        </Grid>
      </Grid>
    </Grid>
  );
};

Values.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    content: PropTypes.object,
    value_item_ref: PropTypes.array,
  }).isRequired,
  showAll: PropTypes.bool,
};

Values.defaultProps = { showAll: false };

export default Values;
