import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 20,
    },
  },
  pictureContainer: {
    border: `1px solid ${theme.palette.lines.border}`,
    borderBottom: 0,
    borderRadius: '18px 18px 0 0',
    position: 'relative',
    overflow: 'hidden',
    maxHeight: 275,
  },
  cardImage: {
    height: 250,
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  cardContent: {
    border: `1px solid ${theme.palette.lines.border}`,
    borderRadius: '0 0 18px 18px',
    borderTopColor: 'transparent',
    flexGrow: 2,
    padding: 15,
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
  },
  cardDate: {
    paddingBottom: 10,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 20,
    },
  },
  cardTitle: {
    paddingBottom: 15,
    '& > a': {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(24),
      paddingBottom: 25,
    },
  },
  hashtagList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  hashtag: {
    color: theme.palette.primary.black,
    display: 'block',
    letterSpacing: 2.88,
    paddingRight: 10,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: rem(12),
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  MoreButton: {
    [theme.breakpoints.up('md')]: {
      marginTop: 15,
      padding: '10px 25px',
      borderRadius: 30,
    },
  },
}));
