import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ResponsiveImage from '../common/ResponsiveImage';
import { headingOptions } from '../common/RichTextOptions';
import UncontrolledLottie from '../common/Lottie/UncontrolledLottie';
import useStyles from './styles';
import isIE from '../../utils/isIE';

const StaticTextImage = ({
  fields, leftImage, rightImage, lotties, bgColor,
}) => {
  const classes = useStyles();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph className={classes.staticTextImageParagraph}>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  const renderStaticImage = () => {
    if (!isIE() && lotties.length > 0) {
      return (
        <React.Fragment>
          <UncontrolledLottie
            className={classes.staticImageLottie}
            lotties={lotties}
          />
        </React.Fragment>
      );
    }
    return fields.image
      ? (
        <ResponsiveImage
          className={classes.staticImage}
          imageUrl={fields.image.fields.file.url}
          imageTitle={fields.title}
          sm={500}
          md={440}
          lg={440}
          xl={440}
        />
      ) : null;
  };

  return (
    <Grid container direction={rightImage ? 'row' : 'row-reverse'} className={classes.staticTextImageComponent}>
      <Grid item xs={11} sm={10}>
        <Grid container alignItems="center" className={`${classes.staticTextImageContent} ${leftImage ? classes.leftImage : classes.rightImage}`} style={{ backgroundColor: bgColor }}>
          <Grid item xs={8} md={5} lg={4} className={`${classes.imageBlock} ${leftImage ? classes.leftImageOrder : classes.rightImageOrder}`}>
            {renderStaticImage()}
          </Grid>
          <Grid
            item
            xs={10}
            md={5}
            lg={4}
            className={rightImage ? classes.rightinformationOrder : null}
          >
            { fields.secondary_title ? (
              <Typography variant="h4" component="h3" className={classes.secondaryTitle}>
                {fields.secondary_title}
              </Typography>
            ) : null}
            <Typography variant="h2" component="h2" className={classes.staticTextImageTitle}>
              {fields.title}
            </Typography>
            <section>
              {documentToReactComponents(fields.content, options)}
            </section>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

StaticTextImage.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.object,
    content: PropTypes.object,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    secondary_title: PropTypes.string,
  }).isRequired,
  bgColor: PropTypes.string,
  leftImage: PropTypes.bool,
  rightImage: PropTypes.bool,
  lotties: PropTypes.arrayOf(PropTypes.shape),
};

StaticTextImage.defaultProps = {
  bgColor: '#f6f6f6',
  leftImage: false,
  rightImage: false,
  lotties: [],
};

export default StaticTextImage;
