import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Careers from '../c_010a-careers/Careers';
import News from '../c_010b-news-culture/News';
import useStyles from './styles';


const CareersNews = ({ careersFields }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.CareersNewsComponent}>
      <Grid container justify="space-between">
        <Grid item xs={11} sm={10} md={6}>
          <Careers fields={careersFields} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container justify="center">
            <Grid item xs={9} md={9}>
              <News />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CareersNews.propTypes = {
  careersFields: PropTypes.shape({
    title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
  }).isRequired,
};

export default CareersNews;
