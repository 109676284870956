import Matcher from './Matcher';
import Pager from './Pager';
import nodeReducer from './nodeReducer';

export const ITEM_LIMIT = 11;

export default function parseJobsTree({
  departments, department, address, search, page, locations,
}) {
  const jobsTree = [];
  const departmentNames = new Set();
  const officeNames = new Set(locations);
  const matcher = new Matcher(department, address, search);
  const pager = new Pager(page, ITEM_LIMIT);

  departments.reduce(nodeReducer, {
    jobsTree,
    departmentNames,
    officeNames,
    matcher,
  });

  return {
    jobsTree: pager.getPage(jobsTree),
    departmentNames: ['All Departments', ...[...departmentNames].sort()],
    officeNames: ['All Offices', ...officeNames],
    totalItems: matcher.count,
    pageCount: pager.count,
  };
}
