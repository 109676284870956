import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  CareersFilter: {
    backgroundColor: theme.palette.background.card,
    borderRadius: '50px 0 0 50px',
    padding: '0 20% 10% 10%',
    marginBottom: 50,
    [theme.breakpoints.up('md')]: {
      height: '99%',
      padding: '0 25% 10% 10%',
      marginBottom: 0,
    },
  },
  CareerFilterTitle: {
    fontSize: rem(14),
    marginTop: 40,
    marginBottom: 30,
    [theme.breakpoints.up('sm')]: {
      fontSize: rem(22),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 45,
      marginBottom: 30,
      fontSize: rem(28),
    },
  },
  FilterDropdown: {
    marginTop: 10,
  },
  FilterTitle: {
    margin: '20px 0',
  },
}));
