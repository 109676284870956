import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  StrategyContainer: {
    alignItems: 'center',
    height: '100%',
  },
  Strategy: {
    justifyContent: 'center',
  },
  Index: {
    order: '2',
    [theme.breakpoints.up('md')]: {
      order: '1',
      '&.inverted': {
        order: '2',
      },
    },
  },
  Description: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 450,
      justifyContent: 'flex-start',
      '&.inverted': {
        maxWidth: '100%',
        justifyContent: 'flex-end',
      },
    },
  },
  Content: {
    alignSelf: 'center',
    order: '3',
    [theme.breakpoints.up('md')]: {
      order: '2',
      '&.inverted': {
        order: '3',
        marginLeft: 50,
      },
    },
  },
  Image: {
    order: '1',
    [theme.breakpoints.up('md')]: {
      marginLeft: 30,
      order: '3',
      '&.inverted': {
        marginLeft: 0,
        marginRight: 20,
        order: '1',
      },
    },
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
  },
  SecondaryTitle: {
    marginBottom: 21,
    [theme.breakpoints.up('md')]: {
      marginLeft: 8,
    },
  },
  Title: {
    marginBottom: 21,
  },
  InvestmentImage: {
    borderRadius: 18,
    width: '100%',
    height: 'auto',
  },
  MakeSmaller: {
    transform: 'scale(0.88)',
    [theme.breakpoints.only('sm')]: {
      transform: 'scale(0.65)',
      marginBottom: -100,
    },
  },
}));
