import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  bannerCareersComponent: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingTop: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
    },
  },
  bannerCareersContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 40,
    },
  },
  bannerCareersTitle: {
    marginLeft: 32,
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  bannerCareersSecondaryTitle: {
    fontSize: rem(14),
    marginTop: 42,
    [theme.breakpoints.up('sm')]: {
      fontSize: rem(20),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 80,
      fontSize: rem(30),
    },
  },
  bannerCareersQuote: {
    display: 'flex',
    backgroundColor: theme.palette.background.lightBlue,
    borderRadius: 18,
    paddingLeft: 32,
    paddingRight: 61,
    paddingTop: 30,
    paddingBottom: 34.5,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingLeft: 41,
      paddingRight: 23,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 100,
      paddingRight: 90,
    },
  },
  quoteIcon: {
    width: 40.5,
    height: 32,
    marginBottom: 27,
    [theme.breakpoints.up('md')]: {
      width: 80,
      height: 64,
      marginBottom: 0,
    },
  },
  bannerCareersDescription: {
    color: theme.palette.primary.main,
    lineHeight: 1.48,
    marginBottom: 0,
    fontSize: rem(12),
    [theme.breakpoints.up('sm')]: {
      fontSize: rem(17),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(24),
      marginLeft: 41,
    },
  },
  careersListTitle: {
    marginTop: 30,
    fontSize: rem(20),
    [theme.breakpoints.up('sm')]: {
      fontSize: rem(26),
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
