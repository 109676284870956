import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, DialogContent, Button,
} from '@material-ui/core';
import { Place, Schedule, Close } from '@material-ui/icons';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../RichTextOptions';
import useStyles from './styles';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Typography paragraph>
        {children}
      </Typography>
    ),
    ...headingOptions,
  },
};

function EventModal({
  formattedDate, name, description, location, time, url, handleClose,
}) {
  const classes = useStyles();
  return (
    <DialogContent>
      <Grid item className={classes.EventDetails}>
        <Grid container className={classes.CloseContainer}>
          <Close onClick={handleClose} className={classes.CloseIcon} />
        </Grid>
        <Typography className={classes.ModalDate}>
          {formattedDate}
        </Typography>
        <Typography variant="h4" component="h4" className={classes.ModalTitle}>
          {name}
        </Typography>
        {location
          ? (
            <Typography variant="h4" component="h4" className={classes.ModalTitle}>
              <Grid container className={classes.IconContainer}>
                <Place className={classes.Icon} />
                {location}
              </Grid>
            </Typography>
          ) : null
            }
        <Typography variant="h4" component="h4" className={classes.ModalTitle}>
          <Grid container className={classes.IconContainer}>
            <Schedule className={classes.Icon} />
            {time}
          </Grid>
        </Typography>
        {description
          ? (
            <section className={classes.Description}>
              {documentToReactComponents(description, options)}
            </section>
          ) : null
            }
        <a target="_blank" rel="noopener noreferrer" href={url} className={classes.EventLink}>
          <Button
            variant="contained"
            color="primary"
          >
          Event Details
          </Button>
        </a>
      </Grid>
    </DialogContent>
  );
}

EventModal.propTypes = {
  name: PropTypes.string.isRequired,
  formattedDate: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.object,
  location: PropTypes.string,
};

EventModal.defaultProps = {
  description: undefined,
  location: '',
};

export default EventModal;
