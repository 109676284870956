import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  managementTeam: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  managementTeamTitle: {
    marginBottom: 40,
  },
  arrowBlock: {
    zIndex: 1299,
    position: 'absolute',
    marginTop: 110,
    [theme.breakpoints.up('sm')]: {
      top: '22%',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      marginTop: 0,
    },
  },
  iconSize: {
    width: 30,
    height: 56,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 50,
    },
  },
  carouselInformation: {
    position: 'relative',
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  carouselInformationMainTitle: {
    fontSize: rem(28),
    paddingBottom: 4,
  },
  carouselInformationSecondaryTitle: {
    fontSize: rem(18),
  },
  carouselInformationBio: {
    paddingTop: 27,
    paddingBottom: 36,
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      paddingTop: 21,
      paddingBottom: 20,
      marginRight: 55,
    },
    '@media only screen and (min-width: 960px) and (orientation: landscape)': {
      marginRight: 121,
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 10,
    },
  },
  spotlightText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    fontSize: 18,
  },
  carouselIllustrations: {
    marginBottom: 40,
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
      marginBottom: 0,
    },
  },
  imageCarousel: {
    borderRadius: '50%',
    display: 'block',
    overflow: 'hidden',
    width: '90%',
    margin: 'auto',
  },
  managementTeamCarousel: {
    maxWidth: 395,
    maxHeight: 395,
  },
  spotlightCarousel: {
    maxWidth: 300,
    maxHeight: 300,
    [theme.breakpoints.only('xs')]: {
      maxWidth: 275,
      maxHeight: 275,
    },
  },
  nextSlideArrow: {
    [theme.breakpoints.down('sm')]: {
      right: '3vw',
    },
  },
  dotsContainer: {
    justifyContent: 'flex-start',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      background: 'transparent',
    },
  },
  dots: {
    '& > div': {
      marginLeft: 0,
      marginRight: 20,
      width: 4,
      height: 4,
    },
  },
}));
