import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  numberBox: {
    textAlign: 'center',
    color: '#fff',
    margin: 20,
    borderRadius: 15,
    padding: '20px 15px',
    '&:nth-child(2)': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:nth-child(3)': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:last-child': {
      backgroundColor: theme.palette.background.specialBlue,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 10,
    },
  },
  number: {
    fontSize: rem(40),
    [theme.breakpoints.down('md')]: {
      fontSize: rem(32),
    },
  },
}));
