import React from 'react';
import PropTypes from 'prop-types';
import { FullPage } from 'react-full-page';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import isIE from '../../utils/isIE';
import useStyles from './styles';

const FullPageScroll = ({ children }) => {
  const theme = useTheme();
  const isActive = useMediaQuery(theme.breakpoints.down(1440)) || isIE();
  const classes = useStyles();

  return (
    <FullPage
      duration={1000}
      controls={(!isActive)}
      scrollMode={isActive ? 'normal' : 'full-page'}
      controlsProps={{ className: classes.controls }}
    >
      { children }
    </FullPage>
  );
};

FullPageScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FullPageScroll;
