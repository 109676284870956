import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  imageGalleryComponent: {
    alignItems: 'center',
  },
  Arrow: {
    width: 26,
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 13,
      height: 26,
    },
  },
  arrowLeft: {
    order: 2,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  CardsSlider: {
    order: 2,
    paddingTop: 40,
    textAlign: 'center',
  },
  arrowRight: {
    order: 4,
    display: 'flex',
    justifyContent: 'center',
  },
  Slider: {
  },
  readMore: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
  },
  galleryItem: {
    borderRadius: 25,
    maxWidth: '100%',
    maxHeight: 400,
    objectFit: 'cover',
  },
  centerLine: {
    textAlign: 'center',
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
