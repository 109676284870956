import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import useStyles from './styles';

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const LinkButton = (props) => {
  const classes = useStyles();
  const { children, className, href } = props;

  return (
    href
      ? (
        <Button component="a" {...props} className={`${classes.linkButton} ${className}`}>
          {children}
        </Button>
      )
      : (
        <Button component={AdapterLink} {...props} className={`${classes.linkButton} ${className}`}>
          {children}
        </Button>
      )
  );
};

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
};

LinkButton.defaultProps = {
  className: '',
  href: '',
};

export default LinkButton;
