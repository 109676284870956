import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveImage = (props) => {
  const {
    imageUrl, imageTitle, className, sm, md, lg, xl,
  } = props;
  return (
    <img
      className={className}
      src={imageUrl}
      srcSet={`${imageUrl}?w=${sm} 600w, 
      ${imageUrl}?w=${md} 960w, 
      ${imageUrl}?w=${lg} 1280w,
      ${imageUrl}?w=${xl} 1920w`}
      alt={imageTitle}
    />
  );
};

ResponsiveImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  className: PropTypes.string,
};

ResponsiveImage.defaultProps = {
  className: '',
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export default ResponsiveImage;
