import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import careersNavLink from '../../../utils/url';

function Department({ name }) {
  const classes = useStyles();

  const pageNavLink = value => careersNavLink({
    page: '1',
    address: 'All Offices',
    department: value,
  });

  return (
    <Box className={classes.Department}>
      <Link className={classes.LocationLink} to={pageNavLink(name)}>
        <Typography variant="h3" component="span">
          {name}
        </Typography>
      </Link>
    </Box>
  );
}

Department.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Department;
