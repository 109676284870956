import { useRef, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function useBackgroundColor(bgColor) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('400'));
  const rootMarginSize = isXs ? '-100px' : '-200px';
  const tresholdSize = 0;
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target === ref.current) {
          document.body.style.transition = 'background-color 0.75s ease';
          document.body.style.backgroundColor = bgColor;
        }
      });
    }, { root: null, rootMargin: rootMarginSize, treshold: tresholdSize });

    observer.observe(ref.current);

    return () => {
      document.body.style.backgroundColor = '#fff';
      observer.disconnect();
    };
  }, [bgColor, rootMarginSize]);

  return ref;
}
