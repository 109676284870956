function compareByTitle(job1, job2) {
  const nameA = job1.title.toUpperCase();
  const nameB = job2.title.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export default function nodeReducer({
  jobsTree, departmentNames, officeNames, matcher, flat,
}, item) {
  const name = item.name.trim();
  if ((item.jobs.length > 0 || name === 'Technology') && !flat) departmentNames.add(name);

  matcher.pushDepartment(name);
  const jobs = item.jobs.reduce((acc, job) => {
    officeNames.add(job.location.name.trim());
    if (matcher.match(job)) {
      acc.push(job);
    }

    return acc;
  }, []);

  const node = {
    name,
    jobs,
  };

  const flatNode = flat || name === 'Technology';
  const recurse = item.children.reduce(nodeReducer, {
    jobsTree: [],
    departmentNames,
    officeNames,
    matcher,
    flat: flatNode,
  });

  matcher.popDepartment();

  if (flatNode) node.jobs = recurse.jobsTree.reduce((acc, n) => [...acc, ...n.jobs], node.jobs);
  else if (recurse.jobsTree.length > 0) node.children = recurse.jobsTree;

  if (jobs.length > 0 || recurse.jobsTree.length > 0) jobsTree.push(node);

  if (name === 'Technology') {
    node.jobs.sort(compareByTitle);
  }

  return {
    jobsTree,
    departmentNames,
    officeNames,
    matcher,
    flat,
  };
}
