import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  lottieContainer: {
    position: 'relative',
    '& div': {
      position: 'absolute',
      top: 0,
      left: 0,
      overflow: 'visible !important',
    },
    '& div:first-child': {
      position: 'relative',
    },
  },
}));
