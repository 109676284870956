import React, { useEffect, useState } from 'react';
import { Slide } from 'react-full-page';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getEntries } from '../../utils/contentful';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import ImageBanner from '../../components/c_033-image-banner/ImageBanner';
import CareerStages from '../../components/c_042_career-stages/CareerStages';
import Carousel from '../../components/c_041_carousel/Carousel';
import Events from '../../components/c_044_events/Events';
import Project from '../../components/common/Project/Project';
import SingleMedia from '../../components/c_037_single-media/SingleMedia';
import Internships from '../../components/c_045_internships/Internships';
import Footer from '../../components/c_003-Footer/Footer';
import useStyles from './styles';

export default function Campus() {
  const [content, setContent] = useState({});
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const state = await getEntries({
          content_type: 'contentPage',
          'fields.slug': '/careers/campus',
          include: 2,
        });
        if (mounted && state) {
          setContent(state.items ? state.items[0] : {});
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', e);
      }
    };
    getData();
    return () => { mounted = false; };
  }, []);

  return (
    <div className={classes.Campus}>
      {content.fields ? (
        <FullPageScroll>
          {content.fields.block_ref.map((item) => {
            let component;
            if (item.fields.layout === 'Banner') {
              component = <ImageBanner fields={item.fields} bgColor="#84b3ff" />;
            } else if (item.fields.component === 'Events') {
              component = (
                <Events fields={item.fields} />
              );
            } else if (item.sys.id === '1KV86oxPBq5NgrccFnruc7') {
              component = <CareerStages fields={item.fields} />;
            } else if (item.fields.component === 'Projects') {
              component = (
                <Carousel
                  title={item.fields.title}
                  sliderClassName={classes.ProjectSlider}
                  bgColor="#f6f6f6"
                  childComponent="Projects"
                >
                  {item.fields.cta_item_ref.map(intern => (
                    <Project
                      key={intern.fields.name}
                      name={intern.fields.name}
                      title={intern.fields.title}
                      description={intern.fields.shortBio}
                      image={intern.fields.image ? intern.fields.image.fields.file.url : ''}
                    />
                  ))}
                </Carousel>
              );
            } else if (item.fields.layout === 'Media') {
              component = <SingleMedia fields={item.fields} />;
            } else if (item.sys.id === '1fckgltQCYne3vf7SBDXsf') {
              component = <Internships fields={item.fields} />;
            } else {
              return null;
            }
            return (
              <Slide key={item.sys.id}>
                <section
                  className={classes.SlideContent}
                >
                  {component}
                </section>
              </Slide>
            );
          })}
          <Slide>
            <Footer />
          </Slide>
        </FullPageScroll>
      ) : (
        <CircularProgress className={classes.loadingProgress} />
      )}
    </div>
  );
}
