import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  DEI: {
    height: '100%',
    alignItems: 'center',
  },
  staticImage: {
    objectFit: 'cover',
    width: '100%',
  },
  textContent: {
    paddingTop: 40,
    '& div': {
      columns: 2,
    },
  },
}));
