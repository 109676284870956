import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  FeaturedArticles: {
  },
  article: {
    borderBottom: `1px solid ${theme.palette.primary.disabled}`,
    paddingBottom: 30,
    paddingTop: 30,
    '&:first-child': {
      paddingTop: 0,
    },
    '&:last-child': {
      borderBottom: 0,
    },
  },
  title: {
    fontWeight: 'normal',
    marginBottom: 25,
    [theme.breakpoints.up('md')]: {
      fontSize: rem(20),
    },
  },
  readMoreLink: {
    alignItems: 'center',
    color: theme.palette.secondary.main,
    display: 'flex',
    textDecoration: 'none',
    textTransform: 'uppercase',
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(16),
    },
  },
}));
