import React, { useEffect, useState } from 'react';
import { Slide } from 'react-full-page';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getEntries } from '../../utils/contentful';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import MainIllustration from '../../components/c_043_main-illustration/MainIllustration';
import CenterText from '../../components/c_011-center-text/CenterText';
import Strategy from '../../components/c_012-investment-strategy-block/Strategy';
import Location from '../../components/common/Location/Location';
import Carousel from '../../components/c_041_carousel/Carousel';
import Employee from '../../components/common/Employee/Employee';
import SingleMedia from '../../components/c_037_single-media/SingleMedia';
import Departments from '../../components/c_040_departments/Departments';
import Footer from '../../components/c_003-Footer/Footer';
import useStyles from './styles';

export default function Careers() {
  const [content, setContent] = useState({});
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    const getData = async () => {
      try {
        const state = await getEntries({
          content_type: 'contentPage',
          'fields.slug': '/careers',
          include: 2,
        });
        if (mounted && state) {
          setContent(state.items ? state.items[0] : {});
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', e);
      }
    };
    getData();
    return () => { mounted = false; };
  }, []);

  return (
    <div className={classes.Careers}>
      {!content || !content.fields ? (
        <CircularProgress className={classes.loadingProgress} />
      ) : (
        <FullPageScroll>
          {content.fields.block_ref.map((item, index) => {
            let component;
            if (item.fields.layout === 'Banner') {
              component = (
                <MainIllustration
                  title={item.fields.title}
                  imgSrc={item.fields.image.fields.file.url}
                  altText={item.fields.image.fields.file.fileName}
                  bgColor="#7dd8d8"
                />
              );
            } else if (item.sys.id === 'ftcAZXnJg04k6wBtQa2LN') {
              component = <CenterText fields={item.fields} centered hideTitle biggerText />;
            } else if (item.fields.component === 'Employees') {
              component = (
                <Carousel title={item.fields.title} bgColor="#e3f4f4">
                  {item.fields.cta_item_ref.map(employee => (
                    <Employee
                      key={employee.fields.name}
                      name={employee.fields.name}
                      title={employee.fields.title}
                      image={employee.fields.image.fields.file.url}
                      department={employee.fields.department}
                      years={employee.fields.years}
                      hobby={employee.fields.hobby}
                      accomplishment={employee.fields.accomplishment}
                    />
                  ))}
                </Carousel>
              );
            } else if (item.fields.layout === 'Full Width (Right Image)') {
              component = (
                <Strategy
                  fields={item.fields}
                  index={index + 1}
                />
              );
            } else if (item.fields.layout === 'Full Width (Left Image)') {
              component = (
                <Strategy
                  fields={item.fields}
                  index={index + 1}
                  inverted
                />
              );
            } else if (item.sys.id === '4xdgRmQUmkI7LthgPRpxJf') {
              component = <CenterText bgColor="#8db1ff" fields={item.fields} centered />;
            } else if (item.fields.office_item_ref) {
              component = (
                <Carousel
                  title={item.fields.title}
                  sliderClassName={classes.LocationsSlider}
                  childComponent="Locations"
                >
                  {item.fields.office_item_ref.map(location => (
                    <Location
                      key={location.fields.name}
                      title={location.fields.name}
                      image={location.fields.image}
                    />
                  ))}
                </Carousel>
              );
            } else if (item.fields.layout === 'Media') {
              component = <SingleMedia fields={item.fields} />;
            } else if (item.fields.accordion_item_ref) {
              component = <Departments fields={item.fields} />;
            } else {
              return null;
            }
            return (
              <Slide key={item.sys.id}>
                <section
                  className={`${classes.SlideContent} ${item.fields.layout === 'Banner' ? 'CareersPage' : ''}`}
                >
                  {component}
                </section>
              </Slide>
            );
          })}
          <Slide>
            <Footer />
          </Slide>
        </FullPageScroll>
      )}
    </div>
  );
}
