function reducer({ jobsTree, pager }, item) {
  const jobs = item.jobs.filter(() => pager.add());

  const node = {
    name: item.name,
    jobs,
  };

  const children = item.children || [];

  const recurse = children.reduce(reducer, {
    jobsTree: [],
    pager,
  });

  if (recurse.jobsTree.length > 0) node.children = recurse.jobsTree;
  if (jobs.length > 0 || recurse.jobsTree.length > 0) jobsTree.push(node);

  return {
    jobsTree, pager,
  };
}

export default class Pager {
  constructor(currentPage, pageSize) {
    this.currentPage = currentPage;
    this.pageSize = pageSize;
    this.count = 0;
    this.seen = 0;
    this.firstIndexInPage = currentPage * pageSize;
  }

  add() {
    this.seen += 1;
    if (this.seen >= this.firstIndexInPage && this.count < this.pageSize) {
      this.count += 1;
      return true;
    }

    return false;
  }

  getPage(jobsTree) {
    const pagedTree = jobsTree.reduce(reducer, { jobsTree: [], pager: this });
    return pagedTree.jobsTree;
  }
}
