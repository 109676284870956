import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Typography,
  Box,
} from '@material-ui/core';
import Ellipsis from '../Ellipsis';
import LinkButton from '../LinkButton';
import useStyles from './styles';

const Card = ({
  category,
  item,
  className,
  withButton,
}) => {
  const classes = useStyles();

  return (
    item.fields
      ? (
        <Box className={`${classes.cardContainer} ${className}`}>
          {
            item.fields.external_url
              ? (
                <a href={item.fields.external_url} target="_blank" rel="noopener noreferrer" className={classes.pictureContainer}>
                  <div className={classes.cardImage} style={{ backgroundImage: `url(${item.fields.heroImage.fields.file.url})` }} />
                </a>
              )
              : (
                <NavLink to={`/blog/${category}/${item.fields.slug}`} className={classes.pictureContainer}>
                  <div className={classes.cardImage} style={{ backgroundImage: `url(${item.fields.heroImage.fields.file.url})` }} />
                </NavLink>
              )
          }
          <Box className={classes.cardContent}>
            <Typography variant="body1" className={classes.cardDate}>
              {moment(item.fields.publishDate).format('MMMM DD, YYYY')}
            </Typography>
            <Typography variant="h3" component="h3" className={classes.cardTitle}>
              {
                item.fields.external_url
                  ? (
                    <a href={item.fields.external_url} target="_blank" rel="noopener noreferrer">
                      <Ellipsis
                        text={item.fields.title}
                        maxLine="2"
                        mobileLines="3"
                      />
                    </a>
                  )
                  : (
                    <NavLink to={`/blog/${category}/${item.fields.slug}`}>
                      <Ellipsis
                        text={item.fields.title}
                        maxLine="2"
                        mobileLines="3"
                      />
                    </NavLink>
                  )
              }
            </Typography>
            <Typography paragraph className={classes.hashtagList}>
              {item.fields.tag_ref && item.fields.tag_ref.map(tag => (
                <NavLink key={tag.fields.name} to={`/blog/${category}?tagId=${tag.sys.id}`} className={classes.hashtag}>
                  {`#${tag.fields.name}`}
                </NavLink>
              ))}
            </Typography>
            {
              // eslint-disable-next-line no-nested-ternary
              withButton
                ? item.fields.external_url
                  ? (
                    <LinkButton href={item.fields.external_url} target="_blank" rel="noopener noreferrer" variant="outlined" color="primary" className={classes.MoreButton}>
                      Read More
                    </LinkButton>
                  )
                  : (
                    <LinkButton to={`/blog/${category}/${item.fields.slug}`} variant="outlined" color="primary" className={classes.MoreButton}>
                      Read More
                    </LinkButton>
                  )
                : null
            }
          </Box>
        </Box>
      )
      : (
        <Box />
      )
  );
};

Card.propTypes = {
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  item: PropTypes.shape({
    fields: PropTypes.object,
  }).isRequired,
  withButton: PropTypes.bool,
};

Card.defaultProps = { className: '', withButton: false };

export default Card;
