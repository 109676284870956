import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  Employee: {
    overflow: 'hidden',
  },
  ImageContainer: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  EmployeeImage: {
    marginBottom: 20,
    width: '100%',
    borderRadius: 15,
  },
  TextContainer: {
    paddingLeft: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  BoxContainer: {
    marginBottom: 20,
    textAlign: 'center',
    backgroundColor: '#92d5d6',
    '& div:first-of-type': {
      backgroundColor: theme.palette.background.specialBlue,
    },
    '& div:last-of-type': {
      backgroundColor: '#ccc',
    },
  },
  Box: {
    height: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  EmployeeName: {
    fontSize: rem(24),
    [theme.breakpoints.down('md')]: {
      fontSize: rem(20),
    },
  },
  MainTitle: {
    marginBottom: 20,
    fontSize: rem(24),
    [theme.breakpoints.down('md')]: {
      fontSize: rem(20),
    },
  },
  SecondaryTitle: {
    fontSize: rem(16),
    [theme.breakpoints.down('md')]: {
      fontSize: rem(14),
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: rem(12),
    },
  },
  SecondaryInfo: {
    fontSize: rem(20),
    [theme.breakpoints.down('md')]: {
      fontSize: rem(18),
    },
    [theme.breakpoints.only('xs')]: {
      fontWeight: 600,
      fontSize: rem(14),
    },
    '@media only screen and (max-width: 300px)': {
      fontSize: rem(12),
    },
  },
}));
