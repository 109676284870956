import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  ScrollIcon: {
    marginBottom: '4%',
    '& img': {
      width: 14,
    },
  },
}));
