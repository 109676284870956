import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Slide } from 'react-full-page';
import { getEntries } from '../../utils/contentful';
import FullPageScroll from '../../components/c_002-full-page-scroll/FullPageScroll';
import LogoBanner from '../../components/c_034_logo-banner/LogoBanner';
import NewsCarousel from '../../components/c_015-news-carousel/NewsCarousel';
import StaticTextImage from '../../components/c_012-static-text-image-block/StaticTextImage';
import Founder from '../../components/c_027-founder/Founder';
import ManagementTeam from '../../components/c_013-management-team/ManagementTeam';
import History from '../../components/c_009-history-timeline/History';
import Footer from '../../components/c_003-Footer/Footer';

import PhilosophyAnimation from '../../assets/animations/Philosophy-Final.json';

import useStyles from './styles';

const lottiePhilosophy = [
  { name: 'PhilosophyGear', animation: PhilosophyAnimation, loop: true },
];

const Company = () => {
  const [content, setContent] = useState({});
  const queryParams = {
    content_type: 'contentPage',
    'fields.slug': '/company',
    include: 2,
  };

  const classes = useStyles();

  useEffect(() => {
    if (content && !content.sys) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParams);

          if (state) {
            setContent(state.items ? state.items[0] : {});
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
  }, [content, queryParams]);

  return (
    <div className={classes.Company}>
      {
        !content || !content.fields
          ? (
            <CircularProgress className={classes.loadingProgress} />
          )
          : (
            <FullPageScroll>
              {
                content.fields.block_ref.map((item, index) => {
                  let component;
                  let isBigComponent = false;
                  if (item.fields.layout === 'Quote') {
                    component = <LogoBanner fields={item.fields} firstSection={index === 0} />;
                  } else if (item.fields.layout === 'Full Width (Left Image)') {
                    component = (
                      <StaticTextImage
                        fields={item.fields}
                        leftImage
                        lotties={lottiePhilosophy}
                      />
                    );
                  } else if (item.fields.layout === 'Full Width (Right Image)') {
                    component = <StaticTextImage fields={item.fields} rightImage bgColor="#ffffff" />;
                  } else if (item.sys.id === '7keHrkE3cymfGRV4wHHvaL') {
                    component = <Founder fields={item.fields} leftImage />;
                  } else if (item.sys.id === '2i1lECar52ZdMTkvFwfx6J') {
                    component = <ManagementTeam fields={item.fields} spotlight={false} />;
                  } else if (item.sys.contentType.sys.id === 'blockBlog') {
                    isBigComponent = true;
                    component = <NewsCarousel fields={item.fields} />;
                  } else if (item.fields.history_item_ref) {
                    isBigComponent = true;
                    component = <History fields={item.fields} />;
                  } else {
                    component = JSON.stringify(item);
                  }
                  return (
                    <Slide key={item.sys.id}>
                      <section className={`${classes.SlideContent} ${isBigComponent ? classes.bigSection : null}`}>
                        { component }
                      </section>
                    </Slide>
                  );
                })
              }
              <Slide>
                <Footer />
              </Slide>
            </FullPageScroll>
          )
      }
    </div>
  );
};

export default Company;
