import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  SearchBar: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.text.light}`,
    borderRight: 0,
    borderRadius: 15,
    paddingLeft: 19,
    height: 32.8,
    '& input::placeholder': {
      color: theme.palette.primary.black,
      opacity: 1,
      fontSize: rem(10),
      fontWeight: 'normal',
      letterSpacing: 2.4,
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        letterSpacing: 2.88,
        fontSize: rem(12),
        fontWeight: 'bold',
      },
    },
  },
  InputRoot: {
    width: '100%',
  },
  InputField: {
    height: '100%',
    opacity: 1,
    padding: 0,
    fontSize: rem(10),
    fontWeight: 'normal',
    letterSpacing: 2.4,
    [theme.breakpoints.up('sm')]: {
      letterSpacing: 2.88,
      fontSize: rem(12),
      fontWeight: 'bold',
    },
  },
  IconButton: {
    padding: 0,
    height: '100%',
    width: 32.8,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.white,
  },
  SearchIcon: {
    width: 15,
    [theme.breakpoints.up('sm')]: {
      width: 16,
    },
  },
}));
