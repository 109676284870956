import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import useStyles from './styles';
import LinkButton from '../common/LinkButton';
import LocationItem from '../../assets/location-icon.png';

const JobCard = ({ jobInformation }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <article className={classes.jobCard}>
          <section>
            <Typography variant="body1" component="h4" className={classes.locationText}>
              <img src={LocationItem} alt="Location Icon" />
              {jobInformation.location.name}
            </Typography>
            <Typography variant="h5" component="h3" className={classes.jobCardTitle}>
              {jobInformation.title}
            </Typography>
          </section>
          <LinkButton
            variant="outlined"
            color="primary"
            className={classes.MainButton}
            href={jobInformation.absolute_url}
            component="a"
            target="_blank"
            rel="noopener noreferrer"
          >
            apply now
          </LinkButton>
        </article>
      </Grid>
    </Grid>
  );
};

JobCard.propTypes = {
  jobInformation: PropTypes.shape({
    title: PropTypes.string,
    absolute_url: PropTypes.string,
    location: PropTypes.object,
  }).isRequired,
};

export default JobCard;
