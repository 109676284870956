import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import useStyles from './styles';
import logo from '../../assets/schonfeld-footer.svg';
import linkedInIcon from '../../assets/linkedin-icon.png';
import { getEntries } from '../../utils/contentful';
import { SITE_TITLE } from '../../constants';

export default function Footer() {
  const classes = useStyles();
  const [menuItems, setMenu] = React.useState([]);
  const [socialItems, setSocial] = React.useState([]);

  if (menuItems.length === 0 && socialItems.length === 0) {
    const getMenu = async () => {
      try {
        const state = await getEntries({
          content_type: 'blockNavigation',
          'fields.name': 'Footer Menu',
          include: 2,
        });
        const stateSocial = await getEntries({
          content_type: 'componentSocialNavigationItem',
        });

        if (state) {
          setMenu(state.items ? state.items[0].fields.navigation_item_ref : []);
        }

        if (stateSocial) {
          setSocial(stateSocial.items ? stateSocial.items : []);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('ERROR: ', e);
      }
    };
    getMenu();
  }

  const AdapterLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

  const menuList = menuItems.map(({ fields }) => {
    let componentProps;
    if (fields.navigation_item_ref) {
      return (
        <React.Fragment key={fields.title}>
          <ListItem
            key={fields.title}
            className={classes.navItem}
            component={AdapterLink}
            to={`/${fields.slug}`}
          >
            <Typography variant="body2" component="span">
              {fields.title}
            </Typography>
          </ListItem>
          {fields.navigation_item_ref.map((i) => {
            if (fields.slug !== i.fields.slug) {
              return (
                <ListItem
                  key={i.fields.title}
                  className={classes.navItem}
                  component={AdapterLink}
                  to={`/${fields.slug}/${i.fields.slug}`}
                >
                  <Typography variant="body2" component="span">
                    {i.fields.title}
                  </Typography>
                </ListItem>
              );
            }
            return null;
          })}
        </React.Fragment>
      );
    }
    if (fields.is_external) {
      componentProps = {
        component: 'a',
        href: fields.slug,
        target: '_blank',
        rel: 'noopener noreferrer',
      };
    } else {
      componentProps = {
        component: AdapterLink,
        to: `/${fields.slug}`,
      };
    }
    return (
      <ListItem
        key={fields.title}
        className={classes.navItem}
        {...componentProps}
      >
        <Typography variant="body2" component="span">
          {fields.title}
        </Typography>
      </ListItem>
    );
  });

  const socialList = socialItems.map(({ fields }) => {
    const componentProps = {
      component: 'a',
      href: fields.slug,
      target: '_blank',
      rel: 'noopener noreferrer',
    };
    let socialIconRendered = null;

    switch (fields.icon) {
      case 'LinkedIn':
        socialIconRendered = linkedInIcon;
        break;
      default:
        break;
    }
    return (
      <ListItem
        key={fields.icon}
        className={classes.socialItems}
        {...componentProps}
      >
        <img className={classes.socialItems} src={socialIconRendered} alt={fields.icon} />
      </ListItem>
    );
  });

  return (
    <footer className={classes.footer}>
      <Grid container justify="center">
        <Grid item xs={10} md={4} className={classes.logoItem}>
          <NavLink to="/">
            <img className={classes.logo} src={logo} alt={SITE_TITLE} />
          </NavLink>
        </Grid>
        <Grid item xs={12} md={8} className={classes.legal}>
          <Typography variant="caption" component="span" className={classes.copyright}>
            <span>
              <span>&copy; Copyright 2021&nbsp;</span>
              Schonfeld strategic advisors llc
            </span>
            <span>all rights reserved</span>
          </Typography>
        </Grid>
        <Grid item xs={10} md={11} className={classes.footerItem}>
          <nav className={classes.footerNav}>
            <List className={classes.footerList}>
              {menuList}
            </List>
          </nav>
        </Grid>
        <Grid item xs={10} md={1} className={classes.socialList}>
          <List className={classes.footerList}>
            {socialList}
          </List>
        </Grid>
      </Grid>
    </footer>
  );
}
