import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../RichTextOptions';
import LinkButton from '../LinkButton';
import useStyles from './styles';

function Stage({
  index, title, content, buttonText, buttonUrl,
}) {
  const classes = useStyles();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  return (
    <Grid item sm={10} md={2} className={classes.Stage}>
      <Grid item className={classes.IndexContainer}>
        <Typography variant="h4" component="span" className={classes.Index}>
          {index}
        </Typography>
      </Grid>
      <Grid container alignItems="flex-start" justify="center" className={classes.TitleContainer}>
        <Typography variant="h4" component="h4" color="primary" className={classes.Title}>
          {title}
        </Typography>
      </Grid>
      <Grid container alignItems="flex-start" justify="center" className={classes.Description}>
        {documentToReactComponents(content, options)}
      </Grid>
      <Grid container justify="center" className={classes.Button}>
        <LinkButton
          variant="contained"
          color="primary"
          to={buttonUrl}
        >
          {buttonText}
        </LinkButton>
      </Grid>
    </Grid>
  );
}

Stage.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string,
};

Stage.defaultProps = {
  buttonUrl: '/',
};

export default Stage;
