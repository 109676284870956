import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import Department from '../common/Department/Department';
import useStyles from './styles';

function Departments({ fields }) {
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="center" className={classes.Departments}>
      <Grid item xs={12}>
        <Grid container direction="column" justify="center" alignItems="center" className={classes.DepartmentTitle}>
          <Typography variant="h2" component="h2" className={classes.centerLine}>
            {fields.title}
          </Typography>
        </Grid>
        <div className={classes.DepartmentContainer}>
          {fields.accordion_item_ref.map(d => (
            <Department
              name={d.fields.title}
              key={d.fields.title}
            />
          ))}
        </div>
      </Grid>
    </Grid>
  );
}

Departments.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    accordion_item_ref: PropTypes.array,
  }).isRequired,
};

export default Departments;
