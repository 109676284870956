import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  Careers: {},
  loadingProgress: {
    display: 'block',
    position: 'fixed',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    zIndex: 1301,
  },
  Section: {
    width: '100vw',
    paddingTop: 100,
    [theme.breakpoints.up('md')]: {
      paddingTop: 160,
    },
  },
  careersContent: {
    paddingTop: '1.875rem',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 42,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 80,
    },
  },
  careersList: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  careersFilter: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
}));
