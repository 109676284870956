import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  controls: {
    zIndex: 100,
    color: theme.palette.primary.main,
    margin: theme.spacing(1, 1.5),
    position: 'fixed',
    right: 20,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '& button': {
      transform: 'scale(0.3, 0.3)',
      '-moz-transform': 'scale(0.3, 0.3)',
      backgroundColor: theme.palette.primary.disabled,
      border: 0,
      borderRadius: '50%',
      margin: theme.spacing(0.5, 0),
      cursor: 'pointer',
      width: 12,
      height: 12,
      fontSize: 0,
      '&:first-child, &:last-child': {
        display: 'none',
      },
      '&:nth-last-child(-2n+2)': {
        display: 'none',
      },
      '&[disabled]': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
