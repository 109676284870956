import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  jobCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.main,
    border: `1px solid ${theme.palette.background.borderColor}`,
    borderRadius: 18,
    marginTop: 12,
    marginBottom: 8,
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 26,
    paddingBottom: 26,
    width: '100%',
    height: 'inherit',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 692,
      paddingTop: 16,
      paddingBottom: 16,
      maxHeight: 120,
    },
  },
  MainButton: {
    textTransform: 'uppercase',
  },
  locationText: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    fontSize: rem(12),
    '& > img': {
      marginRight: 5,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(13),
    },
  },
  jobCardTitle: {
    fontSize: rem(18),
    marginBottom: 16,
    lineHeight: 1.5,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 380,
      marginBottom: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 330,
      marginBottom: 0,
      fontSize: rem(20),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 445,
      marginBottom: 0,
    },
  },
}));
