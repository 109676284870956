import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  historyComponent: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    alignItems: 'center',
  },
  timelineContainer: {
    width: 10000,
  },
  timeline: {
    width: 'auto',
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  titleRow: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  buttonBox: {
    position: 'absolute',
    right: 0,
    left: 0,
    width: '100vw',
    zIndex: 1299,
    top: 230,
    [theme.breakpoints.up('md')]: {
      top: '50%',
      width: 'auto',
      left: 'auto',
      paddingRight: 50,
    },
  },
  backIconButton: {
    width: 13,
    height: 26,
    padding: 0,
    color: theme.palette.primary.black,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 50,
      left: '3vw',
      position: 'absolute',
      zIndex: 1,
    },
  },
  backIcon: {
    width: 13,
    height: 26,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 50,
    },
  },
  forwardIconButton: {
    width: 13,
    height: 26,
    padding: 0,
    marginLeft: 12,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 50,
      right: '3vw',
      position: 'absolute',
      zIndex: 1,
    },
  },
  forwardIcon: {
    width: 13,
    height: 26,
    [theme.breakpoints.down('sm')]: {
      width: 26,
      height: 50,
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 20,
  },
  eventCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 32,
    borderRadius: 18,
    marginRight: 32,
    backgroundColor: theme.palette.background.card,
    width: `${(4 / 6) * 100}vw`,
    height: 310,
    position: 'relative',
    textAlign: 'center',
    paddingTop: 64,
  },
  cardIcon: {
    '& img': {
      maxHeight: 50,
    },
  },
  cardTitle: {
    alignSelf: 'stretch',
    paddingTop: 26,
    paddingBottom: 11,
  },
  bottomContainer: {
  },
  lineTitleContainer: {
    marginTop: 35,
    marginBottom: 16,
    width: '100%',
  },
  lineContainer: {
    marginBottom: 60,
    display: 'flex',
    justifyContent: 'left',
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.lines.main,
  },
  eventButtonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: `calc(${(4 / 6) * 100}vw + 32px)`,
    position: 'relative',
    paddingRight: 32,
  },
  event: {
    backgroundColor: theme.palette.primary.main,
    border: 0,
    padding: 0,
    width: 10,
    height: 10,
    borderRadius: 16,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
  eventLabel: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    position: 'absolute',
    top: 22,
    left: '50%',
    transform: 'translateX(calc(-50% - 16px))',
    [theme.breakpoints.up(700)]: {
      left: 0,
      transform: 'translateX(110px)',
      width: 60,
      textAlign: 'center',
    },
  },
  activeEvent: {
    backgroundColor: theme.palette.secondary.main,
    width: 16,
    height: 16,
    position: 'relative',
    overflow: 'visible',
    '&:before': {
      content: '""',
      width: 1,
      height: 63,
      backgroundColor: theme.palette.lines.main,
      position: 'absolute',
      left: 8,
      bottom: 16,
    },
  },
}));
