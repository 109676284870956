import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  appBar: {
    borderBottom: `0px solid ${theme.palette.divider}`,
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: 1300,
    width: '100vw',
  },
  toolbar: {
    flexWrap: 'wrap',
    paddingBottom: 25,
    paddingTop: 25,
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
      paddingLeft: 90,
      paddingRight: 90,
      '&.scrolling': {
        paddingTop: 25,
      },
    },
  },
  toolbarTitle: {
    flexGrow: 1,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: '0 31px',
    ...theme.mixins.toolbar,
  },
  headerLogo: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    '& img': {
      transition: 'all 1s ease-in-out',
      width: 176,
    },
  },
  menuButton: {
    borderRadius: 0,
    padding: 0,
    '& img': {
      height: 15,
    },
  },
  headerList: {
    display: 'flex',
    textTransform: 'uppercase',
    padding: 0,
    '& > span': {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    '& > a': {
      flexShrink: 0,
      width: 'auto',

      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
  link: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1, 1.5),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mainLink: {
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      width: '0',
      height: 3,
      background: theme.palette.secondary.main,
      transition: 'width .3s',
    },
  },
  dropdownContainer: {
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.primary.white,
      position: 'absolute',
    },
  },
  dropdownLink: {
    [theme.breakpoints.up('lg')]: {
      border: `1px solid ${theme.palette.background.card}`,
      '&:hover': {
        backgroundColor: theme.palette.background.card,
      },
      '&.active': {
        [theme.breakpoints.up('lg')]: {
          backgroundColor: theme.palette.background.card,
        },
      },
    },
  },
  dropdownArrow: {
    padding: 0,
    height: '100%',
    color: theme.palette.primary.main,
    '& svg': {
      transition: 'transform 0.5s',
      fontSize: 25,
    },
    '&.open': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  drawerLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    display: 'block',
    paddingLeft: 48,
    paddingRight: 48,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    '& > span': {
      fontWeight: 'bold',
      color: 'inherit',
    },
    '&:hover': {
      [theme.breakpoints.up('lg')]: {
        '&:after': {
          width: '100%',
        },
      },
      '& > span': {
        color: theme.palette.secondary.main,
      },
    },
    '&.active': {
      [theme.breakpoints.up('lg')]: {
        '&:after': {
          width: '100%',
        },
      },
      '& > span': {
        color: theme.palette.secondary.main,
      },
    },
    '&.scrolling': {
      '&.active': {
        [theme.breakpoints.up('lg')]: {
          '&:after': {
            width: 0,
          },
        },
      },
    },
  },
  desktopNav: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  mobileNav: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row-reverse',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));
