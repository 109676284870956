import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Hidden,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import RightArrow from '@material-ui/icons/KeyboardArrowRight';
import useStyles from './styles';
import careersNavLink from '../../utils/url';

const Careers = ({ fields }) => {
  const classes = useStyles();

  const pageNavLink = value => careersNavLink({
    page: '1',
    address: value,
    department: 'Technology',
  });

  return (
    <Grid container justify="center" className={classes.TechnologyLocations}>
      <Grid item xs={8} lg={7} xl={5}>
        <Grid container alignItems="flex-end" justify="center">
          <section className={classes.Content}>
            <Typography variant="h2" component="h2" className={classes.LocationsTitle}>
              {fields.title}
            </Typography>
            <Hidden mdUp>
              <div className={classes.ImageWrapper}>
                {
                  fields.image
                    ? (
                      <img
                        src={fields.image.fields.file.url}
                        alt={fields.title}
                        className={classes.OfficesImage}
                      />
                    )
                    : ''
                }
              </div>
            </Hidden>
            <section>
              {(fields.office_item_ref).map(office => (
                <div key={office.fields.name} className={classes.LocationEntry}>
                  <Typography variant="subtitle1" component="h3" className={classes.LocationTitle}>
                    {office.fields.name}
                  </Typography>
                  <Link className={classes.LocationLink} to={pageNavLink(office.fields.address)}>
                    <Typography variant="h5" component="span">
                      {`SEE OUR OPEN POSITIONS IN ${!office.fields.abbreviation.includes('Asia') ? 'THE' : ''} ${office.fields.abbreviation}`}
                    </Typography>
                    <RightArrow className={classes.Arrow} />
                  </Link>
                </div>
              ))}
            </section>
          </section>
          <Hidden smDown>
            <Grid item xs={4} md={4} xl={5}>
              {
                fields.image
                  ? (
                    <img
                      src={fields.image.fields.file.url}
                      alt={fields.title}
                      className={classes.OfficesImage}
                    />
                  )
                  : ''
              }
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};

Careers.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    office_item_ref: PropTypes.array,
    image: PropTypes.object,
  }).isRequired,
};

export default Careers;
