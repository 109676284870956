import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  InternshipComponent: {
    height: '90%',
    alignItems: 'center',
    textAlign: 'center',
  },
  InternshipTitle: {
    marginTop: 0,
    marginBottom: 20,
  },
  centerLine: {
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  ButtonContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  InternshipButton: {
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      margin: '10px auto',
      '& span': {
        width: 200,
      },
    },
  },
}));
