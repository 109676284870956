import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  SingleMedia: {
    height: '90%',
    alignItems: 'center',
    textAlign: 'center',
  },
  mediaContainer: {
    position: 'relative',
  },
  centerLine: {
    marginBottom: 40,
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  mediaItem: {
    width: '100%',
    borderRadius: 18,
  },
  imageCaption: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.lightBlue,
    padding: 22,
    position: 'absolute',
    right: 20,
    bottom: -20,
    borderRadius: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: rem(14),
      right: 10,
    },
  },
}));
