import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography, Box } from '@material-ui/core';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ResponsiveImage from '../common/ResponsiveImage';

import useStyles from './styles';

const DetailPage = ({ category, item, className }) => {
  const classes = useStyles();
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph className={classes.ContentParagraph}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h4" component="h3" className={classes.ContentSecondaryTitle}>
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h4" component="h3" className={classes.ContentSecondaryTitle}>
          {children}
        </Typography>
      ),
    },
  };

  return (
    item.fields
      ? (
        <Box className={`${classes.detailPageContainer} ${className}`}>
          {
            item.fields.external_url
              ? (
                <a href={item.fields.external_url} target="_blank" rel="noopener noreferrer" className={classes.pictureContainer}>
                  {
                    item.fields.heroImage.fields
                      ? (
                        <ResponsiveImage
                          imageUrl={item.fields.heroImage.fields.file.url}
                          imageTitle={item.fields.heroImage.fields.title}
                          className={classes.cardPicture}
                          sm={500}
                          md={800}
                          lg={1200}
                          xl={1200}
                        />
                      )
                      : null
                  }
                </a>
              )
              : (
                <div className={classes.pictureContainer}>
                  {
                    item.fields.heroImage.fields
                      ? (
                        <ResponsiveImage
                          imageUrl={item.fields.heroImage.fields.file.url}
                          imageTitle={item.fields.heroImage.fields.title}
                          className={classes.cardPicture}
                          sm={500}
                          md={800}
                          lg={1200}
                          xl={1200}
                        />
                      )
                      : null
                  }
                </div>
              )
          }
          <Box className={classes.detailPageContent}>
            <section className={classes.dateAndTagsSection}>
              <Typography variant="body1" className={classes.detailPageDate}>
                {moment(item.fields.publishDate).format('MMMM DD, YYYY')}
              </Typography>
              <Typography paragraph className={classes.hashtagList}>
                {item.fields.tag_ref && item.fields.tag_ref.map(tag => (
                  <NavLink key={tag.fields.name} to={`/blog/${category}?tagId=${tag.sys.id}`} className={classes.hashtag}>
                    {`#${tag.fields.name}`}
                  </NavLink>
                ))}
              </Typography>
            </section>
            <Typography variant="h3" component="h3" className={classes.detailPageTitle}>
              {
                item.fields.external_url
                  ? (
                    <a href={item.fields.external_url} target="_blank" rel="noopener noreferrer">
                      {item.fields.title}
                    </a>
                  )
                  : (
                    item.fields.title
                  )
              }
            </Typography>
            {documentToReactComponents(item.fields.body, options)}
          </Box>
        </Box>
      )
      : (
        <Box />
      )
  );
};

DetailPage.propTypes = {
  category: PropTypes.string.isRequired,
  className: PropTypes.string,
  item: PropTypes.shape({
    fields: PropTypes.object,
  }).isRequired,
};

DetailPage.defaultProps = { className: '' };

export default DetailPage;
