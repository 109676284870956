import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';


const OrderedList = (props) => {
  const classes = useStyles();
  const { children, className } = props;

  return (
    <ol className={`${classes.OrderedList} ${className}`}>
      { children }
    </ol>
  );
};

OrderedList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

OrderedList.defaultProps = {
  className: '',
};

export default OrderedList;
