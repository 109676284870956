import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useBackgroundColor from '../../utils/useBackgroundColor';
import useStyles from './styles';

function MainIllustration({
  title, imgSrc, altText, bgColor, isLogo,
}) {
  const classes = useStyles();
  const ref = useBackgroundColor(bgColor);
  return (
    <Grid
      container
      justify="center"
      className={classes.MainIllustration}
      ref={ref}
    >
      <Grid item xs={isLogo ? 5 : 10} md={isLogo ? 3 : 8} lg={isLogo ? 2 : 6}>
        <img
          src={imgSrc}
          alt={altText}
          className={classes.Illustration}
        />
      </Grid>
      {isLogo ? null : (
        <Grid container justify="center">
          <Typography variant="h2" component="h2" className={classes.centerLine}>
            {title}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

MainIllustration.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  isLogo: PropTypes.bool,
};

MainIllustration.defaultProps = {
  title: '',
  bgColor: '#ffffff',
  isLogo: false,
};

export default MainIllustration;
