import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  News: {
    marginTop: 150,
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
    '& section': {
      width: '100%',
    },
  },
  NewsList: {
    marginTop: 36,
  },
  NewsTitle: {
    margin: 0,
  },
  BlogItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    textDecoration: 'none',
    '&:hover .BlogDescription': {
      color: theme.palette.primary.main,
    },
    '&:hover .arrow': {
      color: theme.palette.secondary.main,
    },
  },
  BlogImage: {
    width: '100%',
  },
  BlogContent: {
    paddingTop: 32,
    paddingBottom: 32,
    borderTop: `1px solid ${theme.palette.primary.disabled}`,
  },
  Summary: {
    paddingLeft: 30,
    borderLeft: `1px solid  ${theme.palette.lines.light}`,
    '&.active': {
      [theme.breakpoints.down('sm')]: {
        marginTop: 5,
        paddingLeft: 0,
        borderLeft: 0,
      },
    },
    '& .BlogDescription': {
      margin: 0,
    },
  },
  Arrow: {
    color: theme.palette.lines.dark,
    marginRight: -10,
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },
  Day: {
    display: 'block',
    color: theme.palette.secondary.main,
    fontSize: rem(24),
    [theme.breakpoints.up('sm')]: {
      fontSize: rem(32),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(25.7),
    },
  },
  Month: {
    color: theme.palette.primary.black,
    display: 'block',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontWeight: 'bold',
    },
  },
  CardTitle: {
    paddingBottom: 15,
    '& > a': {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));
