import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  founderMain: {
    height: '100%',
    alignItems: 'center',
  },
  founderContainer: {
    backgroundColor: theme.palette.background.card,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
  },
  textContainer: {
    alignSelf: 'center',
  },
  founderTitle: {
    marginBottom: 40,
  },
  founderMainTitle: {
    fontSize: rem(28),
    paddingBottom: 4,
  },
  founderSecondaryTitle: {
    fontSize: rem(18),
  },
  founderParagraph: {
    paddingTop: 27,
    paddingBottom: 25,
    [theme.breakpoints.up('md')]: {
      paddingTop: 21,
      paddingBottom: 20,
    },
  },
  staticImage: {
    width: '100%',
    height: '100%',
    marginRight: 40,
    display: 'table-cell',
    verticalAlign: 'bottom',
    paddingTop: 40,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
    '@media only screen and (min-width: 960px)': {
      paddingRight: 40,
    },
  },
}));
