import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';


export default makeStyles(theme => ({
  Careers: {
    backgroundColor: theme.palette.background.card,
    paddingTop: 40,
    paddingBottom: 40,
    borderRadius: '0 50px 50px 0',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '100%',
      padding: 0,
    },
  },
  CareersCta: {
    order: 1,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 80,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      order: 2,
      margin: 0,
    },
  },
  CareerDetail: {
    lineHeight: 0.98,
    marginBottom: 10,

  },
  CareerDetailBlock: {
    marginTop: 17,
    marginBottom: 24,
  },
  Icon: {
    maxWidth: 83,
    maxHeight: 73,
    marginBottom: rem(12),
  },

}));
