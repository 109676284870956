import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useOnScreen from '../../utils/useOnScreen';
import { headingOptions } from '../common/RichTextOptions';
import ResponsiveImage from '../common/ResponsiveImage';
import LinkButton from '../common/LinkButton';
import useStyles from './styles';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';
import UncontrolledLottie from '../common/Lottie/UncontrolledLottie';
import isIE from '../../utils/isIE';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const RightImage = ({ fields, strategyLotties }) => {
  const classes = useStyles();
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [ref, isVisible] = useOnScreen();
  const SliderLength = fields.cta_item_ref.length;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography paragraph>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };

  const handleSlideChange = (step) => {
    setActiveSlide(step);
  };

  const handleNextSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide + 1);
  };

  const handlePreviousBack = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide - 1);
  };

  const renderImage = (ctaItem, index) => {
    if (!isIE() && strategyLotties[index]) {
      const animations = strategyLotties[index];
      const { h, w } = animations[0].animation;
      const isLandscape = w > h;
      return (
        <React.Fragment>
          {
              activeSlide === index && (
              <UncontrolledLottie
                className={isLandscape ? '' : classes.lottiePortrait}
                lotties={animations}
                delay={0.3}
              />
              )
          }
        </React.Fragment>
      );
    }
    return ctaItem.fields.image
      ? (
        <ResponsiveImage
          className={classes.imageCarousel}
          imageUrl={ctaItem.fields.image.fields.file.url}
          imageTitle={ctaItem.fields.title}
          sm={500}
          md={440}
          lg={620}
          xl={810}
        />
      ) : null;
  };

  return (
    <React.Fragment>
      <Grid container className={classes.rightImage} justify="space-around" ref={ref}>
        <Grid item xs={1} md={2}>
          <Grid container justify="center">
            <Grid item className={classes.arrowBlock}>
              <ArrowIcon
                disabled={activeSlide === 0}
                onClickHandler={handlePreviousBack}
                reverseArrow
                arrowClass={classes.iconSize}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={9} md={8}>
          <AutoPlaySwipeableViews
            autoplay={isVisible}
            axis="x"
            index={activeSlide}
            onChangeIndex={handleSlideChange}
            enableMouseEvents
            interval={10000}
            animateTransitions={activeSlide !== 0}
          >
            {fields.cta_item_ref.map((ctaItem, index) => (
              <Grid
                container
                key={ctaItem.fields.title}
                alignItems="center"
                justify="space-between"
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={classes.carouselInformation}
                >
                  <Typography
                    variant="h2"
                    component="h2"
                    className={classes.rightImageTitle}
                  >
                    {ctaItem.fields.title}
                  </Typography>
                  <section className={classes.rightImageText}>
                    {documentToReactComponents(ctaItem.fields.content, options)}
                  </section>
                  <LinkButton
                    variant="contained"
                    color="primary"
                    to={ctaItem.fields.button_slug}
                    className={classes.rightImageBtn}
                  >
                    {ctaItem.fields.button_text}
                  </LinkButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.carouselIllustrations}
                >
                  {renderImage(ctaItem, index)}
                </Grid>
              </Grid>
            ))}
          </AutoPlaySwipeableViews>
        </Grid>
        <Grid item xs={1} md={2}>
          <Grid container justify="center">
            <Grid
              item
              className={`${classes.arrowBlock} ${classes.nextSlideArrow}`}
            >
              <ArrowIcon
                disabled={activeSlide === SliderLength - 1}
                onClickHandler={handleNextSlide}
                arrowClass={classes.iconSize}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

RightImage.propTypes = {
  fields: PropTypes.shape({
    cta_item_ref: PropTypes.array,
  }).isRequired,
  strategyLotties: PropTypes.arrayOf(PropTypes.array),
};
RightImage.defaultProps = {
  strategyLotties: [],
};
export default RightImage;
