import React, { useEffect, useReducer } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import qs from 'query-string';
import useStyles from './styles';
import { getEntries } from '../../utils/contentful';
import Footer from '../../components/c_003-Footer/Footer';
import BannerCareers from '../../components/c_018-banner-careers/BannerCareers';
import CareersList from '../../components/c_019-careers-list/CareersList';
import CareersFilter from '../../components/c_023-careers-list-filter/CareersFilter';
import JSONJobBoard from '../../components/c_024-JSON-LD-Job-Board/JSONJobBoard';
import { initialState, reducer } from './state';
import { getDepartments, getJobs } from '../../api/greenhouse';
import { greenhouseBoardToken } from '../../utils/config';
import { ITEM_LIMIT } from './jobsTree';

const queryParams = {
  content_type: 'contentPage',
  'fields.slug': '/open-positions',
  include: 2,
};

const OpenPositions = ({ history }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    loading, content, jobsTree, jobsData, officeNames, departmentNames, totalItems, pageCount,
  } = state;
  const classes = useStyles();

  const queryString = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const page = queryString.page ? parseInt(queryString.page, 10) - 1 : 0;
  const address = queryString.address ? queryString.address : '';
  const department = queryString.department ? queryString.department : '';
  const search = queryString.q ? queryString.q : '';

  useEffect(() => {
    let mounted = true;
    const doFetch = async () => {
      try {
        dispatch({ type: 'SET_LOADING', payload: true });
        const entries = await getEntries(queryParams);
        const jobs = await getJobs(greenhouseBoardToken);
        const departments = await getDepartments(greenhouseBoardToken);

        if (mounted) {
          dispatch({
            type: 'INIT',
            payload: {
              entries,
              jobsData: jobs,
              departments,
              department,
              address,
              search,
              page,
            },
          });
        }
      } catch (e) {
        console.log('ERROR: ', e); // eslint-disable-line no-console
      } finally {
        if (mounted) dispatch({ type: 'SET_LOADING', payload: false });
      }
    };
    doFetch();

    return () => { mounted = false; };
  }, [page, address, department, search]);

  return (
    <div className={classes.Careers}>
      <JSONJobBoard jsonldData={jobsData} />
      {
        content.fields
          ? (
            <Box>
              {content.fields.block_ref.map((item) => {
                if (item.fields.layout === 'Quote') {
                  return (
                    <section key={item.sys.id} className={classes.Section}>
                      <BannerCareers fields={item.fields} />
                    </section>
                  );
                }
                return null;
              })}
              <Grid
                container
                direction="row-reverse"
                className={classes.careersContent}
              >
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={12} md={8} className={classes.careersList}>
                      <CareersList
                        history={history}
                        fields={jobsTree}
                        loading={loading}
                        totalItems={totalItems}
                        renderedItems={pageCount}
                        itemsLimit={ITEM_LIMIT}
                        page={page}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.careersFilter}>
                      <CareersFilter
                        history={history}
                        departmentsList={departmentNames}
                        officesList={officeNames}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Footer />
            </Box>
          )
          : (
            <CircularProgress className={classes.loadingProgress} />
          )
      }
    </div>
  );
};

OpenPositions.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object,
  }),
};

OpenPositions.defaultProps = {
  history: {},
};

export default OpenPositions;
