import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';
import isIE from '../../utils/isIE';

export default makeStyles(theme => ({
  quoteBlockComponent: {
    height: isIE() ? '100vh' : '100%',
    alignItems: 'center',
  },
  quoteBlockContainer: {
    width: '100%',
  },
  quoteBlockImageBlock: {
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 40,
  },
  quoteBlockTitle: {
    marginTop: 0,
    marginBottom: 20,
  },
  centerLine: {
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  quoteBlockImage: {
    width: '100%',
  },
  quoteBlockParagraph: {
    marginBottom: 20,
  },
  paragraphBlock: {
    display: 'flex',
    marginBottom: 20,
    '& div > p': {
      color: theme.palette.primary.main,
      fontSize: rem(18),
      [theme.breakpoints.down('md')]: {
        fontSize: rem(15),
      },
    },
  },
  ScrollIcon: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: 14,
    },
  },
  hideLine: {
    '&:before': {
      display: 'none',
    },
  },
  biggerText: {
    '& p': {
      fontSize: rem(16),
      [theme.breakpoints.up('lg')]: {
        fontSize: rem(18),
      },
    },
  },
}));
