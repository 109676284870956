import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import { ReactComponent as QuoteIcon } from '../../../assets/quote-light-green.svg';
import useStyles from './styles';

function Project({
  name, title, description, image,
}) {
  const classes = useStyles();
  return (
    <Grid item className={classes.Project}>
      <QuoteIcon />
      <Grid container className={classes.DescriptionContainer}>
        <Typography color="primary" className={classes.ProjectDescription}>
          {description}
        </Typography>
      </Grid>
      <Grid container>
        {image ? (
          <Grid item>
            <img className={classes.ProjectImage} src={image} alt={title} />
          </Grid>
        ) : null}
        <Grid item>
          <Typography color="primary" className={classes.Name}>{name}</Typography>
          <Typography>{title}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

Project.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default Project;
