import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  Careers: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& > div': {
      display: 'table-row',
    },
  },
  loadingProgress: {
    display: 'block',
    position: 'fixed',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    zIndex: 1301,
  },
  SlideContent: {
    width: '100vw',
    paddingTop: 100,
    [theme.breakpoints.up('md')]: {
      paddingTop: 155,
    },
    '@media only screen and (min-width: 1280px) and (min-height: 680px) and (orientation: landscape)': {
      height: '100vh',
    },
  },
  LocationsSlider: {
    paddingRight: 'calc(67% - 10px)',
    [theme.breakpoints.down('md')]: {
      paddingRight: 'calc(52% - 10px)',
    },
    [theme.breakpoints.only('xs')]: {
      paddingRight: 0,
    },
    '& div > div': {
      justifyContent: 'center',
    },
  },
}));
