import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import Stage from '../common/Stage/Stage';
import useStyles from './styles';
import useBackgroundColor from '../../utils/useBackgroundColor';

function CareerStages({ fields }) {
  const classes = useStyles();
  const ref = useBackgroundColor('#ffffff');
  return (
    <Grid container justify="center" alignContent="center" className={classes.CareerStages} ref={ref}>
      <Grid item xs={10} md={12}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="h2" component="h2" className={classes.centerLine}>
            {fields.title}
          </Typography>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="flex-start" className={classes.StagesContainer}>
          {fields.cta_item_ref.map((el, index) => (
            <Stage
              key={el.fields.title}
              index={index + 1}
              title={el.fields.title}
              content={el.fields.content}
              buttonText={el.fields.button_text}
              buttonUrl={el.fields.button_slug}
            />
          ))}
          <div className={classes.line} />
        </Grid>
      </Grid>
    </Grid>
  );
}

CareerStages.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    cta_item_ref: PropTypes.array,
  }).isRequired,
};

export default CareerStages;
