import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import useOnScreen from '../../utils/useOnScreen';
import LinkButton from '../common/LinkButton';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';
import useStyles from './styles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Offices = ({ fields }) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [ref, isVisible] = useOnScreen();
  const SliderLength = (fields.office_item_ref).length;
  const classes = useStyles();

  const handleSlideChange = (step) => {
    setActiveSlide(step);
  };

  const handleNextSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide + 1);
  };

  const handlePreviousBack = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide - 1);
  };

  return (
    <React.Fragment>
      <Grid container direction="row-reverse" className={classes.offices} ref={ref}>
        <Grid item xs={12} className={classes.officesBackground}>
          <img className={classes.background} src={fields.image.fields.file.url} alt="World Map" />
        </Grid>
        <Grid item xs={11} sm={10} className={classes.officesContent}>
          <Grid container alignItems="flex-end" justify="space-between">
            <Grid item xs={12} md={4} className={classes.officesInformation}>
              <Typography variant="h2" component="h2">
                {fields.title}
              </Typography>
              <Typography paragraph className={classes.officesInformationDescription}>
                {fields.content.content.map(item => item.content.map(contentText => (
                  contentText.value
                )))}
              </Typography>
              <LinkButton variant="contained" color="primary" className={classes.officesInformationBtn} to={fields.button_slug}>
                {fields.button_text}
              </LinkButton>
            </Grid>
            <Grid item xs={12} md={7} className={classes.officesCarousel}>
              <Grid container alignItems="center">
                <Grid item xs={3} md={2} className={classes.sliderIcon}>
                  <ArrowIcon
                    disabled={activeSlide === 0}
                    onClickHandler={handlePreviousBack}
                    reverseArrow
                    arrowClass={classes.iconSize}
                  />
                </Grid>
                <Grid item xs={6} md={6} className={classes.ImagesBlock}>
                  <AutoPlaySwipeableViews
                    autoplay={isVisible}
                    axis="x"
                    index={activeSlide}
                    onChangeIndex={handleSlideChange}
                    enableMouseEvents
                    interval={5000}
                    animateTransitions={activeSlide !== 0}
                  >
                    {(fields.office_item_ref).map(imageInformation => (
                      <article
                        key={imageInformation.fields.name}
                        className={classes.carouselImages}
                      >
                        {
                          imageInformation.fields.image
                            ? (
                              <img
                                className={classes.image}
                                src={imageInformation.fields.image.fields.file.url}
                                alt={imageInformation.fields.name}
                              />
                            )
                            : ''
                        }
                        <Typography variant="subtitle1" component="h3" className={classes.carouselLocationName}>
                          {imageInformation.fields.name}
                        </Typography>
                      </article>
                    ))}
                  </AutoPlaySwipeableViews>
                </Grid>
                <Grid item xs={3} md={2} className={classes.sliderIcon}>
                  <ArrowIcon
                    disabled={activeSlide === SliderLength - 1}
                    onClickHandler={handleNextSlide}
                    arrowClass={classes.iconSize}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <MobileStepper
                    steps={SliderLength}
                    position="static"
                    variant="dots"
                    activeStep={activeSlide}
                    classes={{
                      root: classes.dotsContainer,
                      dots: classes.dots,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Offices.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    image: PropTypes.object,
    content: PropTypes.object,
    office_item_ref: PropTypes.array,
  }).isRequired,
};

export default Offices;
