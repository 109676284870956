const maxSlides = (component) => {
  let slidesLimit;
  switch (component) {
    case 'Locations':
      slidesLimit = {
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 3,
      };
      break;
    case 'Projects':
      slidesLimit = {
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 3,
      };
      break;
    default:
      slidesLimit = {
        xs: 1, sm: 1, md: 1, lg: 1, xl: 1,
      };
  }
  return slidesLimit;
};

export default maxSlides;
