import { makeStyles } from '@material-ui/styles';
import isIE from '../../utils/isIE';

export default makeStyles(() => ({
  Statistics: {
    height: isIE() ? '100%' : '90%',
    alignItems: 'center',
  },
  centerLine: {
    marginBottom: 40,
    '&:before': {
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));
