import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import useStyles from './styles';

const UncontrolledLottie = (props) => {
  const classes = useStyles();
  const { className, lotties, delay } = props;
  const [isPaused, setPaused] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPaused(true);
    }, delay * 1000);
    return () => clearTimeout(timer);
  }, [delay]);

  const OptionsMapper = (animation, loop) => ({
    loop,
    autoplay: isPaused,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <div className={`${className} ${classes.lottieContainer}`}>
      {lotties.map(item => (
        <Lottie
          key={item.name}
          height="auto"
          options={OptionsMapper(item.animation, item.loop ? item.loop : false)}
        />
      ))}
    </div>
  );
};

UncontrolledLottie.propTypes = {
  className: PropTypes.string,
  lotties: PropTypes.arrayOf(PropTypes.shape).isRequired,
  delay: PropTypes.number,
};
UncontrolledLottie.defaultProps = {
  className: '',
  delay: 1,
};

export default UncontrolledLottie;
