import parseJobsTree from './jobsTree';

export const initialState = {
  loading: false,
  content: {},
  jobsTree: [],
  jsonldData: {},
  totalItems: 0,
  pageCount: 0,
};

export function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case 'SET_LOADING': {
      return { ...state, loading: payload };
    }
    case 'INIT': {
      const {
        entries, jobsData, departments, department, address, search, page,
      } = payload;

      const content = entries && entries.items ? entries.items[0] : state.content;
      const [openPositionsBlock] = content.fields.block_ref.filter(element => element.fields.title === 'Open Positions');
      const locations = openPositionsBlock.fields.office_item_ref.map(x => x.fields.address);

      const {
        jobsTree, departmentNames, officeNames, totalItems, pageCount,
      } = parseJobsTree({
        departments, department, address, search, page, locations,
      });

      return {
        ...state,
        content,
        jobsData,
        jobsTree,
        departmentNames,
        officeNames,
        totalItems,
        pageCount,
      };
    }
    default:
      return state;
  }
}
