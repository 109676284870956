import { contactFormURL } from '../utils/config';

function submit(values) {
  return fetch(contactFormURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  }).then(res => res.json());
}

export default { submit };
