import { makeStyles } from '@material-ui/styles';
import { rem } from '../../../constants';

export default makeStyles(theme => ({
  BulletList: {
    '& p': {
      marginLeft: rem(24),
      marginBottom: rem(10),
    },
    '& p::before': {
      content: '"\\2022"',
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      display: 'inline-block',
      width: rem(24),
      marginLeft: rem(-24),
    },
  },

}));
