import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';


const TitleRichText = (props) => {
  const classes = useStyles();
  const { children, variant, component } = props;

  return (
    <Typography className={classes.TitleRichText} variant={variant} component={component}>
      { children }
    </Typography>
  );
};

TitleRichText.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  component: PropTypes.string,
};
TitleRichText.defaultProps = {
  variant: '',
  component: '',
};

export default TitleRichText;
