import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'query-string';
import moment from 'moment';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import useStyles from './styles';
import { getEntries } from '../../utils/contentful';
import Card from '../../components/common/Card/Card';
import SearchBar from '../../components/c_022-search-bar/SearchBar';
import FeaturedArticles from '../../components/c_020-featured-articles/FeaturedArticles';
import Footer from '../../components/c_003-Footer/Footer';


const BlogPage = (props) => {
  const [content, setContent] = useState([]);
  const [parameters, setParameters] = useState({
    categoryTags: {
      financial: '',
      news: '',
      events: '',
      'featured-slug': '',
    },
    totalItems: -1,
    activeTag: '',
    activePage: -1,
    lastPage: 0,
    search: '',
  });
  const classes = useStyles();
  const { match, location, history } = props;
  const { category } = match.params;
  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { page, tagId, q: search } = queryString;
  const currentDate = moment().format();

  const itemsLimit = 6;
  const currentPage = page ? parseInt(page, 10) - 1 : 0;
  const skipItems = currentPage * itemsLimit;
  const fullPagination = (location.search === '')
    ? '?'
    : `${tagId ? `?tagId=${tagId}&${search ? `q=${search}&` : ''}` : `${search ? `?q=${search}&` : '?'}`}`;

  const queryParamsEntries = {
    content_type: 'blogPost',
    'fields.category[match]': category,
    'fields.publishDate[lte]': currentDate,
    order: '-fields.publishDate',
    include: 3,
    limit: itemsLimit,
    skip: skipItems,
  };

  const blogTitles = {
    News: 'News & Press',
    Culture: 'Culture',
    Technology: 'From the developers',
  };

  const queryParamsHashtags = {
    content_type: 'tag',
  };

  const refContainer = React.useRef(null);

  if (tagId) {
    queryParamsEntries.links_to_entry = tagId;
  }

  if (search) {
    queryParamsEntries.query = search;
  }

  useEffect(() => {
    let mounted = true;
    if (
      (content.length === 0 && parameters.totalItems < 0)
      || (currentPage !== parameters.activePage)
      || (tagId !== parameters.activeTag)
      || (search !== parameters.search)
    ) {
      const getData = async () => {
        try {
          const state = await getEntries(queryParamsEntries);
          const stateHt = await getEntries(queryParamsHashtags);

          if (stateHt) {
            const tagElements = {};
            stateHt.items.forEach((item) => { tagElements[item.fields.slug] = item.sys.id; });

            if (mounted && state) {
              setContent(state.items.length > 0 ? state.items : []);
              setParameters({
                search,
                categoryTags: tagElements,
                totalItems: state.total,
                activeTag: tagId,
                activePage: currentPage,
                lastPage: state.total % itemsLimit === 0
                  ? (state.total / itemsLimit) - 1
                  : Math.floor(state.total / itemsLimit),
              });
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('ERROR: ', e);
        }
      };
      getData();
    }
    return () => { mounted = false; };
  }, [
    content,
    currentPage,
    parameters,
    setParameters,
    queryParamsEntries,
    queryParamsHashtags,
    tagId,
    search,
  ]);

  const handleScrollToElement = () => {
    window.scroll(0, refContainer.current.offsetTop - 100);
  };

  const searchNavLink = (value) => {
    let link = '?page=1&';
    if (tagId) {
      link += `tagId=${tagId}&`;
    }
    if (value.trim()) {
      link += `q=${value.trim()}`;
    }
    history.push(link);
  };

  const createPagination = () => {
    const itemArray = [];
    for (let i = 0; i <= parameters.lastPage; i += 1) {
      itemArray.push((
        <Typography key={`link-${i}`} variant="body1" component="span">
          <NavLink
            to={`${fullPagination}page=${i + 1}`}
            exact
            onClick={handleScrollToElement}
            className={`${classes.paginationElement} ${currentPage === i ? 'active' : ''}`}
          >
            &nbsp;
            {i + 1}
            &nbsp;
          </NavLink>
        </Typography>
      ));
    }

    return itemArray;
  };

  return (
    <div className={classes.BlogPage}>
      {
        content.length === 0 && parameters.totalItems < 0
          ? (
            <CircularProgress className={classes.loadingProgress} />
          )
          : (
            <Box>
              <Grid container direction="row-reverse" className={classes.Section}>
                <Grid container item xs={11} md={4} direction="row-reverse">
                  <Box className={classes.Sidebar}>
                    <Typography variant="h3" component="h3" className={classes.SideSectionTitle}>
                      Choose a Category
                    </Typography>
                    <Typography variant="h5" component="span" className={classes.SideHashtag}>
                      <NavLink to={`?tagId=${parameters.categoryTags.financial}`}>
                        #Financial
                      </NavLink>
                    </Typography>
                    <Typography variant="h5" component="span" className={classes.SideHashtag}>
                      <NavLink to={`?tagId=${parameters.categoryTags.news}`}>
                        #News
                      </NavLink>
                    </Typography>
                    <Typography variant="h5" component="span" className={classes.SideHashtag}>
                      <NavLink to={`?tagId=${parameters.categoryTags.events}`}>
                        #Events
                      </NavLink>
                    </Typography>
                    <Typography variant="h3" component="h3" className={classes.SideSectionTitle}>
                      Search for Articles
                    </Typography>
                    <SearchBar onSearch={searchNavLink} initValue={search} />
                    <Typography variant="h3" component="h3" className={classes.SideSectionTitle}>
                      Featured Articles
                    </Typography>
                    <FeaturedArticles
                      tagIds={parameters.categoryTags}
                      onClickEvent={handleScrollToElement}
                    />
                  </Box>
                </Grid>
                <Grid container item alignContent="flex-start" justify="flex-start" xs={11} md={7}>
                  <Grid item xs={11}>
                    <Typography variant="h2" component="h2" className={classes.BlogTitle} ref={refContainer}>
                      {blogTitles[category] || 'From the developers'}
                    </Typography>
                  </Grid>
                  <Grid container justify="flex-start">
                    {
                      content.length > 0
                        ? content.map(item => (
                          <Grid
                            item
                            xs={11}
                            sm={6}
                            xl={4}
                            key={item.sys.id}
                          >
                            <Card
                              item={item}
                              category={category}
                              className={classes.BlogCard}
                              withButton
                            />
                          </Grid>
                        ))
                        : (
                          <Typography variant="h4" component="h3">
                            No results found.
                          </Typography>
                        )
                    }
                  </Grid>
                  <Grid item xs={11} className={classes.pagination}>
                    <Hidden xsUp={parameters.lastPage <= 0}>
                      <NavLink
                        to={`?page=${currentPage === 0 ? currentPage + 1 : currentPage}`}
                        className={`${classes.paginationPrevious} ${currentPage === 0 ? 'disabled' : ''}`}
                        onClick={handleScrollToElement}
                      >
                        <ChevronLeft />
                      </NavLink>
                    </Hidden>
                    <Box className={classes.navPages}>
                      {
                        parameters.totalItems > itemsLimit
                          ? createPagination()
                          : null
                      }
                    </Box>
                    <Hidden xsUp={parameters.lastPage <= 0}>
                      <NavLink
                        to={`?page=${currentPage === parameters.lastPage ? currentPage + 1 : currentPage + 2}`}
                        className={`${classes.paginationNext} ${currentPage === parameters.lastPage ? 'disabled' : ''}`}
                        onClick={handleScrollToElement}
                      >
                        <ChevronRight />
                      </NavLink>
                    </Hidden>
                  </Grid>
                </Grid>
              </Grid>
              <Footer />
            </Box>
          )
      }
    </div>
  );
};

BlogPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

export default BlogPage;
