import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, Box,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { headingOptions } from '../common/RichTextOptions';
import { ReactComponent as QuoteIcon } from '../../assets/quote-dark-blue.svg';
import useStyles from './styles';
import useBackgroundColor from '../../utils/useBackgroundColor';

function TextQuote({ fields }) {
  const ref = useBackgroundColor('#8acac8');
  const classes = useStyles();

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        children[0] !== '' && (
        <Typography
          variant="h4"
          paragraph
          className={classes.TextQuoteDescription}
        >
          {children}
        </Typography>
        )
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <Grid item>
          <QuoteIcon className={classes.TextQuoteIcon} />
          {children}
        </Grid>
      ),
      ...headingOptions,
    },
  };

  return (
    <Grid container justify="center" alignItems="center" className={classes.TextQuote} ref={ref}>
      <Grid item xs={11} md={7}>
        <Box className={classes.TextQuoteBox}>
          {documentToReactComponents(fields.content, options)}
        </Box>
      </Grid>
    </Grid>
  );
}

TextQuote.propTypes = {
  fields: PropTypes.shape({
    content: PropTypes.object,
  }).isRequired,
};

export default TextQuote;
