import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid,
} from '@material-ui/core';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useStyles from './styles';
import LinkButton from '../common/LinkButton';
import { headingOptions } from '../common/RichTextOptions';


const Careers = ({ fields }) => {
  const classes = useStyles();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography variant="h4" className={classes.CareerDetail}>
          {children}
        </Typography>
      ),
      ...headingOptions,
    },
  };
  return (
    <Grid container className={classes.Careers}>
      <Grid item xs={8}>
        <section>
          {
            fields.image
              ? (
                <img
                  src={fields.image.fields.file.url}
                  alt="Carrers Icon"
                  className={classes.Icon}
                />
              )
              : ''
          }
          <Typography variant="h2" component="h2">
            {fields.title}
          </Typography>
          <section className={classes.CareerDetailBlock}>
            {documentToReactComponents(fields.content, options)}
          </section>
          <LinkButton variant="outlined" color="primary" className={classes.MainButton} to={fields.button_slug}>
            {fields.button_text}
          </LinkButton>
        </section>
      </Grid>
    </Grid>
  );
};

Careers.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    button_slug: PropTypes.string,
    button_text: PropTypes.string,
    content: PropTypes.object,
    image: PropTypes.object,
  }).isRequired,
};

export default Careers;
