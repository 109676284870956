import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Grid, useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import ArrowIcon from '../common/ArrowIcon/ArrowIcon';
import useStyles from './styles';
import useBackgroundColor from '../../utils/useBackgroundColor';
import maxSlides from './maxSlides';

function Carousel({
  children, title, bgColor, sliderClassName, childComponent,
}) {
  const [activeSlide, setActiveSlide] = useState(0);
  const classes = useStyles();
  const ref = useBackgroundColor(bgColor);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const maxLimit = maxSlides(childComponent);

  const slidesLimit = () => {
    let limit;
    if (isXs) {
      limit = maxLimit.xs;
    } else if (isSm) {
      limit = maxLimit.sm;
    } else if (isMd) {
      limit = maxLimit.md;
    } else if (isLg) {
      limit = maxLimit.lg;
    } else {
      limit = maxLimit.xl;
    }
    return limit;
  };

  const handleSlideChange = (slide) => {
    setActiveSlide(slide);
  };

  const handleNextSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide + 1);
  };

  const handleBackSlide = () => {
    setActiveSlide(prevActiveSlide => prevActiveSlide - 1);
  };

  return (
    <Grid container justify="flex-end" className={classes.Carousel} ref={ref}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={10} sm={8} md={9} lg={8}>
            <section>
              <Typography variant="h2" component="h2" className={classes.CarouselTitle}>
                {title}
              </Typography>
            </section>
          </Grid>
          <Grid container item xs={12} justify="center">
            <Grid item xs={2} md={1} className={classes.arrowLeft}>
              <ArrowIcon
                disabled={activeSlide === 0}
                onClickHandler={handleBackSlide}
                reverseArrow
                arrowClass={classes.Arrow}
              />
            </Grid>
            <Grid item xs={8} className={classes.CardsSlider}>
              <SwipeableViews
                axis="x"
                index={activeSlide}
                className={`${classes.Slider} ${sliderClassName}`}
                onChangeIndex={handleSlideChange}
                slideClassName={classes.SlideContainer}
                enableMouseEvents
              >
                {children}
              </SwipeableViews>
            </Grid>
            <Grid item xs={2} md={1} className={classes.arrowRight}>
              <ArrowIcon
                disabled={activeSlide === children.length - slidesLimit()}
                onClickHandler={handleNextSlide}
                arrowClass={classes.Arrow}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Carousel.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  sliderClassName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired,
  childComponent: PropTypes.string,
};

Carousel.defaultProps = {
  bgColor: '#ffffff',
  sliderClassName: '',
  childComponent: '',
};

export default Carousel;
