import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  carouselIcon: {
    padding: 0,
    '&:disabled > span > svg': {
      fill: theme.palette.primary.disabled,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  prevButton: {
    transform: 'rotate(180deg)',
  },
}));
