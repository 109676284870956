import { makeStyles } from '@material-ui/styles';
import { rem } from '../../constants';

export default makeStyles(theme => ({
  detailPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 20,
    },
  },
  pictureContainer: {
    border: `1px solid ${theme.palette.lines.border}`,
    borderBottom: 0,
    borderRadius: '18px 18px 0 0',
    position: 'relative',
    overflow: 'hidden',
    '&:before': {
      display: 'block',
      content: '""',
      width: '100%',
      paddingTop: '100%',
      [theme.breakpoints.up('md')]: {
        paddingTop: '56.25%',
      },
    },
  },
  cardPicture: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: 0,
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: '100%',
    },
  },
  detailPageContent: {
    border: `1px solid ${theme.palette.lines.border}`,
    borderRadius: '0 0 18px 18px',
    borderTop: 0,
    flexGrow: 2,
    padding: 15,
    [theme.breakpoints.up('lg')]: {
      padding: 30,
    },
  },
  dateAndTagsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('1250')]: {
      flexDirection: 'row',
    },
  },
  ContentParagraph: {
    marginTop: 16,
  },
  ContentSecondaryTitle: {
    fontSize: rem(15),
    [theme.breakpoints.up('md')]: {
      fontSize: rem(22),
    },
  },
  detailPageDate: {
    paddingBottom: 10,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 20,
    },
  },
  hashtagList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  hashtag: {
    color: theme.palette.primary.black,
    display: 'block',
    letterSpacing: 2.88,
    paddingRight: 10,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: rem(12),
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline',
    },
  },
  detailPageTitle: {
    paddingBottom: 15,
    '& > a': {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: rem(28),
      paddingBottom: 25,
    },
  },
}));
