import React, { useReducer, useRef } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Grid,
  Snackbar,
  FormHelperText,
} from '@material-ui/core';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReCAPTCHA from 'react-google-recaptcha';
import { reducer, initialState } from './state';
import ContactFormApi from '../../api/contactform';
import { contactFormSiteKey as sitekey } from '../../utils/config';

export default function ContactForm() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const ref = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    ContactFormApi.submit(state.values).then((res) => {
      if (res.success) {
        dispatch({ type: 'SUBMIT_SUCCESS' });
        ref.current.reset();
      } else if (res.errors) {
        dispatch({ type: 'SUBMIT_VALIDATION_ERRORS', payload: { errors: res.errors } });
      } else {
        dispatch({ type: 'SUBMIT_SERVER_ERRORS' });
      }
    }, () => dispatch({ type: 'SUBMIT_SERVER_ERRORS' }));
  };

  const handleChange = (e) => {
    dispatch({
      type: 'SET_VALUE',
      payload: {
        name: e.target.name,
        data: e.target.value,
      },
    });
  };

  const notificationMsg = (
    state.notification ? (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {state.notification.type !== 'error' ? <CheckCircleIcon color="secondary" /> : <ErrorOutlinedIcon color="error" /> }
        <span style={{ marginLeft: '5px', fontSize: '1rem' }}>{state.notification.message}</span>
      </span>
    ) : null

  );

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Snackbar
        message={notificationMsg}
        open={state.notification}
        autoHideDuration={5000}
        onClose={() => dispatch({ type: 'CLOSE_NOTIFICATION' })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="First Name" variant="outlined" required name="firstName" onChange={handleChange} value={state.values.firstName} error={state.errors.firstName !== undefined} helperText={state.errors.firstName} inputProps={{ styles: { backgroundColor: 'red' } }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Last Name" variant="outlined" required name="lastName" onChange={handleChange} value={state.values.lastName} error={state.errors.lastName !== undefined} helperText={state.errors.lastName} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Email" variant="outlined" required name="email" onChange={handleChange} value={state.values.email} error={state.errors.email !== undefined} helperText={state.errors.email} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField select fullWidth label="Topic" variant="outlined" required name="topic" onChange={handleChange} value={state.values.topic}>
            <MenuItem value="general">General</MenuItem>
            <MenuItem value="investors">Investors</MenuItem>
            <MenuItem value="careers">Careers</MenuItem>
            <MenuItem value="press">Press</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Subject" variant="outlined" required name="subject" onChange={handleChange} value={state.values.subject} error={state.errors.subject !== undefined} helperText={state.errors.subject} inputProps={{ maxLength: 50 }} />
          <Grid container justify="flex-end">
            <span>{`${state.values.subject.length} / 50`}</span>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth label="Details" variant="outlined" required multiline rows={4} name="details" onChange={handleChange} value={state.values.details} error={state.errors.details !== undefined} helperText={state.errors.details} inputProps={{ maxLength: 500 }} />
          <Grid container justify="flex-end">
            <span>{`${state.values.details.length} / 500`}</span>
          </Grid>
        </Grid>
        <Grid item>
          <ReCAPTCHA
            ref={ref}
            sitekey={sitekey}
            onChange={token => dispatch({ type: 'SET_CAPTCHA', payload: token })}
          />
          {state.errors.token && <FormHelperText variant="outlined" error>{state.errors.token}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" color="primary" variant="contained" fullWidth size="large">Submit</Button>
        </Grid>
      </Grid>
    </form>
  );
}
