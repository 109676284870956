import React from 'react';
import PropTypes from 'prop-types';
import Event from '../common/Event';
import Carousel from '../c_041_carousel/Carousel';
import useStyles from './styles';
import isIE from '../../utils/isIE';

function Events({ fields }) {
  const classes = useStyles();
  const pages = fields.cta_item_ref.reduce((acc, current) => {
    const event = {
      id: `${current.fields.eventName}-${current.fields.date}`,
      name: current.fields.eventName,
      description: current.fields.description,
      location: current.fields.location,
      date: current.fields.date,
      url: current.fields.url,
    };
    const page = acc[acc.length - 1];
    if ((!isIE() && page.events.length < 6)
    || (isIE() && page.events.length < 3)) {
      page.events.push(event);
      page.id += `${event.id}-`;
    } else {
      acc.push({ id: `${event.id}-`, events: [event] });
    }
    return acc;
  }, [{ id: '', events: [] }]);

  return (
    <Carousel title={fields.title}>
      {pages.map(({ id, events }) => (
        <div className={classes.EventPage} key={id}>
          {events.map(
            event => (
              <Event
                key={event.id}
                name={event.name}
                description={event.description}
                location={event.location}
                date={event.date}
                url={event.url}
              />
            ),
          )}
        </div>
      ))}
    </Carousel>
  );
}

Events.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    eventName: PropTypes.string,
    date: PropTypes.string,
    url: PropTypes.string,
    cta_item_ref: PropTypes.array,
  }).isRequired,
};

export default Events;
